<template>
  <div class="cpt-task-list-search">
    <div style="display: flex; align-items: center">
      <div>
       <div class="date">{{`${date.year}年${date.month}月${date.day}日 ${date.min}`}}</div>
        <div style="display: flex; align-items: center">
          <div style="margin-left:20px" @click="$emit('on-personnelInformation')" class="linear-btned">
            人员信息
          </div>
      <div style="margin-left:14px" @click="$emit('on-flyWork')" class="linear-btned">
            飞手排班
          </div>
        </div>
      </div>

      <!-- <el-form
        :model="search_form"
        style="margin-left: 24px"
        class="linear-form"
        inline
      >
        <el-form-item label="局办单位:">
          <el-select
            v-model="search_form.typeId"
            size="mini"
            clearable
            placeholder="请选择局办单位"
          >
            <el-option
              v-for="item in drd.cates"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="任务状态:">
          <el-select
            v-model="search_form.status"
            size="mini"
            clearable
            placeholder="请选择任务状态"
          >
            <el-option
              v-for="item in drd.status"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item >
        <div @click="$emit('on-search', search_form)" class="linear-btn">
            搜索
          </div>
        </el-form-item>
        <el-form-item >
        <div style="margin-left:70px" @click="$emit('on-flyWork')" class="linear-btn">
            飞手排班
          </div>
        </el-form-item>
      </el-form> -->
    </div>
    <div>
      
    <!-- <div @click="$emit('on-search', search_form)" class="linear-btn" style="margin-left: 20px" >
      批量排班
    </div> -->
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      isEasy: false,
      search_form: {
        name: null,
        typeId: null,
        source: null,
        status: null,
      },
      drd: {
        cates: [
          // { id: 1, title: "飞行区" },
          { id: 2, title: "禁飞区" },
          { id: 3, title: "限飞区" },
        ],
        status: [
          { id: 1, title: "启用" },
          { id: 0, title: "禁用" },
        ],
        apply: [
          { id: 1, title: "自主创建" },
          { id: 0, title: "通用空域" },
        ],
      },
      data: {
        list: null,
        show: false,
        routeButton: false,
        routeButton1: false,
        routeButton2: false
      },
      visible: {
        upload: false,
      },
      fileList: [],
      date:{
        year:null,
        month:null,
        day:null,
        min:null
      }
    };
  },
  created () {
    this.getTime()
  },
  methods: {
    getTime() {
      this.getdate = setInterval(() => {
        this.date.year = dayjs().year();
        this.date.month = dayjs().month() + 1;
        this.date.day = dayjs().date();
        this.date.min = dayjs().format('HH:mm:ss');
      }, 1000)
    }
  }, 
  destroyed() {
    if (this.getdate) {
      clearInterval(this.getdate)
      this.getdate = null
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-task-list-search {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  .allAir {
    z-index: 20;
  }
  .date{
    width: 290px;
    height: 20px;
    font-size: 16px;
    font-family: YouSheBiaoTiHei;
    color: #FFFFFF;
    line-height: 20px;
    margin-left:20px;
    margin-bottom: 8px;
  }
      .linear-btned{
    cursor: pointer;
    width: 126px;
    height: 40px;
    background: rgba(38,71,238,0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,18,55,0.5);
    border: 1px solid;
    border-radius: 6px;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
  }
}
.padding {
  padding-right: 0px !important;
}

::v-deep .el-input {
  width: 178px;
  .el-input__inner {
    width: 100% !important;
  }
}
</style>