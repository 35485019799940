<template>
  <div>
    <Dialog class="lineDialog" :title="editFlyLineData ? (readOnly ? '预览航线' : '编辑航线') : '航线绘制'" :width="438" :height="450"
      :top="107" :left="130" :visible.sync="visible.isShow" @on-close="beforeClose">
      <el-form ref="edit_form" :rules="rules" :model="edit_form" class="linear-form vertical" label-width="100px">
        <el-form-item label="航线名称:" prop="flightName" ref="filename">
          <el-input v-model="edit_form.flightName" placeholder="请输入航线名称" clearable :readonly="visible.readonly"
            class="input-name" />
        </el-form-item>
        <el-form-item label="默认高度:" prop="heightProp">
          <el-input onkeyup="value=value.replace(/[^\d]/g,'')" v-model="edit_form.alt" placeholder="请输入默认高度"
            :readonly="visible.readonly" class="input-name" @change="changeAllAlt" @input="changeAllAlt"
            oninput="value=value.replace(/[^\d]/g,'')" />
          <span class="label-name">m</span>
        </el-form-item>
        <el-form-item label="默认速度:" prop="speed" ref="speed">
          <el-input v-model="edit_form.speed" placeholder="请输入默认速度" clearable :readonly="visible.readonly"
            class="input-name" />
          <span class="label-name">m/s</span>
        </el-form-item>
        <el-form-item label="航线状态">
          <el-select :disabled="visible.readonly" v-model="edit_form.status" placeholder="请选择">
            <el-option label="正常" :value="1">
            </el-option>
            <el-option label="禁用" :value="0">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="航线标签">
          <el-input :readonly="visible.readonly" v-model="edit_form.label" placeholder="请输入自定义航线标签" clearable
            class="input-name" />
        </el-form-item>


        <el-form-item label="默认偏航角:" prop="name">
          <el-input v-model="edit_form.gimbalYaw" placeholder="请输入默认偏航角" clearable :readonly="visible.readonly"
            class="input-name" @blur="DefaultAngle(0)" />
          <span class="label-name">(°)</span>
        </el-form-item>
        <el-form-item label="默认俯仰角:" prop="name">
          <el-input v-model="edit_form.gimbalPitch" placeholder="请输入默认俯仰角" clearable :readonly="visible.readonly"
            class="input-name" @blur="DefaultAngle(1)" />
          <span class="label-name">(°)</span>
        </el-form-item>
        <el-form-item label="绘制航点:">
          <div class="btn-submit" style="width: 84px;height: 32px;" @click="visible.editdialog = !visible.editdialog">
            <img src="~@/assets/images/icons/RouteMapping.png">
            <span>{{ edit_form.points[0].lon === 0 && edit_form.points[0].lat === 0
              ? ''
              : edit_form.points.length }}</span>
          </div>
        </el-form-item>
      </el-form>
      <div class="foot-btn-box">
        <div v-if="!readOnly" class="btn-submit" @click="beforeClose">
          取消
        </div>
        <div v-if="!readOnly" class="btn-submit" @click="submitForm">
          保存航线
        </div>
      </div>
    </Dialog>

    <Dialog :title="edit_form.points[0].lon === 0 && edit_form.points[0].lat === 0
      ? '航点'
      : '航点' + (action.curr_index + 1)" :isShowClose="true" :plus="edit_form.points.length >= 2 ? true : false"
      @redcurr="redcurr" @addcurr="addcurr" :width="438" :height="451" :top="600" :left="130"
      :style="visible.editdialog ? 'visibility:visible' : 'visibility:hidden'" @on-close="visible.editdialog = false">
      <el-form ref="edit_form2" :rules="rules" :model="edit_form" class="linear-form vertical" label-width="80px">
        <el-form-item label="经度:" prop="points">
          <el-input v-model="edit_form.points[action.curr_index].lon" placeholder="请输入经度" clearable
            :disabled="visible.readonly" class="input-name"></el-input>
        </el-form-item>
        <el-form-item label="纬度:" prop="points">
          <el-input v-model="edit_form.points[action.curr_index].lat" placeholder="请输入纬度" clearable
            :disabled="visible.readonly" class="input-name"></el-input>
        </el-form-item>
        <el-form-item label="高度:" prop="alt">
          <el-input v-model="edit_form.points[action.curr_index].alt" :min="0" clearable :disabled="visible.readonly"
            oninput="value=value.replace(/[^\d]/g,'')" class="input-name" placeholder="请输入高度" />
          <span class="label-name">m</span>
        </el-form-item>
        <!-- <el-form-item label="速度:" prop="speed">
          <el-input
            v-model="edit_form.speed"
            placeholder="请输入航线速度"
            clearable
            :readonly="visible.readonly"
            class="input-name"
          >
          </el-input>
          <span class="label-name">m/s</span>
        </el-form-item> -->
        <el-form-item label="偏航角:" prop="pitch">
          <el-input v-model="edit_form.points[action.curr_index].gimbalYaw" class="aainput" :disabled="visible.readonly"
            style="width:262px" placeholder="请输入偏航角"
            @blur="edit_form.points[action.curr_index].gimbalYaw = Number(edit_form.points[action.curr_index].gimbalYaw)"
            @change="WaypointAngle(0)"></el-input>
          <span class="label-name">(°)</span>
        </el-form-item>
        <el-form-item label="俯仰角:" prop="gimbalPitch">
          <el-input v-model="edit_form.points[action.curr_index].gimbalPitch" :disabled="visible.readonly"
            style="width:262px" placeholder="请输入俯仰角"
            @blur="edit_form.points[action.curr_index].gimbalPitch = Number(edit_form.points[action.curr_index].gimbalPitch)"
            @change="WaypointAngle(1)"></el-input>
          <span class="label-name">(°)</span>
        </el-form-item>
        <el-form-item label="动作:">
          <div style="display:flex;align-items:center;">
            <div class="btn-submit" style="width: 88px;height: 30px;lineHeight: 30px;"
              @click="() => (action.visible = true)">
              <i style="marginRight:5px;fontStyle:normal">+</i>{{ editFlyLineData ? (readOnly ? '预览' : '编辑') : '添加' }}
            </div>
            <div style="margin-left:10px" class="label-name">{{
              this.edit_form.points[this.action.curr_index].actions.length
            }}个动作</div>
          </div>
        </el-form-item>
      </el-form>
      <div class="foot-btn-box" v-if="!visible.readonly">
        <div class="btn-submit" @click="visible.editdialog = false">确定</div>
        <div class="btn-del" v-if="edit_form.points.length > 2" @click="delcurr">删除航点</div>
      </div>
    </Dialog>

    <Dialog :title="editFlyLineData ? (readOnly ? '预览动作' : '编辑动作') : '添加动作'"
      :style="action.visible ? 'visibility:visible' : 'visibility:hidden'" :width="326" :top="600" :left="544"
      :showSave="readOnly ? false : true" @on-save="saveValue" @on-close="action.visible = false" class="pub_dialog_hddz">
      <div class="form-submit-item" v-if="!visible.readonly" :style="action.visible ? 'opacity:1' : 'opacity:0'">
        <div class="item-label-style" style="color:#9fc4ff;marginBottom:3px">航点动作</div>
        <el-dropdown style="width: 100%" trigger="click">
          <div class="form-submit-item">
            <div class="linear-btn" style="width: 100%;fontSize:14px;">
              <i style="marginRight:5px;fontStyle:normal">+</i>
              添加
            </div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in action.types" :key="index" :disabled="visible.readonly">
              <div @click="handle_add_action(item)">{{ item.label }}</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="list-box" :style="action.visible ? 'opacity:1' : 'opacity:0'">
        <div class="action-item" v-for="(item, index) in edit_form.points[action.curr_index].actions"
          :key="item.actionType">

          <div class="flex">
            <div class="item-label-style">{{ item.label + ':' }}</div>
            <div class="item-input" v-if="item.actionType == 'STAY'">
              <el-switch style="marginRight:23px" v-model="item.boolean" :disabled="visible.readonly"
                active-color="#13ce66" inactive-color="#ff4949">
              </el-switch>
              <el-input :disabled="!item.boolean" v-model="item.actionParam" oninput="value=value.replace(/[^\d]/g,'')"
                :readonly="visible.readonly">
              </el-input>
            </div>
            <div class="item-input" v-else-if="item.actionType == 'START_TAKE_PHOTO'">
              <el-switch style="marginRight:23px" v-model="item.boolean" :disabled="visible.readonly"
                active-color="#13ce66" inactive-color="#ff4949">
              </el-switch>
              <el-input :disabled="!item.boolean" v-model="item.actionParam" oninput="value=value.replace(/[^\d]/g,'')"
                :readonly="visible.readonly" class="item-inputer">
              </el-input>
            </div>
            <div class="item-inputder" v-else>
              <el-switch v-model="item.boolean" :disabled="visible.readonly" active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
            </div>
            <span class="item-labeler" v-if="item.actionType == 'START_TAKE_PHOTO' || item.actionType == 'STAY'">{{
              item.actionType == "START_TAKE_PHOTO" ? "张" : "s"
            }}</span>
            <div @click="handle_delete_action(index, item)" class="iconfont icon-shanchu" title="删除"
              style="color: #FF7070" v-if="!visible.readonly"></div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </Dialog>
  </div>
</template>

<script>
let handler, viewer, entity;
let noFlyEntities = [];
import API from "@/api";
import { transformCartesian2WGS84 } from "@/utils/cesium";
import { nanoid } from "nanoid";
import Dialog from "./dialog-box.vue";
import { setView } from "@/utils/cesium.js";

export default {
  components: {
    Dialog
  },
  props: {
    close: {
      type: Function,
      default: () => { },
    },
    editFlyLineData: {
      // 编辑航线传进来的对象
      type: Object || String,
      default: null,
    },
    readOnly: {
      // 是否为预览航线,可控制航点文案以及右上角的点击按钮是保存还是关闭
      type: Boolean,
      default: false,
    },
  },
  data() {
    let height = (rule, value, callback) => {
      if (!this.edit_form.alt && this.edit_form.alt !== 0) {
        callback(new Error('请输入默认高度'));
      }
      callback();
    };
    let points = (rule, value, callback) => {
      // 经纬度验证
      for (let index = 0; index < value.length; index++) {
        if (value[index].lon === undefined || value[index].lat === undefined) {
          this.errorIndex.latAndlon = index;
          break;
        } else {
          this.errorIndex.latAndlon = -1;
        }
      }
      if (
        value[this.action.curr_index].lon === undefined ||
        value[this.action.curr_index].lat === undefined
      ) {
        callback(new Error("经纬度信息不能为空，请补充完整该航点经纬度信息"));
      }
      callback();
    };
    let altValidator = (rule, value, callback) => {
      // 高度验证
      for (let index = 0; index < this.edit_form.points.length; index++) {
        if (this.edit_form.points[index].alt === undefined) {
          this.errorIndex.alt = index;
          break;
        } else {
          this.errorIndex.alt = -1;
        }
      }
      if (this.edit_form.points[this.action.curr_index].alt === undefined) {
        callback(new Error("请输入高度"));
      }
      callback();
    };
    let validateSpeed = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入航线速度'));
      } else if (value <= 0) {
        callback(new Error('请输入正确的航线速度格式,必须大于0,但不能大于25'));
      } else if (value > 25) {
        callback(new Error('请输入正确的航线速度格式,必须大于0,但不能大于25'));
      } else if (0 < value <= 25) {
        let num = value.split('.')[1]
        if (num.length > 2) {
          callback(new Error('请输入正确的航线速度格式,可保留两位小数'));
        }
      } else {
        callback();
      }
    };
    return {
      leftVal: {
        leftFirst: '92px',
        leftSecond: '426px',
        leftThird: '797px'
      },
      handler: null, // 地球点击事件
      isShow: true, // 控制绘制航线弹框是否显示
      isUpdate: false, // 是否是在修改航线
      viewList: null, // 存储空域数据
      allairArray: [], // 存储所有禁飞区域
      errorIndex: {
        // 保存是否有航点信息不完整
        latAndlon: -1, //经纬度
        alt: -1, // 高度
        gimbalYaw: -1, // 偏航角
        gimbalPitch: -1, // 俯仰角
      },
      rules: {
        // 校验规则
        flightName: [
          { required: true, message: "请输入航线名称", trigger: "blur" },
        ],
        heightProp: [
          { required: true, validator: height, trigger: "blur" },
        ],
        // speed: [
        //   { required: true, message: "请输入航线速度", trigger: "blur" },
        //   {
        //     pattern:
        //       /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^[0-9]\.[0-9]([0-9])?$)/,
        //     message: "请输入正确的航线速度格式,可保留两位小数,但不可为0",
        //   },
        // ],
        speed: [{ required: true, message: "请输入航线速度", trigger: 'blur' },
        { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确的航线速度格式,可保留两位小数' }],
        destination: [
          { required: true, message: "请输入目标位置", trigger: "blur" },
        ],
        points: [{ required: true, validator: points, trigger: "change" }],
        alt: [{ required: true, validator: altValidator, trigger: "change" }],
      },
      linelist: null,
      visible: {
        edit: false,
        add: true,
        notice: false,
        addhd: false,
        show: false,
        readonly: false,
        editdialog: false
      },
      edit_form: {
        // 存储选择的航点信息
        gimbalYaw: 0,
        label: null,//航线标签
        gimbalPitch: 0,
        flightName: null,
        destination: "",
        speed: 5,
        status: 1,
        alt: 100,
        points: [
          {
            lon: 0,
            lat: 0,
            alt: 100,
            gimbalYaw: 0,
            gimbalPitch: 0,
            actions: [],
            uuid: null,
          },
        ],
      },
      action: {
        // 存储每个航点的动作
        visible: false,
        types: [
          {
            label: "悬停",
            input: "el-input-number",
            min: 1,
            max: 32000,
            actionType: "STAY",
            actionParam: 10,
          },
          {
            label: "拍照",
            actionType: "START_TAKE_PHOTO",
            actionParam: 0,
          },
          {
            label: "开始录像",
            actionType: "START_RECORD",
            actionParam: 0,
          },
          {
            label: "停止录像",
            actionType: "STOP_RECORD",
            actionParam: 0,
          },
        ],
        curr_index: 0,
      },
    };
  },
  methods: {
    changeAllAlt(val) {
      this.edit_form.points.forEach(v => v.alt = Number(val))
    },
    DefaultAngle(status) { //默认值设置
      let firstIndexGimbalYaw = this.edit_form.points.findIndex(v => v.gimbalYaw)
      let firstIndexGimbalPitch = this.edit_form.points.findIndex(v => v.gimbalPitch)
      if (status == 0) {
        this.edit_form.gimbalYaw = Number(this.edit_form.gimbalYaw) //就算清空，失去焦点时也会将默认设置的''或者null转换为0
        //获取第一个设置了角度的航点,然后在此航点之前未设置角度的航点赋予默认角度（前提是有设置默认角度）
        //找出设置了航点角度之前的航点，然后将默认值赋予设置了航点角度之前的值
        if (this.edit_form.gimbalYaw) this.edit_form.points.slice(0, firstIndexGimbalYaw == -1 ? this.edit_form.points.length : firstIndexGimbalYaw).forEach(v => {
          v.gimbalYaw = this.edit_form.gimbalYaw
          v.gimbalPitch = this.edit_form.gimbalPitch
        })
      } else if (status == 1) {
        this.edit_form.gimbalPitch = Number(this.edit_form.gimbalPitch)
        if (this.edit_form.gimbalPitch) this.edit_form.points.slice(0, firstIndexGimbalPitch == -1 ? this.edit_form.points.length : firstIndexGimbalPitch).forEach(v => {
          v.gimbalYaw = this.edit_form.gimbalYaw
          v.gimbalPitch = this.edit_form.gimbalPitch
        })
      }
    },
    WaypointAngle(status) {
      if (status == 0) {
        this.edit_form.points[this.action.curr_index].gimbalYaw = Number(this.edit_form.points[this.action.curr_index].gimbalYaw) //就算清空，失去焦点时也会将默认设置的''或者null转换为0
        //获取第一个设置了角度的航点,然后在此航点之后已经被赋值默认角度的航点重置为0（前提是有设置默认角度）
        //找出设置了航点角度之后的航点，然后将第一个设置航点之后的航点都重置为0
        if (this.edit_form.points[this.action.curr_index].gimbalYaw) this.edit_form.points.slice(this.action.curr_index + 1).forEach(v => {
          v.gimbalYaw = 0
        })
      } else if (status == 1) {
        this.edit_form.points[this.action.curr_index].gimbalPitch = Number(this.edit_form.points[this.action.curr_index].gimbalPitch)
        if (this.edit_form.points[this.action.curr_index].gimbalPitch) this.edit_form.points.slice(this.action.curr_index + 1).forEach(v => {
          v.gimbalPitch = 0
        })
      }
    },
    //保存动作
    saveValue() {
      this.action.visible = false
    },
    edit(value) {
      // 如果是编辑航线，把传过来的值赋进来
      this.action.curr_index = 0;
      this.init_form(value);
      let flight = JSON.parse(value.flightCourseJson)
      let { points } = flight;
      this.edit_form.points = points;
      this.edit_form.gimbalYaw = flight.gimbalYaw
      this.edit_form.gimbalPitch = flight.gimbalPitch
      this.edit_form.alt = flight.alt
    },
    delcurr() {
      // 删除某个航点
      let index = this.action.curr_index;
      if (index > 0) {
        this.action.curr_index--;
        this.edit_form.points.splice(index, 1);
        this.handler.removeEditPoint(index);
      } else {
        this.$el_message("无法删除首点");
      }
    },
    init_form(data) {
      Object.keys(this.edit_form).forEach((key) => {
        this.edit_form[key] = data[key];
      });
    },
    leftOnclick(value) {
      const cartesian3 = value.positions[value.positions.length - 1];
      // 判断是否点击修改某点
      if (!isNaN(value.index)) {
        this.action.curr_index = value.index;
        // this.move();
      } else {
        const aa = transformCartesian2WGS84(cartesian3);
        const first = this.edit_form.points[0];
        const last = this.edit_form.points[this.edit_form.points.length - 1];
        // 判断双击事件重复录入航点
        if (aa.lng === last.lon && aa.lat === last.lat) {
        } else {
          // 判断第一个点，第一次点击航点覆盖默认点位信息
          if (first.lon === 0 && first.lat === 0) {
            // console.log("覆盖");
            this.edit_form.points[0].lon = Number(aa.lng);
            this.edit_form.points[0].lat = Number(aa.lat);
            this.edit_form.points[0].uuid = nanoid();
          } else {
            // console.log("新增");
            this.action.curr_index++;
            this.edit_form.points.push({
              lon: Number(aa.lng),
              lat: Number(aa.lat),
              alt: this.edit_form.points[this.action.curr_index - 1].alt,
              gimbalYaw: this.edit_form.points[this.action.curr_index - 1].gimbalYaw,
              gimbalPitch: this.edit_form.points[this.action.curr_index - 1].gimbalPitch,
              actions: [],
              uuid: nanoid(),
            });
          }
        }
      }
    },
    rigthOnclick(data) {
      if (data.positions.uuid) {
        this.action.curr_index = 0;
        this.edit_form.points = [
          {
            lon: 0,
            lat: 0,
            alt: 120,
            pitch: 0,
            gimbalPitch: 0,
            gimbalYaw: 0,
            actions: [],
          },
        ];
      } else {
        if (this.edit_form.points.length <= 1) {
          this.$el_message("无法删除首点");
        } else {
          this.action.curr_index--;
          this.edit_form.points.pop();
        }
      }
    },
    move(data) {
      // 移动航点函数
      if (!isNaN(data.index)) {
        const aa = transformCartesian2WGS84(data.move_position);
        this.action.curr_index = data.index;
        this.edit_form.points[data.index].lon = Number(aa.lng);
        this.edit_form.points[data.index].lat = Number(aa.lat);
      }
    },
    openDrawFlyLinePopup() {
      // 打开绘制航线弹框时做的一些事
      if (this.editFlyLineData) {
        this.edit(this.editFlyLineData);
        this.isUpdate = this.editFlyLineData.id;
        // 判断是否是修改航线，如果是，渲染航线
        let points = [...this.edit_form.points];
        let poi = [];
        points.map((item) => {
          poi.push(Cesium.Cartesian3.fromDegrees(item.lon, item.lat, 0));
        });
        if (this.readOnly) {
          this.handler = new MMCM.DrawAirwayHandler({
            viewer: viewer,
            positions: poi,
            config: {
              isReadonly: this.readOnly, // 是否可以操作
            },
          });
        } else {
          this.handler = new MMCM.DrawAirwayHandler({
            viewer: viewer,
            positions: poi,
          });
        }
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            points[0].lon,
            points[0].lat,
            5000
          )
        });
      } else {
        this.handler = new MMCM.DrawAirwayHandler({
          viewer: viewer,
        });
        // viewer.camera.flyTo({
        //   destination: Cesium.Cartesian3.fromDegrees(
        //     120.67593514717086,
        //     30.513843958092217,
        //     100000
        //   )
        // });
        // //飞入济源
        // setView(window.viewer);

      }

      this.handler.setInputAction((data) => {
        this.leftOnclick(data);
      }, MMCM.DrawEventType.LEFT_CLICK);

      this.handler.setInputAction((data) => { },
        MMCM.DrawEventType.LEFT_DOUBLE_CLICK);

      this.handler.setInputAction((data) => {
        this.rigthOnclick(data);
      }, MMCM.DrawEventType.RIGHT_CLICK);

      this.handler.setInputAction((data) => {
        this.move(data);
      }, MMCM.DrawEventType.MOUSE_MOVE);
    },
    beforeClose() {
      // 创建航线弹框关闭时做的一些事
      this.showLine();
      if (this.handler) {
        this.handler.destroy(); // 吧点击的功能也去掉选点的功能也去掉
      }
      this.close()
    },
    redcurr() {
      // 上个航点
      if (this.action.curr_index > 0) {
        this.action.curr_index--;
      }
    },
    addcurr() {
      // 下个航点
      if (this.action.curr_index < this.edit_form.points.length - 1) {
        this.action.curr_index++;
      }
    },
    handle_add_action(item) {
      this.edit_form.points[this.action.curr_index].actions.push({ ...item });
    },
    handle_delete_action(index, item) {
      this.edit_form.points[this.action.curr_index].actions.splice(index, 1);
    },
    // 计算距离
    transformWGS842Cartesian(position, alt) {
      return position
        ? Cesium.Cartesian3.fromDegrees(
          position.lng,
          position.lat,
          (position.alt = alt || position.alt),
          Cesium.Ellipsoid.WGS84
        )
        : Cesium.Cartesian3.ZERO;
    },
    showLine() {
      // 清除提示框
      const ces = document.getElementsByClassName("cesium-tooltip")[0];
      const body = document.getElementsByTagName("body")[0];
      if (ces) {
        body.removeChild(ces);
      }
    },
    resetValue() {
      //当点击重置按钮将鼠标字体去除，后初始化方法
      let ces = document.getElementsByClassName("cesium-tooltip")[0];
      let body = document.getElementsByTagName("body")[0];
      if (ces) {
        body.removeChild(ces);
      }
      //去除刚刚编辑的内容（点）
      this.handler.destroy()
      //初始化方法
      this.handler = new MMCM.DrawAirwayHandler({
        viewer,
      });
      this.handler.setInputAction((data) => {
        this.leftOnclick(data);
      }, MMCM.DrawEventType.LEFT_CLICK);

      this.handler.setInputAction((data) => { },
        MMCM.DrawEventType.LEFT_DOUBLE_CLICK);

      this.handler.setInputAction((data) => {
        this.rigthOnclick(data);
      }, MMCM.DrawEventType.RIGHT_CLICK);

      this.handler.setInputAction((data) => {
        this.move(data);
      }, MMCM.DrawEventType.MOUSE_MOVE);
    },
    //重置按钮
    handleClose() {
      //重置点击事件
      this.resetValue()
      this.action.visible = false;
      this.visible.editdialog = false
      this.visible.readonly = false;
      this.action.curr_index = 0;
      this.edit_form.flightName = null;
      this.edit_form.destination = "";
      this.edit_form.label = null;

      this.edit_form.speed = 5;
      this.edit_form.status = 1;
      this.edit_form.points = [
        {
          lon: 0,
          lat: 0,
          alt: 100,
          pitch: 0,
          gimbalPitch: 0,
          actions: [],
          uuid: null,
        },
      ];
    },
    submitForm() {
      let list = [];
      list.push(
        this.checkForm("edit_form"),
        this.checkForm("edit_form2")
      );
      Promise.all(list)
        .then(() => {
          console.log("通过检测");
          this.handleSave()
        })
        .catch(() => {
          console.log("未通过");
        });
    },
    checkForm(formName) {
      console.log(this.$refs[formName]);
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate(valid => {
          if (valid) {
            resolve();
          } else reject();
        });
      })
    },
    // 保存
    async handleSave() {
      // this.$refs["edit_form"].validate(async (valid) => {
      if (this.errorIndex.latAndlon !== -1) {
        this.action.curr_index = this.errorIndex.latAndlon;
      } else if (this.errorIndex.alt !== -1) {
        this.action.curr_index = parseInt(this.errorIndex.alt);
      } else if (this.errorIndex.gimbalYaw !== -1) {
        this.action.curr_index = this.errorIndex.gimbalYaw;
      } else if (this.errorIndex.gimbalPitch !== -1) {
        this.action.curr_index = this.errorIndex.gimbalPitch;
      } else if (this.edit_form.points.length <= 1) {
        this.$message.warning("请选择航点");
      } else {
        const { date, points, ...rest } = this.edit_form;
        await points.map((res) => {
          res.lat = Number(res.lat)
          res.lon = Number(res.lon)
        })
        console.log(points, '-----------------------');
        let speed = Number(rest.speed);
        let gimbalYaw = Number(rest.gimbalYaw);
        let gimbalPitch = Number(rest.gimbalPitch);
        let alt = Number(rest.alt);
        const flightCourse = {
          filename: rest.flightName,
          line: {
            baseSpeed: speed,
          },
          points,
          baseSpeed: speed,
          gimbalYaw,
          gimbalPitch,
          alt
        };
        let distance = points.reduce((total, item, index) => {
          let dist = 0;
          if (points[index + 1]) {
            let cd = Cesium.Cartesian3.distance(
              this.transformWGS842Cartesian({ lng: item.lon, lat: item.lat }),
              this.transformWGS842Cartesian({
                lng: parseInt(points[index + 1].lon),
                lat: parseInt(points[index + 1].lat),
              })
            );
            dist = cd;
          }
          return total + parseInt(dist);
        }, 0);

        const params = {
          ...rest,
          pointCount: points.length,
          distance,
          time: parseInt(distance / rest.speed),
          flightCourseJson: JSON.stringify(flightCourse),
          speed,
        };

        let res;
        if (this.isUpdate) {
          res = await API.AIRWAY.updFlightLine({
            ...params,
            id: this.isUpdate,
          });
        } else {
          res = await API.AIRWAY.addFlightLine({
            ...params,
            // status: 1,
          });
        }
        if (res.status === 1) {
          this.$el_message(this.isUpdate ? "修改成功" : "创建成功");
          this.beforeClose();
        } else {
          this.$message.error(res.message);
        }
      }
      // });
    },
    async init_Map_Status() {
      let res = await API.AIRSPACE.selectLikeList({
        status: 1,
        pageNo: 1,
        pageSize: 10000,
      });
      console.log(res);
      if (res.status === 1) {
        // 根据airspcelist判断是否是修改空域，所有空域列表中过滤掉当前空域
        if (this.airspcelist && this.airspcelist.id) {
          let list = res.data.data;
          list.length > 0 ? this.viewList = list.filter(item => item.id != this.airspcelist.id) : this.viewList = list
        } else {
          this.viewList = res.data.data;
        }
        this.add()
      } else {
        this.$message.error(res.message);
      }
    },
    add() {
      this.viewList.map((item, index) => {
        let positions = item.region.split(" ").join(",").split(",");
        if (positions && item.typeId === 2) {
          this.allairArray.push(positions)
        }
        if (positions.length > 3) {
          let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
          let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
          if (positions.length % 2 !== 0) return;
          entity = viewer.entities.add({
            name: "Red polygon on surface",
            position,
            label: {
              text: item.name,
              font: "20px monospace",
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(5, 5),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              fillColor: Cesium.Color.WHITE,
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 200000),
            },
            polygon: {
              hierarchy,
              material: item.color ? Cesium.Color.fromCssColorString(item.color).withAlpha(0.2) : (item.typeId === 2 ? Cesium.Color.RED.withAlpha(0.2) : Cesium.Color.YELLOW.withAlpha(0.2)),
              outline: true,
              outlineColor: Cesium.Color.BLACK,
            },
          });
          noFlyEntities.push(entity);
        }
      });
      // 将所有禁飞区回传到父组件
      // this.addallairArray(this.allairArray)
    },
    on_plate_left_click(movement) {
      let data = this.getcameraPosInfo()

      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(120.570164, 29.927768, 76900.3885525426),
        orientation: {
          "heading": 0.005918411232473275,
          "pitch": -0.9278204182519674,
          "roll": 6.283184339467954
        }
      })

      // 首页板块封面代码
      // plate_data_source.entities.values.map(item => item.polygon.material = Cesium.Color.fromCssColorString('#00016c').withAlpha(0.2))
      let plate = this.get_plate_by_click(movement)
      if (plate) {
        // this.g_show_zysb(0, 0, 1)
        let center = plate.properties.center._value
        this.fly_to_globe({
          destination: Utils.transformWGS842Cartesian({
            lng: center[0],
            lat: center[1],
            alt: 6000,
          }),
        })
      }
    },
    getcameraPosInfo() {
      // 获取 相机姿态信息
      var head = viewer.scene.camera.heading
      var pitch = viewer.scene.camera.pitch
      var roll = viewer.scene.camera.roll
      var info = { 'heading': head, 'pitch': pitch, 'roll': roll };
      // 获取位置 wgs84的地心坐标系，x,y坐标值以弧度来表示
      var position = viewer.scene.camera.positionCartographic //with longitude and latitude expressed in radians and height in meters.
      //以下方式也可以获取相机位置只是返回的坐标系不一样

      // 弧度转经纬度
      var longitude = Cesium.Math.toDegrees(position.longitude).toFixed(6)
      var latitude = Cesium.Math.toDegrees(position.latitude).toFixed(6)
      var height = position.height
      return { lng: longitude, lat: latitude, h: height, mat: info }
    },
  },
  mounted() {
    viewer = window.viewer
    this.visible.readonly = this.readOnly
    //改变组件在不同页面的样式定位
    if (this.$route.path == '/platform/order/visual/view/device') {
      this.leftVal = {
        leftFirst: '580px',
        leftSecond: '950px',
        leftThird: '1350px'
      }
    }
    this.init_Map_Status()
    this.openDrawFlyLinePopup()
    window.Allhandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
  },
  beforeDestroy() {
    window.Allhandler.setInputAction((movement) => {
      this.on_plate_left_click(movement)
    }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK)
    this.beforeClose()
    if (entity) {
      viewer.entities.removeAll()
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .linear-form.vertical .el-form-item .el-form-item__label {
  float: left;
  text-align: right;
  line-height: 36px;
  font-size: 16px;
}

::v-deep .linear-form.vertical .el-form-item .el-form-item__content .el-input.input-name {
  width: 262px;
}

::v-deep .cpt-service-dialog .dialog-content {
  padding: 0 6px 14px 14px;
}

.label-name {
  margin-left: 4px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #BBCFF0;
  line-height: 21px;
}

.btn-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  //   width: 100px;
  //   height: 36px;
  //   background: linear-gradient(180deg, rgba(28,91,197,0.6900) 0%, #091A38 100%);
  //   opacity: 0.8;
  //   border: 1px solid #488CFF;
  //   text-align: center;
  //   line-height: 34px;
  //   font-size: 14px;
  //   font-family: PingFangSC-Regular, PingFang SC;
  //   color: #9FC4FF;
  width: 126px;
  height: 40px;
  background: rgba(38, 71, 238, 0.71);
  box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
  border-radius: 2px;
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.btn-del {
  width: 100px;
  height: 34px;
  background: linear-gradient(180deg, rgba(182, 81, 81, 0.6900) 0%, #091A38 100%);
  opacity: 0.8;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #FF7880;
  text-align: center;
  border: 1px solid #FF7070;
  line-height: 34px;
  border-bottom: 1px solid #FF7070;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.foot-btn-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.pub_dialog {
  width: 400px;
  position: fixed;
  right: 10px;
  top: 110px;
  left: auto;
  bottom: auto;
  z-index: 1000;

  .headBtn {
    display: flex;
    justify-content: center;

    .airwayNum {
      height: 36px;
      line-height: 36px;
      font-size: 17px;
      color: #9fc4ff;
      padding: 0 20px;
    }
  }
}

// .pub_dialog_hddz {
//   z-index: 1000;
//   width: 400px;
//   position: fixed;
//   right: 420px;
//   top: 110px;
//   left: auto;
//   bottom: auto;
//   .action-item {
//     :hover {
//       background: rgba(12, 34, 67, 0.55) !important;
//     }
//     .flex {
//       display: flex;
//       justify-content: space-between;
//       height: 40px;
//       line-height: 40px;
//       .iconfont {
//         font-size: 30px;
//         color: #9fc4ff;
//         cursor: pointer;
//       }
//       .item-label {
//         font-size: 14px;
//         color: #9fc4ff;
//         letter-spacing: 0;
//         display: block;
//         flex-shrink: 0;
//         float: none;
//         text-align: left;
//       }
//     }
//   }
//   .footer {
//     margin-top: 20px;
//     display: flex;
//     justify-content: space-between;
//   }
// }
.pub_dialog_hddz {
  // width: 316px;
  // height: 368px;
  width: 326px;
  // height: 203px;
  position: absolute;
  // right: 387px;
  // top: 44px;
  top: 314px;
  // left: 797px;
  // left: auto;
  bottom: auto;

  .item-label-style {
    color: #9fc4ff;
    margin-bottom: 3px
  }

  .action-item {
    margin: 10px 0;

    // :hover{
    //   background: rgba(44, 123, 255, 0.5);
    // }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      line-height: 40px;

      .iconfont {
        font-size: 30px;
        color: #9fc4ff;
        cursor: pointer;
      }

      .item-label {
        font-size: 14px;
        color: #9fc4ff;
        letter-spacing: 0;
        display: block;
        flex-shrink: 0;
        float: none;
        text-align: left;
        width: 60px;
        text-align: center;
      }

      .item-labeler {
        display: block;
        text-align: center;
        color: #9fc4ff;
        width: 10px;
      }

      .item-inputder {
        width: 180px;
        text-align: left;
      }

      .item-input {
        display: flex;
        align-items: center;
        width: 180px;
        // justify-content: space-around;
        justify-content: flex-end;

        .el-input {
          width: 94px;
          height: 36px;
        }

        .el-input .el-input__inner {
          color: #9fc4ff;
          width: 100%;
          background: rgba(3, 24, 49, 0.8000);
          border: 1px solid #009AFF;
          margin-left: 0;
        }

        .item-inputer.el-input .el-input__inner {
          color: #9fc4ff;
          width: 94px;
          background: rgba(3, 24, 49, 0.8000);
          border: 1px solid #009aff;
        }
      }
    }
  }

  .footer {
    margin-top: 20px;
    // .form-submit-item {
    display: flex;
    justify-content: space-between;
    // }
  }
}
</style>
<style lang="scss">
.lineDialog {
  .el-select {
    width: 260px !important;
  }
}
</style>