<template ate>
    <div class="editBox">
        <div class="add w100 h40 lh40 tc cf cp fw700 f17 fr" @click="showDialg">新增</div>
        <div class="fix"></div>
        <div class="earlyWarningBox mt17">
            <div class="earlyWarning_hd">
                <span>预警类型</span>
                <span>图片</span>
                <!-- <span>视频</span> -->
                <span>预警时间</span>
                <span>编辑</span>
            </div>
            <div class="earlyWarning_hb-box">
                <div class="earlyWarning_hb mt10  " v-for="(item, index) in listData" :key="index">
                    <div>
                        {{ item.aiName }}
                    </div>
                    <div class="h88">
                        <div class="w126 ht100 imgAndVideo lh88 tc cp">
                            <el-image class="w126 ht100" :src="item.scene"></el-image>
                        </div>
                    </div>
                    <div>
                        {{ item.date }}
                    </div>
                    <!-- 编辑 -->
                    <div class="tc h88 lh88" style="margin:0 auto">
                        <div @click="deleteFn(index)" style="color: #35D9E7"
                            class=" cp dib w50 h50 f25 iconfont icon-shanchu"></div>
                    </div>

                </div>
            </div>

        </div>
        <div class="Details_Item_Left ">
            <span class="Left_Item_Icon"></span>
            <span class="Left_Item_Title">报告总结</span>
        </div>
        <div class="mt14 pt14   cf indent bgBlack">
            <el-input type="textarea" :rows="1" placeholder="请输入内容" v-model="reportDetailCopy.remark">
            </el-input>
        </div>
        <el-dialog :fullscreen='true' :title="titleList[type]" :visible.sync="dialogVisible" :append-to-body="true"
            :before-close="handleClose">
            <div class="jcsb w600 fr ">
                <el-select clearable @change="warningListFn(1)" class="mr20" size="mini" v-model="keyword" placeholder="全部能力">
                    <el-option v-for="item in aiList" :key="item.key" :label="item.value" :value="item.value">
                    </el-option>
                </el-select>
                <el-date-picker @change="warningListFn(1)" value-format="yyyy-MM-dd HH:mm:ss" class="mr20 select" size="mini"
                    v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
                <el-button type="primary" size="mini" @click="selectAll">全选</el-button>
                <el-button type="primary" size="mini" @click="reset">刷新</el-button>
            </div>
            <div class=" warningStatisticsBox mt80">
                <div class="itemBox">
                    <div class="item  h320  " v-for="(item, index) in warningListObj"
                        :class="item.status == true ? 'active_item' : ''" @click="handclick(index, item.status)">
                        <div class="h200 wih100 img">
                            <img class="ht100 wih100" :src="item.scene" alt="">
                        </div>
                        <div class="jcsb ml20   mt10 pb10" style="border-bottom: 1px solid #182235;width:80%">
                            <span class=" f16 cf">{{ item.aiName }}</span><span class="f14" style="color:#8792a0">目标数量:
                                {{ item.num }}</span>
                        </div>
                        <div class=" pl20 pr20 mt10">
                            <span class=" f14 " style="color:#8792a0">设备名称：</span><span class="cf f16">
                                {{ item.deviceSerial }}
                            </span>
                        </div>
                        <div class=" pl20 pr20 mt10">
                            <span class=" f14 " style="color:#8792a0">预警时间：</span><span class="cf f16">{{ item.date
                            }} {{ item.hm }}</span>
                        </div>
                    </div>
                </div>
                <div class="fr tr wih100 mt10 cf">
                    <el-pagination :page-sizes="[8, 30, 300, 500]" class="cpt-table-pagination fr "
                        @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageNum"
                        :page-size="pageSize" layout="total,sizes, prev, pager, next" :total="total">
                    </el-pagination>
                </div>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="selectImgFn">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import API from "@/api";
export default {
    data() {
        return {
            warningList: [],//'预警下拉信息'
            listData: [],
            taskId: "",
            dialogVisible: false,
            imgList: [],//图片列表
            videoList: [],//视频列表
            VUE_APP_IMGIP: "",
            selectIndex: null,
            keyword: null,
            type: null,//1 图片 2视频
            titleList: ['暂无', '选择图片', '选择视频'],
            reportDetailCopy: {},
            total: 0,
            pageNum: 1,
            pageSize: 8,
            warningListObj: null,
            aiList: [],
            value1: null
        }
    },
    props: {
        reportDetail: {
            type: Object,
            default: () => { }
        },
    },
    watch: {
        'reportDetail': {
            handler(value) {
                let reportDetail = JSON.parse(JSON.stringify(value))
                this.init(reportDetail)

            },
            deep: true,
        },
    },
    created() {
        this.getWarningType()
        let reportDetail = JSON.parse(JSON.stringify(this.reportDetail))
        this.reportDetailCopy = reportDetail
        console.log( this.reportDetailCopy ,' this.reportDetailCopy ');
        this.drd_task_ais()
        // 
        this.init(reportDetail)

    },

    mounted() {
        const { VUE_APP_IMGIP } = process.env;
        this.VUE_APP_IMGIP = VUE_APP_IMGIP
    },
    methods: {
        init(reportDetail) {
            if (reportDetail && reportDetail.ais && reportDetail.ais.length > 0) {
                console.log(reportDetail.ais, 'listData');
                this.listData = reportDetail.ais.map(item => ({
                    id: item.aiId,
                    aiName: item.warningTypeName,
                    date: item.warningTime,
                    scene: item.warningPhotoStorage
                }))


            } else {
                this.listData = []
            }
        },
        async drd_task_ais() { // AI下拉
            try {
                let res = await API.AI.ailist();
                this.aiList = res.data
            } catch (e) {
                console.error(e);
            }
        },
        selectAll() {
            let arr = this.warningListObj.map(item => ({
                ...item,
                status: true
            }))
            this.warningListObj = arr
            this.$forceUpdate()
        },
        // 重置
        reset() {
            this.pageNum = 1,
                this.pageSize = 8
            this.deviceSerial = null
            this.keyword = null
            this.value1 = null
            this.warningListFn()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.warningListFn()
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.warningListFn()

        },
        async warningListFn(num) {
            if(num ){
                this.pageNum = num
            }
            // this.loading = true
            let { pageNum, pageSize, keyword, value1, deviceSerial } = this
            let start = null
            let end = null

            if (value1 && value1.length > 0) {
                start = value1[0]
                end = value1[1]
            }
            let res = await API.FLIGHTMANAGEMENT.warningList({
                page: pageNum,
                pageSize: pageSize,
                keyword: keyword || null,
                deviceSerial: deviceSerial || null,
                start: start,
                end: end,
                taskId:this.reportDetailCopy.taskId
            })
            console.log(res, 'xxx');
            if (res.status == 1) {
                this.warningListObj = res.data.records.map(item => ({
                    ...item,
                    status: false
                }))
                // this.loading = false
                this.total = res.data.total

            }
        },
        handclick(index, status) {
            this.warningListObj[index].status = !status
        },
        changeDate() {
            this.$forceUpdate();
        },
        // 选择飞行成果图片
        selectImgFn() {
            // let ids = this.warningListObj.filter(item => item.status).map(k => k.id)
            let ids = this.warningListObj.filter(item => item.status)
            if (ids.length == 0) {
                return this.$message.warning('请选择需要添加的预警信息！')
            } else {
                let data = JSON.parse(JSON.stringify(this.listData))
                data.push(...ids)
                const map = new Map();
                const newArr = data.filter(v => !map.has(v.id) && map.set(v.id, 1));

                console.log(newArr, 'data');
                this.listData = newArr


                this.dialogVisible = false
                this.selectIndex = null
                this.imgList = []
                this.$forceUpdate();
                // 清空状态

            }
        },
        // 显示飞行成果图片列表
        async showDialg() {
            this.dialogVisible = true
            this.warningListFn()

        },
        // 改变图片是否选择的状态 
        changeImgStatus(item, type) {
            item.statusFlag = !item.statusFlag

            // if (type == 1) {
            //     item.statusFlag = !item.statusFlag
            // } else if (type == 2) {
            //     item.statusFlag = !item.statusFlag
            //     this.imgList.forEach(el => {
            //         console.log(el.id);
            //         if (el.id == item.id) {
            //             item.statusFlag = !item.statusFlag
            //         } else {
            //             item.statusFlag = false
            //         }
            //     })
            // }
        },
        handleClose() {
            this.dialogVisible = false
            this.selectIndex = null
            this.imgList = []
        },
        // add() { 
        //     let itemData = {
        //         warningTypeId: "",
        //         warningTypeName: "",
        //         warningPhotoStorage: "",
        //         warningPhotoUrl: "",
        //         video: "",
        //         remark: "",
        //         taskId: this.reportDetailCopy.taskId,
        //     }
        //     this.listData.push(itemData)
        //     this.$forceUpdate();
        // },
        deleteFn(index) {
            this.listData.splice(index, 1)
            this.$forceUpdate();

        },
        save() {
            let reportDetail = JSON.parse(JSON.stringify(this.reportDetailCopy))
            reportDetail.ais = this.listData
            return reportDetail
        },
        getSrc(Srclsit) {
            let src = Srclsit.split(',')
            return src[0]
        },
        async getWarningType() { // 预警下拉信息
            const res = await API.TASK.getAlertTypeDropDown()
            this.warningList = res.data
        }
    },
}
</script>
<style lang="scss" scoped>
.warningStatisticsBox {
    height: 75vh;


    .itemBox {
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        height: 71.4vh;
    }

    .item {
        width: 24%;
        height: 344px;
        margin: 5px;
        // background: rgba(0, 0, 0, 0.4);
        box-shadow: inset 0px 0px 30px 0px rgba(26, 79, 255, 0.82);
        // border: 1px solid #73A6D1;
        backdrop-filter: blur(4px);
        border: 1px solid transparent;

        .img {
            background-color: #0b3d66;
        }
    }

    .item:hover {
        border: 1px solid #73A6D1;
    }

    .active_item {
        border: 1px solid #73A6D1;
    }


}

.editBox {
    height: 42vh;

    .add {
        background: rgba(38, 71, 238, 0.71);
        box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
        border-radius: 6px;
    }

    .Left_Item_Icon {
        display: inline-block;
        width: 4px;
        height: 16px;
        background: #FFBD36;
        border-radius: 2px;
    }

    .Left_Item_Title {
        padding-left: 5px;
        width: 90px;
        height: 24px;
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 24px;
    }

    .earlyWarningBox {
        .earlyWarning_hd {
            display: flex;
            align-items: center;
            background: rgba(138, 152, 177, 0.18);
            height: 48px;

            span {
                display: inline-block;
                text-align: center;
                // width: calc(100% / 5);
                width: calc(100% / 4);
                font-size: 16px;
                font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                font-weight: bold;
                color: #FFFFFF;
            }
        }

        .earlyWarning_hb-box {
            height: 24vh;
            overflow-y: auto;

            .earlyWarning_hb {
                display: flex;
                color: #fff;
                height: 100px;
                background: rgba(138, 152, 177, 0.18);
                border: 1px solid #73A6D1;
                align-items: center;

                div {
                    text-align: center;
                    // width: calc(100% / 5);
                    width: calc(100% / 4);
                }

                .imgAndVideo {
                    margin: 0 auto;
                    display: inline-block;
                    background: rgba(0, 0, 0, 0.24);
                    border: 1px solid #B4BCBE;

                    .icon-add {
                        width: 100%;
                        font-size: 80px;
                        text-align: center;
                        line-height: 80px;
                    }
                }
            }
        }

        ::v-deep .el-icon-close {
            color: #8aabd4;
            font-size: 16px;
            position: absolute;
            right: 13px;
            top: 19px;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }
}

.imgDialog {
    display: flex;
    height: 300px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    overflow-y: auto;

    .selectStyle {
        border: 1px solid red;
    }

    .defaultStyle {
        border: 1px solid transparent;

    }

    .imgInfor {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}


::v-deep {
    .el-textarea__inner {
        background: rgba(0, 0, 0, 0.6);
        border: none;
        border-radius: 6px;
        border: 0;
        padding: 16px 24px;
        color: #fff;
    }

    .el-input--mini .el-input__inner {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: none;
    }
}

// 设置滚动条宽度
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
    background: rgba(10, 122, 227, 0.01);
}

//滚动条的哈滑块
::-webkit-scrollbar-thumb {
    background: #2e6190;
    border-radius: 3px;
}
</style>