<template>
  <div>
    <!-- 无人机作业报告 -->
    <Dialog title="无人机作业报告 " :width="1200" :top="120" :left="120" @on-close="closePopup">
      <div class=" dialogStyle  wih100 ">
        <!-- 基础页面 -->
        <div style="color:#AAB7C3">
          <div class="Details_Item_Left mb14">
            <span class="Left_Item_Icon"></span>
            <span class="Left_Item_Title">需求单详情</span>
          </div>
          <div class=" " style="display: flex;">
            <div style="flex:1 0 0">任务类别：<span style="color:#6AFF64 ">{{ ReportDetail.cate }}</span></div>
            <div style="flex:1 0 0">任务场景：<span class="cf">{{ ReportDetail.taskType || '暂无' }}</span></div>
            <div style="flex:1 0 0">发起单位：<span class="cf">{{ ReportDetail.organizationName }}</span></div>
            <div></div>

          </div>
          <div class=" mt24 " style="color:#AAB7C3;display: flex;">
            <div style="flex:1 0 0">发起人姓名：<span class="cf">{{ReportDetail.name}}</span></div>
            <div style="flex:1 0 0" class="w180">审核人：<span class="cf">{{ ReportDetail.reportApplyUser || '暂无' }}</span></div>
            <div style="flex:1 0 0">生成时间：<span class="cf">{{ ReportDetail.addtime }}</span></div>
            <div> </div>
          </div>
        </div>
        <div>
          <div class="Details_Item_Left mt32">
            <span class="Left_Item_Icon"></span>
            <span class="Left_Item_Title">飞行记录</span>
          </div>
        </div>
        <div class=" mt14 " style="color:#AAB7C3;display: flex;">
          <div style="flex:1 0 0">飞行总架次：<span class="cf dib mr5">{{ ReportDetail.sortieNum }}</span>次 </div>
          <!-- <div>飞行总时间:<span class="cf">{{ ReportDetail.executeTime }}-{{ ReportDetail.endTime }}</span></div> -->
          <div style="flex:1 0 0">飞行总里程：<span class="cf dib mr5">{{ ReportDetail.flightDistance }} </span>m</div>
          <div style="flex:1 0 0">飞行总时长：<span class="cf dib mr5 ">{{ (ReportDetail.flightTime/60).toFixed(1)}}</span>min</div>
          <div style="flex:1 0 0">飞手姓名：<span class="cf">{{ ReportDetail.operation }}</span></div>
        </div>
        <div class=" mt24 " style="color:#AAB7C3;display: flex;">
          <div style="flex:1 0 0">派单员：<span class="cf dib mr5">{{ ReportDetail.handleUser }}</span> </div>
          <div style="flex:1 0 0">详细作业位置：<span class="cf">{{ ReportDetail.origin }}</span></div>
          <div style="flex:1 0 0">预警次数：<span class="cf dib mr5">{{ ReportDetail.warningNum }}</span>次</div>
          <div style="flex:1 0 0"></div>
          <div></div>
        </div>
        <div class="Details_Item_Left mt32">
          <span class="Left_Item_Icon"></span>
          <span class="Left_Item_Title">预警信息</span>
        </div>
        <!-- 预警信息-预览 -->
        <div v-if="reportType">
          <div class="earlyWarningBox mt17">
            <div class="earlyWarning_hd">
              <span>预警类型</span>
              <span>图片</span>
              <!-- <span>视频</span> -->
              <span>预警时间</span>
            </div>
            <div class="earlyWarning_hb-box">
              <div class="earlyWarning_hb mt10  " v-for="(item,index) in ReportDetail.ais" :key="index">
                <div>
                  {{item.warningTypeName}}
                </div>
                <div class="h88">
                  <div class="w126 ht100 imgAndVideo">
                    <el-image class="w122 mt2 dib h84" style=" height: 84px" :src="getSrc(item.warningPhotoStorage)"
                      :preview-src-list="getSrcList(item.warningPhotoStorage)">
                    </el-image>
                  </div>
                </div>
                <!-- <div class="h88" @click="showDialogVisibleVideo(item.video)">
                  <div class="w126 ht100 imgAndVideo cp ">
                    <video class="w126 ht100 " controls="controls" controlslist="download fullscreen noremoteplayback"
                      :disablePictureInPicture="false" autoplay>
                      <source :src="item.video" type="video/mp4">
                    </video>

                  </div>
                </div> -->
                <div>
                  {{item.warningTime}}
                </div>
              </div>
            </div>
          </div>
          <div class="Details_Item_Left mt20">
            <span class="Left_Item_Icon"></span>
            <span class="Left_Item_Title">报告总结</span>
          </div>
          <div class="mt14 pt14   cf indent bgBlack" style="min-height:4vh">
            {{ ReportDetail.remark || '暂无' }}
          </div>
        </div>
        <!-- 预警信息-编辑 -->
        <div v-else>
          <Edit :reportDetail="ReportDetail" ref="EditRef"></Edit>
        </div>


        <!-- 下方审批按钮 -->
        <div class="button hwb-flex" v-if="reportApprovalInfo.isApproval">
          <div @click="edit" class="yes">{{reportType ? '编辑':'保存'}}</div>
          <div @click="reset" v-if="!reportType" class="yes">返回</div>
          <div @click="approvalPass" v-show="reportType" class="yes">审批通过</div>
          <div @click="openApprovalPopup" v-show="reportType" class="no">审批不通过</div>
        </div>
      </div>
    </Dialog>
    <!-- 审批不通过输入原因的弹框 -->
    <DialogZzc v-if="notPassPopupIsShow" @on-close="closeNotPassPopup" title="审批不通过" :width="520" :top="280">
      <el-input type="textarea" :rows="6" placeholder="请输入不通过原因" v-model="reasonForFailure">
      </el-input>
      <!-- 审批按钮 -->
      <div class="button-no-pass hwb-flex">
        <div class="no" @click="notPassPopupIsShow = false">取消</div>
        <div class="yes" @click="submitApproval">确定</div>
      </div>
    </DialogZzc>
  </div>
</template>

<script>
// /kaola/warningList
import Dialog from '@/components/common/new-dialog/index-tow.vue'; // 弹框组件
import DialogZzc from '@/components/common/new-dialog'; // 弹框组件
import Edit from './edit/index.vue'; // 弹框组件
import API from "@/api";
export default {
  components: {
    Dialog,
    DialogZzc,
    Edit
  },
  props: {
    reportApprovalInfo: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      ReportDetail: {}, // 报告信息
      notPassPopupIsShow: false, // 审批不通过弹框
      reasonForFailure: '', // 审批不通过原因
      reportType: true,//true 代表预览模式 false代表编辑模式
    }
  },
  methods: {
    showDialogVisibleVideo(url){
      if(url){
        this.videoUrl = url
        this.dialogVisibleVideo = true
      }
    },
    async edit() {
      let { reportType } = this
      if (reportType) {
        this.reportType = !reportType
      } else {
        let data = this.$refs.EditRef.save()
        let ids =data.ais.map(k => k.id)
        data.ais=null
        data.aiIds=ids
        const res = await API.TASK.updateReport_tasks(data);
        if (res.status === 1) {
          this.$el_message("编辑成功");
         await this.showFn()
          this.reportType = !reportType
        }
      }

    },
    // 取消编辑
    reset() {
      this.reportType = true
    },
    closePopup() { // 关闭审批报告弹框
      this.$emit('close')
    },
    closeNotPassPopup() { // 关闭审批不通过弹框
      this.notPassPopupIsShow = false
    },
    async approvalPass() { // 审批通过
      const res = await API.TASK.reportApplyNew({
        ids: [this.reportApprovalInfo.id],
        status: 1
      })
      if (res.status === 1) {
        this.closePopup()
        this.$message.success('审批成功')
      } else {
        this.$message.console.error(res.message)
      }
    },
    async submitApproval() { // 审批不通过
      if(!this.reasonForFailure){
        this.$message.warning("请输入审批不通过原因");
        return
      }
      const res = await API.TASK.reportApplyNew({
        approvalRemark: this.reasonForFailure,
        ids: [this.reportApprovalInfo.id],
        status: 2
      })
      if (res.status === 1) {
        this.closePopup()
        this.$message.success('审批成功')
      } else {
        this.$message.console.error(res.message)
      }
    },
    openApprovalPopup() { // 打开审不通过弹框
      this.notPassPopupIsShow = true
    },
    async showFn() {
      // 任务报告详情接口
      let { data: res } = await API.TASK.getReportDetail(this.reportApprovalInfo.id);
      console.log( res,' this.ReportDetail');
      this.ReportDetail = res
    },
    getSrc(Srclsit) {
      let src = Srclsit.split(',')
      return src[0]
    },
    getSrcList(Srclsit) {
      let src = Srclsit.split(',')
      let newSrc = []
      for (let index = 0; index < src.length; index++) {
        newSrc.push(src[index])
      }
      return newSrc
    },
  },
  mounted() {
    this.showFn()
  }
}
</script>
<style lang="scss" scoped>
/* 审批不通过样式 */
::v-deep {
  .el-textarea__inner {
    background: rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: 6px;
    border: 0;
    padding: 16px 24px;
    color: #fff;
  }
}

.dialogStyle {
  height: 77vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.button-no-pass {
  margin-top: 40px;
  padding: 0 80px;
  color: #fff;
  font-size: 18px;
  line-height: 40px;
  text-align: center;

  .no {
    width: 126px;
    height: 40px;
    background: rgba(219, 27, 27, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    // border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
  }

  .yes {
    width: 126px;
    height: 40px;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 6px;
    // border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
  }
}

.button {
  margin-top: 40px;
  padding: 0 300px;
  color: #fff;
  font-size: 18px;
  line-height: 40px;
  text-align: center;

  .no {
    width: 126px;
    height: 40px;
    cursor: pointer;
    background: rgba(219, 27, 27, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    // border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
  }

  .yes {
    width: 126px;
    height: 40px;
    cursor: pointer;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 6px;
    // border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
  }
}

.Details_Item_Left {
  display: flex;
  align-items: center;

  .Left_Item_Icon {
    display: inline-block;
    width: 4px;
    height: 16px;
    background: #FFBD36;
    border-radius: 2px;
  }

  .Left_Item_Title {
    padding-left: 5px;
    width: 90px;
    height: 24px;
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 24px;
  }
}

.earlyWarningBox {
  .earlyWarning_hd {
    display: flex;
    align-items: center;
    background: rgba(138, 152, 177, 0.18);
    height: 48px;

    span {
      display: inline-block;
      text-align: center;
      // width: calc(100% / 4);
      width: calc(100% / 3);
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }
  }

  .earlyWarning_hb-box {
    height: 24vh;
    overflow-y: auto;

    .earlyWarning_hb {
      display: flex;
      color: #fff;
      height: 100px;
      background: rgba(138, 152, 177, 0.18);
      border: 1px solid #73A6D1;
      align-items: center;

      div {
        text-align: center;
        // width: calc(100% / 4);
        width: calc(100% / 3);
      }

      .imgAndVideo {
        margin: 0 auto;
        display: inline-block;
        background: rgba(0, 0, 0, 0.24);
        border: 1px solid #B4BCBE;
      }
    }
  }

  ::v-deep .el-icon-close {
    color: #8aabd4;
    font-size: 16px;
    position: absolute;
    right: 13px;
    top: 19px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
</style>