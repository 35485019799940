<template>
  <DialogOne  @on-close="coloseNeedPopup" title="需求单详情" :width="938" :top="188">
    <div class="dispatch">
      <div class="need-top-title hwb-flex">
        <div class="left"><div class="dec"></div>需求单详情</div>
        <div class="right">{{Details_List.addTime}}</div>
      </div>
      <div class="need-bottom">
        <div class="one-info over-out hwb-flex"><span>需求单名称：</span><span  class="over" style="color:#fff">{{Details_List.taskTitle}}</span></div>
        <div class="one-info"><span>任务类别：</span><span :style="`color:${Details_List.taskCateName == '常态任务'? '#6AFF64':'#FF3636'}`">{{Details_List.taskCateName}}</span></div>
        <div class="one-info"><span>任务场景：</span><span style="color:#fff">{{Details_List.taskTypeName}}</span></div>

        <div class="one-info"><span>审核状态：</span><span :style="getApplyColor(Details_List.papplyStatus)">{{map.apply[Details_List.papplyStatus]}}</span></div>
        <div class="one-info"><span>调度状态：</span><span :style="getDispatchColor(Details_List.disposeStatus)">{{map.dispatchStatus[Details_List.disposeStatus]}}</span></div>
        <div class="one-info"><span>直属上级审批状态：</span><span :style="getApplyColor(Details_List.papplyStatusByUser)">{{Details_List.taskCateName == '临时紧急任务' ? '默认通过':map.apply[Details_List.papplyStatusByUser]}}</span></div>

        <div class="one-info"><span>发起单位：</span><span style="color:#fff">{{Details_List.organizationName}}</span></div>
        <div class="one-info"><span>发起人姓名：</span><span style="color:#fff">{{Details_List.userName}}</span></div>
        <div class="one-info"><span>联系方式：</span><span style="color:#fff">{{Details_List.phone}}</span></div>

        <div class="info-add">
          <div class="one-info"><span>开始时间：</span><span style="color:#fff">{{Details_List.startedAt}}</span></div>
          <div class="one-info"><span>结束时间：</span><span style="color:#fff">{{Details_List.endAt}}</span></div>
        </div>

        <div class="one-info-long"><span>任务目标位置：</span><span style="color:#fff">{{Details_List.destination}}</span></div>
      <div class="one-info-long"><span>详细位置说明：</span><span style="color:#fff">{{Details_List.origin}}</span></div>
      </div>
      <div style="color:#AAB7C3">需求单任务内容描述：</div>
      <div class="detail">
        {{Details_List.remark}}
      </div>
      <div style="color:#AAB7C3" v-if="Details_List.approvalRemark">审批不通过原因:</div>
      <div class="detail" v-if="Details_List.approvalRemark">
        {{Details_List.approvalRemark}}
      </div>
    </div>
  </DialogOne>
</template>

<script>
import DialogOne from '@/components/common/new-dialog/index.vue'; // 弹框组件
export default {
  components: {
    DialogOne
  },
  props: {
    Details_List: {
      type: Object,
      default: ()=>{}
    }
  },
  data() {
    return {
      map: {
        status: {
          "-2": "已拒绝",
          "-1": "已取消",
          0: "待执行",
          1: "已完成",
          // 2: "执行中",
          3: "执行中",
        },
        apply: {
          null:'/',
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
        dispatchStatus: {
          1: '待派单',
          2: '已派单',
        }
      
    }
    }
  },
  methods: {
    coloseNeedPopup(){ //关闭需求详情弹框
      this.$emit('on-closeDetails')
    },
    getDispatchColor(status) {
      switch (status) {
        case 1:
          return "color: #FFBD36";
          break;
        case 2:
          return "color: #0AFF00";
          break;
        default:
          break;
      }
    },
    getApplyColor(status) { //审批状态颜色
      switch (status) {
        case 0:
          return "color: #FFBD36";
          break;
        case 1:
          return "color: #4799F3";
          break;
        case 3:
          return "color: #4799F3";
          break;
        case 2:
          return "color: #FF3636";
          break;
        case null:
          return "color: #4799F3";
          break;
        default:
          break;
      }
    },
  }

}
</script>

<style lang="scss" scoped>
  /* 详情单样式 */
.dispatch{
  // height: 456px;
  width: 100%;
  // overflow: auto;
  .need-top-title{
    height: 34px;
    .left{
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      padding-left: 10px;
      position: relative;
      .dec{
        position: absolute;
        top: 2px;
        left: 0;
        width: 4px;
        height: 16px;
        background: #FFBD36;
        border-radius: 2px;
      }
    }
    .right{
      font-size: 14px;
      color: #AAB7C3;
    }
  }
  .need-bottom{
    margin-top: 28px;
    font-size: 14px;
    color: #AAB7C3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .over-out{
      align-items: left !important;
    }
    .over{
      text-align: left;
      display: inline-block;
      width: 71%;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .info-add{
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
    }
    .one-info{
      width: 33.3%;
      margin-bottom: 12px;
    }
    .one-info-long{
      width: 100%;
      margin-bottom: 12px;
    }
  }
  .detail{
    height: 107px;
    margin-top: 12px;
    background: rgba(0,0,0,0.6);
    border-radius: 4px;
    padding: 16px 24px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .button{
    margin-top: 40px;
    padding: 0 140px;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    .no{
      cursor: pointer;
      width: 126px;
      height: 40px;
      // background: rgba(219,27,27,0.71);
      background: rgba(38,71,238,0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,0,0,0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-right: 20px;
    }
    .yes{
      cursor: pointer;
      width: 126px;
      height: 40px;
      background: rgba(38,71,238,0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,18,55,0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-left: 20px;
    }
  }
}
</style>