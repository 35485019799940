<template>
  <div>
    <!-- 需求单制定弹框 -->
    <Dialog v-if="initiatingDemandPopupIsShow" @on-close="closeIDsPopup" title="需求单制定-子任务" style='padding-top:0px'
      :width="1710" :top="120" :left="120">
      <div class="content hwb-flex">
        <!-- 左侧发起需求 -->
        <div class="create-need">
          <!-- 中间的竖线装饰 -->
          <div class="dec"></div>
          <!-- 发起需求弹框 -->
          <!-- :hide-required-asterisk="true" form表单红色必填提示隐藏-->
          <el-form ref="edit_form" :model="edit_form" :label-position="'left'" :rules="rules" class="edit-form"
            label-width="130px">
            <el-form-item label="子任务名称" prop="taskTitle">
              <el-input v-model="edit_form.taskTitle" placeholder="请输入子任务名称" clearable />
            </el-form-item>
            <el-form-item label="飞行时间" prop="date1">
              <div class="timecss">
                <el-form-item prop="date1" style="margin-bottom: 0px;">
                  <el-date-picker @change="date1change" :picker-options="pickerOptionsStart" v-model="edit_form.date1"
                    @focus="dateStartPickerFocus" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" type="datetime"
                    clearable>
                  </el-date-picker>
                </el-form-item>
                <span style="width: 6px; color: #D8D8D8; text-align: center">-</span>
                <el-form-item prop="date2" style="margin-bottom: 0px;">
                  <el-date-picker v-model="edit_form.date2" :picker-options="pickerOptionsEnd" @focus="dateEndPickerFocus"
                    value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="结束时间" clearable>
                  </el-date-picker>
                </el-form-item>
              </div>
            </el-form-item>

            <el-form-item label="任务目标位置" prop="destination">
              <el-cascader class="input-orgname" placeholder="请选择街道" v-model="edit_form.destination"
                :options="drd.cityType" :props="{
                  children: 'children',
                  label: 'title',
                  value: 'id',
                  emitPath: false,
                  empty: false,
                }" clearable>
              </el-cascader>
            </el-form-item>
            <el-form-item label="详细位置说明" prop="origin">
              <el-input v-model="edit_form.origin" placeholder="请输入详细位置" clearable />
            </el-form-item>
            <el-form-item label="子任务需求描述" style="width: 100%" prop="remark">
              <el-input v-model="edit_form.remark" placeholder="请输入子任务需求描述" type="textarea" :rows="4"
                clearable></el-input>
            </el-form-item>
          </el-form>
          <!-- 确定按钮 -->
          <div class="anniu">
            <div @click="handle_save" class="button cp">保存</div>
          </div>
        </div>
        <!-- 右侧列表 -->
        <div class="list">
          <!-- 赛选栏 -->
          <div class="select">
            <el-select v-model="selectInfo.taskCateId" size="mini" clearable placeholder="请选择调度状态" class="button">
              <el-option v-for="item in drd.distributiontatus" :key="item.id" :label="item.label" :value="item.id" />
            </el-select>
            <el-select v-model="selectInfo.taskTypeId" size="mini" clearable placeholder="请选择执行状态"
              style="margin-left:20px" class="button">
              <el-option v-for="item in drd.executionStatus" :key="item.id" :label="item.label" :value="item.id" />
            </el-select>
          </div>
          <!-- 表格 -->
          <Table class="task-table" :columns="columns" :dataSource="dataSource" :pagination="pagination" @size-change="
            (size) => {
              pagination.pageSize = size;
              async_get_list();
            }
          " @page-change="
  (page) => {
    pagination.page = page;
    async_get_list();
  }
">
            <template #disposeStatus="{ row }">
              <div class="action" :style="{ color: row.disposeStatus === 1 ? '#FFBD36' : '#00D2FF' }">
                {{ row.disposeStatus === 1 ? '待派单' : '已派单' }}
              </div>
            </template>
            <template #taskStatus="{ row }">
              <div class="action" :style="getApplyColor(row.taskStatus)">
                {{ map.status[row.taskStatus] }}
              </div>
            </template>
            <template #action="{ row }">
              <div class="action">
                <span title="派单" v-if="
                  row.disposeStatus == 1
                " @click="subtaskDispatch(row)" class="iconfont icon-daipaidan"
                  style="color:#35D9E7;padding-right:6px"></span>
                <span title="详情" @click="subtaskDetails(row)" class="iconfont icon-chakanxiangqing"
                  style="color:#35D9E7;padding-right:6px"></span>
              </div>
            </template>
          </Table>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/common/new-dialog/index-tow.vue'; // 弹框组件
import Table from "@/components/common/table-new-hwb"; // 列表组件
import * as turf from '@turf/turf'
import dayjs from "dayjs";
import API from "@/api";
import { mapGetters } from "vuex"
export default {
  components: {
    Dialog,
    Table
  },
  props: {
    taskData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      selectInfo: { // 下拉状态选择
        taskCateId: null, // 调度状态id
        taskTypeId: null, // 执行状态id
      },
      pickerOptionsStart: {}, //预计飞行开始时间限制
      pickerOptionsEnd: {}, //预计飞行结束时间限制
      organId: null, // 机构赛选的id
      selectList: null, // 赛选的机构
      initiatingDemandPopupIsShow: true, // 控制需求单制定弹框是否展示
      edit_form: {  // 发起需求表单字段
        taskTitle: null, // 需求名称
        date1: null,
        date2: null,
        taskCateId: null, // 任务类型   
        origin: null, // 详细作业位置
        destination: null, // 作业位置（街道）
        remark: null, // 任务描述
      },
      rules: {  // 验证规则
        taskTitle: [
          { required: true, message: "请输入子任务名称", trigger: "blur" },
        ],
        date1: [
          { required: true, message: "请选择飞行开始时间", trigger: "blur" },
          { required: true, message: "请选择飞行开始时间", trigger: "change" },
        ],
        date2: [
          { required: true, message: "请选择飞行结束时间", trigger: "blur" },
        ],
        destination: [
          { required: true, message: "请选择街道", trigger: "change" },
        ],
        origin: [
          { required: true, message: "请输入详细位置说明", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入子任务需求描述", trigger: "blur" },

        ]
      },
      drd: {
        uav_type: [],
        mount_type: [],
        task_type: [],
        task_cate: [],
        flightLine: [],
        distributiontatus: [ // 派单状态
          { id: 1, label: "待派单" },
          { id: 2, label: "已派单" },
        ],
        executionStatus: [ // 执行状态
          { id: 0, label: "待执行" },
          { id: 3, label: "执行中" },
          { id: 1, label: "已完成" },
          { id: -2, label: "已拒绝" },
        ],
        proStatus: [
          { id: 1, label: "待处理", value: 0 },
          { id: 2, label: "处理完成", value: 1 },
          { id: 3, label: "无需处理", value: 2 },
        ],
        cityType: [
          {
            id: 13,
            title: "济源市",
            children: [
              { id: 1, title: "沁园街道" },
              { id: 2, title: "济水街道" },
              { id: 3, title: "北海街道" },
              { id: 4, title: "天坛街道" },
              { id: 5, title: "玉泉街道" },
              { id: 6, title: "克井镇" },
              { id: 7, title: "五龙口镇" },
              { id: 8, title: "轵城镇" },
              { id: 9, title: "承留镇" },
              { id: 10, title: "邵原镇" },
              { id: 11, title: "坡头镇" },
              { id: 12, title: "梨林镇" },
              { id: 17, title: "大峪镇" },
              { id: 14, title: "思礼镇" },
              { id: 15, title: "王屋镇" },
              { id: 16, title: "下冶镇" },


            ],
          },
        ],
      },

      columns: [
        { prop: "index", label: "序号", width: "100px" },
        { prop: "taskTitle", label: "任务单名称", width: "220px" },
        { prop: "disposeStatus", slot: true, label: "调度状态", width: "140px" },
        { prop: "taskStatus", slot: true, label: "执行状态", width: "140px" },
        { prop: "organizationName", label: "单位名称", width: "140px" },
        { prop: "startedAt", label: "开始时间", width: "180px" },
        { prop: "endAt", label: "结束时间", width: "180px" },
        { prop: "action", slot: true, label: "操作", width: "180px" },
      ],
      dataSource: [],
      map: {
        status: {
          "-2": "已拒绝",
          "-1": "已取消",
          0: "待执行",
          1: "已完成",
          // 2: "执行中",
          3: "执行中",
        },
        apply: {
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["dbox_data"]),
    ...mapGetters(["user_info_hn"])
  },
  methods: {
    subtaskDispatch(row) { // 子任务派单
      let data = row
      data.isChild = 1
      data.isSubPage = 1
      this.$emit('subtaskDispatch', data)
    },
    subtaskDetails(row) { // 子任务派单
      let data = row
      data.isChild = 1
      data.isSubPage = 1
      this.$emit('subtaskDetails', data)
    },
    getApplyColor(status) { //审批状态颜色
      switch (status) {
        case 0:
          return "color: #FFBD36";
          break;
        case 1:
          return "color: #4799F3";
          break;
        case 3:
          return "color: #4799F3";
          break;
        case 2:
          return "color: #FF3636";
          break;
        default:
          break;
      }
    },
    date1change() {
      if (this.edit_form.date1.length > 0) {
        this.$refs['edit_form'].clearValidate(['date1']);
      }
    },
    // -------------------------------------------------------------------------------时间选择限制开始
    dateStartPickerFocus() {
      // 预计飞行开始时间获取焦点做选择限制
      this.pickerOptionsStart = {
        disabledDate: (time) => {
          // 日期选择限制
          return time.getTime() < Date.now() - 8.64e7;
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制

      };

    },
    dateEndPickerFocus() {
      // 预计飞行结束时间获取焦点做选择限制
      this.pickerOptionsEnd = {
        disabledDate: (time) => {
          // 日期选择限制
          if (
            this.edit_form.date1 &&
            new Date(this.edit_form.date1).getTime() > Date.now()
          ) {
            return (
              time.getTime() < new Date(this.edit_form.date1).getTime() - 8.64e7
            );
          } else {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    getNowTime(val) {
      // 时间时分秒限制
      let date;
      if (val && new Date(val).getTime() > Date.now()) {
        date = new Date(val);
      } else {
        date = new Date();
      }
      let hours = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hours < 10) hours = "0" + hours;
      if (minute < 10) minute = "0" + minute;
      if (second < 10) second = "0" + second;
      let timeStr = `${hours}:${minute}:${second}`;
      return timeStr;
    },
     dataFilter(){
      if (this.edit_form.date1 || this.edit_form.date2) {
        if (this.edit_form.date1 == this.edit_form.date2) {
        console.log(20221113);
        return true
      }
      }
      
    },
    // ---------------------------------------------------------------------发起需求函数开始
    async handle_save() {
      this.$refs["edit_form"].validate(async (valid) => {
        if (valid) {
          let dataBoolean =  this.dataFilter()
          if(dataBoolean) return this.$message.warning('时间不能相同')
          let { destination, date1, date2, ...rest } = this.edit_form;
          const params = {
            ...rest,
            startedAt: dayjs(date1).format("YYYY-MM-DD HH:mm:ss"),
            endAt: dayjs(date2).format("YYYY-MM-DD HH:mm:ss"),
          };
          //下拉框id转换街道
          destination = this.cityTypeIdFile(destination);
          params.destination = destination;
          params.pid = this.taskData.id
          let res
          if (this.updateNeedData) {
            params.id = this.updateNeedData.id
            res = await API.TASK.EditNew(params)
          } else {
            res = await API.TASK.addSubtaskNew(params)
          }
          if (res.status === 1) {
            this.$message.success('创建需求成功')
            this.edit_form = {
              taskTitle: null,
              data1: null,
              date2: null,
              taskCateId: this.taskData.taskCateId,
              origin: null,
              destination: this.cityTypeIdFiler(this.taskData.destination),
              remark: null,
            }
            this.updateNeedData = null
            this.async_get_list()
            setTimeout(() => {
              this.$refs['edit_form'].clearValidate();
            }, 40);
          } else {
            this.$message.error(res.message)
          }
        }
      });
    },
    cityTypeIdFile(val) {
      if (val == 13) {
        return "济源市";
      } else {
        //筛选出选择的街道
        return this.drd.cityType[0].children.filter(
          (v) => this.edit_form.destination == v.id
        )[0].title;
      }
    },
    // -------------------------------------------------------------------------发起需求函数结束
    async async_get_list() { // 获取列表数据
      const { page, pageSize } = this.pagination;
      let params = `?pageNo=${page}&pageSize=${pageSize}&sumTaskId=${this.taskData.id}&disposeStatus=${this.selectInfo.taskCateId ? this.selectInfo.taskCateId : ''}&taskStatus=${this.selectInfo.taskTypeId !== null ? this.selectInfo.taskTypeId : ''}`
      let res = await API.TASK.taskOrderListNew(params);
      res = res.data
      this.dataSource = res.data || [];
      this.dataSource = this.dataSource.map((item, index) => ({
        ...item,
        index: (page - 1) * pageSize + index + 1,
      }));
      this.pagination.pageSize = res.pageSize;
      this.pagination.total = res.totalCount;
    },
    closeIDsPopup() { // 关闭不通过原因弹框
      this.$emit("closeDemand");
    },
    getAllOrganization() {
      let data = this.dbox_data
      this.selectList = this.getTreeData(data);
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    async drd_task_type() { //获取任务类型下拉
      try {
        let res = await API.TASK.GetDrdType();
        res = res.data;
        this.drd.task_type = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async drd_task_cate() { // 任务场景下拉
      try {
        let res = await API.TASK.GetDrdCate();
        res = res.data;
        this.drd.task_cate = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    cityTypeIdFiler(val) { //筛选出选择的街道
      let data = this.drd.cityType[0].children.filter(
        (v) => val == v.title
      )[0];
      if (data) {
        return data.id;
      } else {
        return null;
      }
    },
  },
  watch: {
    selectInfo: {
      deep: true,
      handler() {
        this.async_get_list()
      }
    },
    "edit_form.date1"(val) {
      // 监视预计飞行开始时间做的某些事
      if (val) {
        const date = new Date(new Date(val).setHours(0, 0, 0, 0)).getTime();
        const today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        if (date > today) {
          // 如果选的日期不是和当天同一天
          this.pickerOptionsStart.selectableRange = "00:00:00 - 23:59:59";
        } else {
          // 如果是同一天
          this.pickerOptionsStart.selectableRange = `${this.getNowTime()} - 23:59:59`;
        }
        if (
          new Date(this.edit_form.date1).getHours() != new Date().getHours() &&
          new Date(this.edit_form.date1).getDay() == new Date().getDay() &&
          new Date(this.edit_form.date1).getDate() == new Date().getDate() &&
          new Date(this.edit_form.date1).getHours() == 0
        ) {
          this.edit_form.date1 = new Date();
        }
      }
      if (
        this.edit_form.date2 &&
        val &&
        new Date(val).getTime() > new Date(this.edit_form.date2).getTime()
      ) {
        this.edit_form.date2 = null; // 如果大于结束时间，清空结束时间
      }
    },
    "edit_form.date2"(val) {
      if (val && new Date(val).getTime() < new Date(this.edit_form.date1).getTime()) {
        this.edit_form.date2 = this.edit_form.date1
        return
      }
      if (val && new Date(val).getTime() < new Date().getTime()) {
        this.edit_form.date2 = new Date()
        return
      }
      if (val) {
        const date = new Date(new Date(val).setHours(0, 0, 0, 0)).getTime();
        let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        if (
          this.edit_form.date1 &&
          new Date(this.edit_form.date1).getTime() > Date.now()
        ) {
          today = new Date(
            new Date(this.edit_form.date1).setHours(0, 0, 0, 0)
          ).getTime();
        }
        if (date > today) {
          // 如果选的日期不是和开始时间或是当天同一天
          this.pickerOptionsEnd.selectableRange = "00:00:00 - 23:59:59";
        } else {
          // 如果是同一天
          this.pickerOptionsEnd.selectableRange = `${this.getNowTime(
            this.edit_form.date1 ? this.edit_form.date1 : ''
          )} - 23:59:59`;
        }
      }
    },
  },
  mounted() {
    console.log(this.taskData);
    this.edit_form.destination = this.cityTypeIdFiler(this.taskData.destination)
    this.edit_form.taskCateId = this.taskData.taskCateId
    this.getAllOrganization()
    this.async_get_list()
    this.drd_task_type()
    this.drd_task_cate()
    this.$forceUpdate()
  },
};
</script>

<style lang="scss" scoped>
/* 绘制区域 */
::v-deep {
  .el-dialog__body {
    padding-top: 0px;
  }
}

.area-select-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(12, 34, 67, 0.4);
  z-index: 7000;

  .content {
    position: absolute;
    width: 1600px;
    height: 700px;
    left: 50%;
    top: 100px;
    margin-left: -800px;
    border: 1px solid #009aff;

    .button {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 160px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1000;

      .el-button--primary {
        background-color: #69b3e5;
      }
    }
  }
}

.content {
  /* 左侧 */
  height: 77vh;
  overflow: hidden;

  .create-need {
    width: 548px;
    height: 760px;
    position: relative;

    .anniu {
      margin-top: 40px;
      text-align: center;
      justify-content: center;
      display: flex;

      .button {
        width: 126px;
        height: 40px;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 40px;
        background: rgba(38, 71, 238, 0.71);
        box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
        border-radius: 6px;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      }
    }

    .dec {
      position: absolute;
      top: 0px;
      height: 83vh;
      left: 560px;
      width: 0px;
      border-right: 1px solid #73A6D1;
      box-shadow: 0px 0px 5px #73A6D1;
    }

    .timecss {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ::v-deep {
      .edit-form {
        padding: 22px 20px 0 6px;

        .el-form-item__label {
          height: 36px;
          color: #AAB7C3;
          font-size: 14px;
          text-align: left;
        }

        .el-date-editor--datetime {
          width: 186px
        }

        .el-input__suffix {
          height: 34px;

          .el-input__suffix-inner {

            .el-input__icon,
            .el-input__clear {
              line-height: 36px;
            }
          }
        }

        .el-cascader {
          width: 100%;
        }

        .el-input {
          box-sizing: border-box;

          .el-input__inner {
            height: 36px;
            box-sizing: border-box;
            background: rgba(40, 53, 122, 0.1);
            border: 1px solid #73A6D1;
            box-shadow: 0px 0px 2px #73A6D1;
            border-radius: 0;
            font-size: 16px;
            color: #9fc4ff;
            letter-spacing: 0;
            font-weight: 400;
            line-height: 36px;
          }

          .el-input__suffix {
            .el-input__suffix-inner {

              .el-input__icon,
              .el-input__clear {
                line-height: 36px;
              }
            }
          }
        }

        .el-textarea {
          textarea {
            box-sizing: border-box;
            background: rgba(40, 53, 122, 0.1);
            background: transparent;
            border: 1px solid #73A6D1;
            box-shadow: 0px 0px 2px #73A6D1;
            border-radius: 0;
            font-size: 16px;
            color: #9fc4ff;
            letter-spacing: 0;
            font-weight: 400;
          }
        }

        .el-form-item__content {


          .el-select {
            width: 100%;
          }
        }







        .linear-fields {
          border: none;
        }

        .overflow-auto {
          overflow: auto;
          max-height: 130px;
          border: 1px solid #27526e;
        }

        .noselected-area {
          color: #999;
          cursor: pointer;
          line-height: 34px;
          font-size: 16px;
        }

        .selected-area {
          color: rgb(25, 194, 107);
          cursor: pointer;
          line-height: 34px;
          font-size: 16px;
        }

        .Inspection {
          height: 37px;
        }
      }
    }
  }

  /* 右侧 */
  .list {
    width: 1086px;
    height: 760px;

    .select {
      margin: 22px 0 20px 0;

      ::v-deep {
        .el-input__inner {
          background-color: #000;
          color: #fff;
          border: 0;
        }
      }
    }

    .task-table {
      height: 598px;
    }
  }
}
</style>