<template>
  <!-- 鼠标移出去除提示，移入显示提示 -->
  <div class="cpt-draw" @mouseout="showLine" @mouseenter="addCesDocument">
    <div class="seach" v-if="allairspce">
      <div class="select">
        <el-input
          style="width: 350px"
          placeholder="请输入空域名称"
          v-model="search.name"
          clearable
        >
          <el-button
            @click="async_get_list"
            slot="append"
            icon="el-icon-search"
            style="padding: 0"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div class="middle" v-if="rowList && rowList.locationArea">
      <el-button @click="flyToTaskId" type="primary" size="mini"
        >回到任务区域</el-button
      >
    </div>
    <div
      v-if="allairspce"
      @click="$emit('clear')"
      class="back-button"
      style="float: right"
      title="关闭"
    ></div>
    <div id="draw-container"></div>
    <!-- 地图切换组件（2D/街道） -->
    <transition
      name="animate__animated dh"
      key="1"
      enter-active-class="animate__fadeInUp"
      leave-active-class="animate__fadeOutDown"
    >
      <Bottomright v-if="!isShow" />
    </transition>
  </div>
</template>

<script>
let handler, viewer, entity;
let noFlyEntities = [];
import { mapGetters } from "vuex";
import Bottomright from "@/components/Bottomright";
import API from "@/api";
export default {
  computed: {
    ...mapGetters(["my_layers_data"]),
  },
  components: {
    Bottomright,
  },
  props: {
    earlyWarningPoint: {
      // 预警点
      type: String,
      default: "",
    },
    drakyStyle: {
      type: Boolean,
      default: false,
    },
    leftOnclick: {
      type: Function,
      default: () => () => {},
    },
    rigthOnclick: {
      type: Function,
      default: () => () => {},
    },
    linelist: {
      type: Object,
      required: false,
      default: null,
    },
    editProps: {
      type: Object,
      required: false,
      default: null,
    },
    move: {
      type: Function,
      default: () => () => {},
    },
    airspcelist: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    // 空域可视化为true
    allairspce: {
      type: Boolean,
      default: false,
    },
    addallairArray: {
      type: Function,
      default: () => () => {},
    },
    rowList: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      viewList: null,
      isShow: false,
      search: {
        name: null,
      },
      VUE_APP_IP: null,
      allairArray: [], // 存储所有禁飞区域
      cesDocument: null, //保存鼠标提示
    };
  },
  methods: {
    cleartooltip() {
      let dom = document.querySelectorAll(".cesium-tooltip");
      dom = [].slice.apply(dom);
      for (let index = 0; index < dom.length; index++) {
        let element = dom[index];
        if (element !== null) {
          element.parentNode.removeChild(element);
        }
      }
    },
    drawWarningPoints() {
      // 绘制预警点
      if (this.earlyWarningPoint) {
        viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(
            this.earlyWarningPoint.split(",")[0],
            this.earlyWarningPoint.split(",")[1],
            200
          ),
          point: {
            color: Cesium.Color.RED, //点位颜色
            pixelSize: 12, //像素点大小
          },
          label: {
            text: "预警位置",
            font: "8pt Source Han Sans CN", //字体样式
            fillColor: Cesium.Color.RED, //字体颜色
            backgroundColor: Cesium.Color.AQUA, //背景颜色
            showBackground: true, //是否显示背景颜色
            style: Cesium.LabelStyle.FILL, //label样式
            outlineWidth: 8,
            verticalOrigin: Cesium.VerticalOrigin.CENTER, //垂直位置
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT, //水平位置
            pixelOffset: new Cesium.Cartesian2(-28, -24), //偏移
          },
        });
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            this.earlyWarningPoint.split(",")[0],
            this.earlyWarningPoint.split(",")[1],
            2000
          ),
        });
      }
    },
    mouseScoll(e) {
      //鼠标滚轮滚动效果
      /*这里获取元素节点*/
      let oPanel = document.getElementById("app");
      let Ripples = document.getElementsByClassName(
        "hwb-mouse-scrolling-effect"
      );
      if (Ripples.length > 0) {
        Ripples[0].remove();
      }
      let oSpan = document.createElement(`div`);
      /*浏览器兼容*/
      e = e || window.Event;
      /*获取相关参数*/
      oSpan.style.left = e.clientX + `px`;
      oSpan.style.top = e.clientY + `px`;
      /*给节点赋值参数数值*/
      oSpan.className = "hwb-mouse-scrolling-effect";
      /*在body中添加span标签*/
      oPanel.appendChild(oSpan);
      /*设置定时器 间隔时间为2000毫秒*/
      setTimeout(() => {
        /*清除ospan节点*/
        oSpan.remove();
      }, 600);
    },
    CesiumLayerMouseEnter() {
      // 鼠标移入地球添加鼠标滚动效果
      document.addEventListener(`mousewheel`, this.mouseScoll);
    },
    CesiumLayerMouseLeave() {
      // 鼠标移出地球添加鼠标滚动效果
      document.removeEventListener(`mousewheel`, this.mouseScoll);
    },
    init_viewer() {
      // 设置相机初始位置为中国上空
      Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
        90,
        -20,
        110,
        90
      );
      viewer = window.viewer = new Cesium.Viewer("draw-container", {
        animation: false, // 动画控制不显示
        shouldAnimate: true,
        timeline: false, // 时间线不显示
        fullscreenButton: false, // 全屏按钮不显示
        creditContainer: document.createElement("div"),
        geocoder: false,
        homeButton: false,
        sceneModePicker: false, // 切换球形和平面型
        baseLayerPicker: false,
        navigationHelpButton: false,
        infoBox: false,
        selectionIndicator: false,
        imageryProvider: new Cesium.WebMapTileServiceImageryProvider({
          url: "https://t0.tianditu.gov.cn/img_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
          layer: "img",
          style: "default",
          tileMatrixSetID: "w",
          format: "tiles",
          maximumLevel: 18,
        }),
        requestRenderMode: true,
      });

      // 根据环境设置相对应地图
      if (this.my_layers_data === "night") {
        viewer.imageryLayers.addImageryProvider(
          new Cesium.UrlTemplateImageryProvider({
            url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
          })
        );
      } else if (this.my_layers_data === "tian") {
        viewer.imageryLayers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            url: "https://t0.tianditu.gov.cn/cia_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
            layer: "cia",
            style: "default",
            tileMatrixSetID: "w",
            format: "tiles",
            maximumLevel: 18,
          })
        );
      }

      // 判断是否为空域可视化
      if (this.allairspce) {
        viewer.camera.setView({
          // 设置相机位置
          destination: Cesium.Cartesian3.fromDegrees(
            120.68240528305054,
            30.458030121429956,
            10000
          ),
        });
        this.search.name = "杭州";
        this.async_get_list();
      } else {
        // 判断是为查看、编辑
        if (this.airspcelist) {
          //------------------------------------------假设是历史预警但是又没区域开始
          if (!this.airspcelist.regionList) {
            let poi = [];
            poi.push(Cesium.Cartesian3.fromDegrees(0, 0, 0));
            handler = new MMCM.DrawPolygonHandler({
              viewer,
              positions: poi,
              config: {
                // 根据readonly为true控制为查看
                isReadonly: this.readonly,
              },
            });
          }
          //------------------------------------------假设是历史预警但是又没区域结束

          if (this.airspcelist.regionList) {
            let data = this.airspcelist.regionList;
            let newData = [],
              poi = [];
            for (let i = 0; i < Math.ceil(data.length / 2); i++) {
              newData[i] = [];
              newData[i].push(data[i * 2]);
              newData[i].push(data[i * 2 + 1]);
            }

            newData.map((item) => {
              poi.push(Cesium.Cartesian3.fromDegrees(item[0], item[1], 0));
            });

            handler = new MMCM.DrawPolygonHandler({
              viewer,
              positions: poi,
              config: {
                // 根据readonly为true控制为查看
                isReadonly: this.readonly,
              },
            });
            viewer.camera.setView({
              destination: Cesium.Cartesian3.fromDegrees(
                data[0],
                data[1],
                100000
              ),
            });
          }
          this.async_get_list();
        } else if (this.rowList) {
          let poi = [];
          poi.push(Cesium.Cartesian3.fromDegrees(0, 0, 0));
          handler = new MMCM.DrawPolygonHandler({
            viewer,
            positions: poi,
            config: {
              // 根据readonly为true控制为查看
              isReadonly: this.readonly,
            },
          });
          viewer.camera.setView({
            destination: Cesium.Cartesian3.fromDegrees(
              120.68240528305054,
              30.458030121429956,
              10000
            ),
          });
          this.async_get_list();
          this.addid(); //对应任务的区域
        } else {
          // 新增空域
          handler = new MMCM.DrawPolygonHandler({
            viewer,
          });
          // 禁止双击事件
          viewer.trackedEntity = undefined;
          viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
          );
          // viewer.camera.setView({
          //   destination: Cesium.Cartesian3.fromDegrees(
          //     120.68240528305054,
          //     30.458030121429956,
          //     10000
          //   ),
          // });
          viewer.camera.setView({
            destination: Cesium.Cartesian3.fromDegrees(112.58, 35.07, 10000),
          });

          this.async_get_list();
        }

        handler.setInputAction((data) => {
          this.leftOnclick(data);
        }, MMCM.DrawEventType.LEFT_CLICK);

        handler.setInputAction((data) => {},
        MMCM.DrawEventType.LEFT_DOUBLE_CLICK);

        handler.setInputAction((data) => {
          this.rigthOnclick(data);
        }, MMCM.DrawEventType.RIGHT_CLICK);

        handler.setInputAction((data) => {
          this.move(data);
        }, MMCM.DrawEventType.MOUSE_MOVE);
      }
    },
    showLine() {
      const ces = document.getElementsByClassName("cesium-tooltip")[0];
      if (ces) {
        ces.style.display = "none";
      }
    },
    addCesDocument(e) {
      if (!this.rowList) {
        const ces = document.getElementsByClassName("cesium-tooltip")[0];
        if (ces) {
          ces.style.display = "block";
        }
      }
    },
    // CesDocumentPosition(e){//修改提示位置
    //   const ces = document.getElementsByClassName("cesium-tooltip")[0];
    //   ces.style.webkitTransform = `translate3d(${e.x-330}px, ${e.y-110}px, 0px)`
    //   ces.style.transform = `translate3d(${e.x-330}px, ${e.y-110}px, 0px)`
    // },
    addid() {
      let positions = this.rowList.locationArea.split(" ").join(",").split(",");
      positions.forEach((element, index) => {
        positions[index] = Number(positions[index]);
      });
      if (positions.length > 3) {
        let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
        let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
        if (positions.length % 2 !== 0) return;
        entity = viewer.entities.add({
          name: "Red polygon on surface",
          id: this.rowList.id,
          position,
          label: {
            text: this.rowList.taskTitle,
            font: "20px monospace",
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(5, 5),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            fillColor: Cesium.Color.WHITE,
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
              0,
              200000
            ),
          },
          polygon: {
            hierarchy,
            material:
              this.rowList.typeId === 1
                ? Cesium.Color.fromCssColorString("#00FF11").withAlpha(0.2)
                : this.rowList.typeId === 2
                ? Cesium.Color.RED.withAlpha(0.2)
                : Cesium.Color.YELLOW.withAlpha(0.2),
            outline: true,
            outlineColor: Cesium.Color.BLACK,
          },
        });
        noFlyEntities.push(entity);
      }
      console.log(
        viewer.entities.values.filter((v) => v.id == this.rowList.id)[0]
      );
      this.flyToTaskId();
    },
    flyToTaskId() {
      viewer.flyTo(
        viewer.entities.values.filter((v) => v.id == this.rowList.id)[0]
      );
    },
    add() {
      this.viewList.map((item, index) => {
        let positions = item.region.split(" ").join(",").split(",");
        positions.forEach((element, index) => {
          positions[index] = Number(positions[index]);
        });
        if (positions && item.typeId === 2) {
          this.allairArray.push(positions);
        }
        if (positions.length > 3) {
          let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
          let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
          if (positions.length % 2 !== 0) return;
          entity = viewer.entities.add({
            name: "Red polygon on surface",
            id: item.id,
            position,
            label: {
              text: item.name,
              font: "20px monospace",
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(5, 5),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              fillColor: Cesium.Color.WHITE,
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                0,
                200000
              ),
            },
            polygon: {
              hierarchy,
              material:
                item.typeId === 1
                  ? Cesium.Color.fromCssColorString("#00FF11").withAlpha(0.2)
                  : item.typeId === 2
                  ? Cesium.Color.RED.withAlpha(0.2)
                  : Cesium.Color.YELLOW.withAlpha(0.2),
              outline: true,
              outlineColor: Cesium.Color.BLACK,
            },
          });
          noFlyEntities.push(entity);
        }
      });
      // 将所有禁飞区回传到父组件
      this.addallairArray(this.allairArray);
    },
    // 获取所有空域数据
    async async_get_list() {
      // 空域可视化
      if (this.allairspce) {
        if (this.search.name === null || this.search.name === "") {
          this.$message.warning("请先输入要搜索的空域");
        } else {
          let res = await API.AIRSPACE.selectLikeList({
            ...this.search,
            status: 1,
            pageNo: 1,
            pageSize: 10000,
          });
          if (res.status === 1 && res.data.data.length == 0) {
            this.$message.warning("未搜索到相关空域");
          } else {
            viewer.entities.removeAll();
            this.viewList = res.data.data;
            this.add();
            viewer.flyTo(viewer.entities, {
              // 让它展示完全
              offset: {
                heading: viewer.camera.heading,
                pitch: Cesium.Math.toRadians(-90),
                roll: 0.0,
              },
            });
          }
        }
      } else {
        // 新增/修改/查看空域
        if (entity) {
          viewer.entities.removeAll();
        }
        let res = await API.AIRSPACE.selectLikeList({
          ...this.search,
          status: 1,
          pageNo: 1,
          pageSize: 10000,
        });
        console.log(res);
        if (res.status === 1) {
          // 根据airspcelist判断是否是修改空域，所有空域列表中过滤掉当前空域
          if (this.airspcelist && this.airspcelist.id) {
            let list = res.data.data;
            list.length > 0
              ? (this.viewList = list.filter(
                  (item) => item.id != this.airspcelist.id
                ))
              : (this.viewList = list);
          } else {
            this.viewList = res.data.data;
          }
          this.add();
        } else {
          this.$message.error(res.message);
        }
      }
    },
  },
  mounted() {
    const { VUE_APP_IP } = process.env;
    this.VUE_APP_IP = VUE_APP_IP;
    this.init_viewer();
    setTimeout(() => {
      this.drawWarningPoints();
    }, 0);
    if (this.drakyStyle) {
      let aa = document.getElementsByClassName("cesium-tooltip");
      aa[0].classList.add("hewanbai-kytip");
    }
    const ces = document.getElementsByClassName("cesium-tooltip")[0];
    this.cesDocument = ces;
    if (this.rowList) {
      let _this = this;
      _this.cleartooltip();
    }
  },
  beforeDestroy() {
    this.CesiumLayerMouseLeave();
  },
  destroyed() {
    if (!this.readonly) {
      const ces = document.getElementsByClassName("cesium-tooltip")[0];
      if (ces) {
        ces.parentElement.removeChild(ces);
      }
    }
    if (entity) {
      viewer.entities.removeAll();
    }
  },
  watch: {
    my_layers_data: function (newVal, oldVal) {
      if (newVal != oldVal) {
        viewer.imageryLayers.removeAll();
        if (newVal === "night") {
          viewer.imageryLayers.addImageryProvider(
            new Cesium.UrlTemplateImageryProvider({
              url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
            })
          );
        } else if (newVal === "tian") {
          console.log(newVal, "tiantiantian");
          // //高德影像地图数据图层，自带注记
          viewer.imageryLayers.addImageryProvider(
            new Cesium.WebMapTileServiceImageryProvider({
              url: "https://t0.tianditu.gov.cn/img_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
              layer: "img",
              style: "default",
              tileMatrixSetID: "w",
              format: "tiles",
              maximumLevel: 18,
            })
          );

          viewer.imageryLayers.addImageryProvider(
            new Cesium.WebMapTileServiceImageryProvider({
              url: "https://t0.tianditu.gov.cn/cia_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
              layer: "cia",
              style: "default",
              tileMatrixSetID: "w",
              format: "tiles",
              maximumLevel: 18,
            })
          );
        }
      }
    },
  },
};
</script>
<style>
.hewanbai-kytip {
  top: 90px !important;
  left: 354px;
  z-index: 9999;
}
.cesium-tooltip {
  top: 90px !important;
  left: 354px;
  z-index: 9999;
}
</style>
<style lang="scss" scoped>
::v-deep {
  .select {
    .el-input__inner {
      border: 1px solid #018fc1;
      background: rgba(12, 34, 67, 0.5);
      border-right: 0px;
      font-size: 16px;
      color: #9fc4ff;
      font-weight: 400;
    }
    .el-input-group__append {
      border: 1px solid #018fc1;
      background: rgba(12, 34, 67, 0.5);
      border-left: 0px;
      line-height: 38px;
    }
    .el-input__suffix {
      line-height: 42px;
    }
  }
}
.cpt-draw {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;

  #draw-container {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .seach {
    position: absolute;
    z-index: 1000;
    top: 40px;
    left: 40px;
    .input-with-name {
      width: 250px;
    }
  }
  .middle {
    position: absolute;
    z-index: 1000;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .back-button {
    z-index: 1000;
    position: absolute;
    top: 40px;
    right: 40px;
    color: #fff;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-image: url(~@/assets/platform/message/task/details/closure.png);
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>
