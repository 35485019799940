<template>
  <div class="demandapproval">
    <!-- 任务完结库 -->
    <Dialog v-if="initiatingDemandPopupIsShow" v-show="type === 1" @on-close="closeIDsPopup" title="任务完结库" :width="1497"
      :top="120" :left="120">
      <div class="content pr">
        <!-- 右侧列表 -->
        <div class="list" style="height:75vh">
          <!-- 顶部两个个东西 -->
          <div class="approval-info state">
            <!-- 待派单 -->
            <div class="jc hwb-flex  spbtg" :class="selectInfo.isSubtasks===2?'selected':null" style="color:#50F9F4;" @click="upSingular(2)">
              <div class="jc-tp">
                <img src="./img/5.png" alt="">
              </div>
              <div class="jc-text">
                <div class="num">{{ distributionQuantity.one }}</div>
                <div class="text">单任务</div>
              </div>
            </div>
            <!-- 已派单 -->
            <div class="jc hwb-flex  sptg" :class="selectInfo.isSubtasks===1?'selected':null" style="color:#50F9F4;" @click="upSingular(1)">
              <div class="jc-tp">
                <img src="./img/7.png" alt="">
              </div>
              <div class="jc-text">
                <div class="num">{{ distributionQuantity.many }}</div>
                <div class="text">多任务</div>
              </div>
            </div>
          </div>
          <!-- 赛选栏 -->
          <div class="select">
            <el-cascader popper-class="input-orgname" placeholder="请选择所属机构" v-model="selectInfo.organizationId"
              clearable :options="selectList" :show-all-levels="false" :props="{
                children: 'child',
                label: 'name',
                value: 'id',
                checkStrictly: true,
                emitPath: false,
                empty: false
              }" class="button">
            </el-cascader>
            
            <el-select v-model="selectInfo.taskCateId" size="mini" clearable placeholder="请选择任务类型" class="button">
              <el-option v-for="item in drd.cates" :key="item.id" :label="item.title" :value="item.id" />
            </el-select>
            <el-select v-model="selectInfo.taskTypeId" size="mini" clearable placeholder="请选择场景类型" class="button">
              <el-option v-for="item in drd.scenes" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
            <el-input
            v-model="selectInfo.name"
            class="w170 ml10 dib "
            size="mini"
            placeholder="请输入任务单名称"
            clearable
          ></el-input>
            <div class="search-button" @click="async_get_list('search')">搜索</div>
          </div>
          <!-- 表格 -->

          <div class="tablesnot">
            <el-table :data="dataSource" class="hwb-table-new"
            stripe
          :header-row-style="{ 'background-color': 'rgba(138,152,177,0.28)', color: '#fff', 'font-size': '14px' }"
        :max-height="550" 
              row-key="id" :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
              :cell-style="cellStyleFilter" 
              :header-cell-style="{ textAlign: 'center' }">
              <el-table-column prop="index" label="序号" :width="60"></el-table-column>
              <el-table-column prop="taskTitle" :show-overflow-tooltip="true" label="需求单名称"></el-table-column>
              <el-table-column prop="taskCateName" label="任务类型">
                <template slot-scope="scope">
                  <div class="action" :style="`color:${scope.row.taskCateName == '常态任务' ? '#6AFF64' : '#FF3636'}`">
                    {{ scope.row.taskCateName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="taskTypeName" label="任务场景"></el-table-column>
              <el-table-column prop="applyStatusName" label="审核状态">
                <template slot-scope="scope">
                  <div class="action" :style="scope.row.isChild ? '' : getApplyColor(scope.row.papplyStatus)">
                    {{ scope.row.isChild ? '/' : map.apply[scope.row.papplyStatus] }}  
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="disposeStatus" label="调度状态">
                <template slot-scope="scope">
                  <div class="action" :style="`color:${scope.row.disposeStatus == 1 ? '#FFBD36' : '#00D2FF'}`">
                    {{ scope.row.disposeStatus == 1 ? '待派单' : '已派单' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="taskStatus" label="执行状态">
                <template slot-scope="scope">
                  <div class="action" :style="getApplyColor(scope.row.taskStatus)">
                    {{ map.status[scope.row.taskStatus] }}
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="organizationName" :show-overflow-tooltip="true" label="单位名称"></el-table-column> -->
              <el-table-column prop="addTime" :show-overflow-tooltip="true" label="开始时间"></el-table-column>
              <el-table-column prop="endAt" :show-overflow-tooltip="true" label="结束时间"></el-table-column>
              <el-table-column prop="action" label="操作" width="180">
                <template slot-scope="scope">
                  <div class="action">
                    <div v-if="(scope.row.child && scope.row.child.length == 0) || !scope.row.child">
                      <span v-if="scope.row.reportStatus==1"  title="查看报告" @click="showFn(scope.row)" class="iconfont cp icon-daipaidan"
                        style="color:#35D9E7;"></span>
                    </div>


                    <span v-if="scope.row.child && scope.row.child.length > 0" title="查看详情" @click="showDetailsInfo(scope.row.id)" class="iconfont cp icon-chakanxiangqing"
                      style="color:#35D9E7;"></span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pageCom class="pa bottom0" :pagination="pagination" @on-pageChange="pageChange" />
          </div>
        </div>
      </div>
    </Dialog>
    <!-- 无人机作业报告 -->
    <Dialog v-if="initiatingDemandPopupIsShow && ReportDetail" v-show="type === 2" @on-close="type = 1" title="无人机作业报告"
      :width="1200" :top="120" :left="120">
      <div class="Details_Item_Left mb10">
          <span class="Left_Item_Icon"></span>
          <span class="Left_Item_Title">需求单详情</span>
        </div>
      <!-- 基础页面 -->
      <div style="color:#AAB7C3">
        <div class="jcsb listInfo">
          <div>任务类别：<span style="color:#6AFF64 ">{{ ReportDetail.cate }}</span></div>
          <div>任务场景：<span class="cf">{{ ReportDetail.taskType || '暂无' }}</span></div>
          <div>发起单位：<span class="cf">{{ ReportDetail.organizationName }}</span></div>
          <div></div>

        </div>
        <div class="jcsb mt24  listInfo" style="color:#AAB7C3">
          <div>发起人姓名：<span class="cf">{{ReportDetail.name}}</span></div>
          <div >审核人：<span class="cf">{{ ReportDetail.reportApplyUser || '暂无' }}</span></div>
          <div>生成时间：<span class="cf">{{ ReportDetail.addtime }}</span></div>
          <div> </div>
        </div>
      </div>
      <div>
        <div class="Details_Item_Left mt32">
          <span class="Left_Item_Icon"></span>
          <span class="Left_Item_Title">飞行记录</span>
        </div>
      </div>
      <div class="jcsb mt14  listInfo" style="color:#AAB7C3">
        <div>飞行总架次：<span class="cf dib mr5">{{ ReportDetail.sortieNum }}</span>次 </div>
        <!-- <div>飞行总时间:<span class="cf">{{ ReportDetail.executeTime }}-{{ ReportDetail.endTime }}</span></div> -->
      
        <div>飞行总里程：<span class="cf dib mr5">{{ ReportDetail.flightDistance }} </span>m</div>
        <div>飞行总时长：<span class="cf dib mr5 ">{{ (ReportDetail.flightTime/60).toFixed(1) }}</span>min</div>
        <div>飞手姓名：<span class="cf">{{ ReportDetail.operation }}</span></div>
        <div></div>
      </div>
      <div class="jcsb mt24 listInfo " style="color:#AAB7C3">
        <div>派单员：<span class="cf dib mr5">{{ ReportDetail.handleUser }}</span> </div>
        <div>详细作业位置：<span class="cf">{{ ReportDetail.origin }}</span></div>
        <div>预警次数：<span class="cf dib mr5">{{ ReportDetail.warningNum }}</span>次</div>
        <div></div>
        <div></div>
      </div>
      <div class="Details_Item_Left mt32">
        <span class="Left_Item_Icon"></span>
        <span class="Left_Item_Title">预警信息</span>
      </div>
      <!-- 预警信息 -->
      <div class="earlyWarningBox mt17">
        <div class="earlyWarning_hd">
          <span>预警类型</span>
          <span>图片</span>
          <!-- <span>视频</span> -->
          <span>预警时间</span>
        </div>
        <div class="earlyWarning_hb-box">
          <div class="earlyWarning_hb mt14 pt14 pb14" v-for="(item,index) in ReportDetail.ais" :key="index">
            <div>{{item.warningTypeName}}</div>
            <div class="h88">
              <div class="w126 ht100 imgAndVideo">
                <el-image class="w122 mt2 dib h84" style=" height: 84px" :src="getSrc(item.warningPhotoStorage)"
                  :preview-src-list="getSrcList(item.warningPhotoStorage)">
                </el-image>
              </div>
            </div>
            <!-- <div class="h88"  @click="showDialogVisibleVideo(item.video)">
              <div class="w126 ht100 imgAndVideo cp "  >
                <video class="w126 ht100 " controls="controls" controlslist="download fullscreen noremoteplayback"
                  :disablePictureInPicture="false" autoplay>
                  <source :src="item.video" type="video/mp4">
                </video>
              </div>
            </div> -->
            <div>{{item.warningTime|| '暂无'}}</div>
          </div>
        </div>
      </div>


      <div class="Details_Item_Left mt32">
        <span class="Left_Item_Icon"></span>
        <span class="Left_Item_Title">报告总结</span>
      </div>
      <div class="mt14 pt14   cf indent bgBlack" style="min-height:4vh">
        {{ ReportDetail.remark || '暂无' }}
      </div>
    </Dialog>
    <el-dialog
      title="视频"
      :visible.sync="dialogVisibleVideo"
      width="30%"
      :before-close='handleClose'
      >
      <video class="wih100 ht100 " controls="controls" controlslist="download fullscreen noremoteplayback"
                      :disablePictureInPicture="false" autoplay>
                      <source :src="videoUrl" type="video/mp4">
                    </video>
    </el-dialog>
    <!-- 如果是多任务，点击打开需求单详情 -->
    <detailsPopup v-if="Details_List" :Details_List="Details_List" @on-closeDetails="Details_List =  null"/>
  </div>
</template>

<script>
let locationEntity;
import Dialog from '@/components/common/new-dialog/index-tow.vue'; // 弹框组件
import detailsPopup from './details-popup/index.vue'; // 详情页面弹框
import Table from "@/components/common/table-new-hwb"; // 列表组件l
import pageCom from "@/components/common/page-com-hwb"; // 分页组件
import API from "@/api";
import { nanoid } from "nanoid";
import { mapGetters } from "vuex"
import { json } from 'body-parser';
export default {
  components: {
    Dialog,
    Table,
    pageCom,
    detailsPopup
  },
  data() {
    return {
      Details_List: null, // 多任务详情信息存储
      dialogVisibleVideo:false,
      VUE_APP_IMGIP: "",
      videoUrl:"",
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ],
      distributionQuantity: { // 单任务 多任务 数量
        many: 0,
        one: 0,
      },
      selectInfo: { // 赛选的信息
        disposeStatus: null, //派单状态：1待派单 2已派单
        isSubtasks: 2, // 1多任务 2单任务
        organizationId: null, // 机构id
        taskCateId: null, // 任务类别
        taskTypeId: null, // 任务场景
        name:null
      },
      generalTaskReportId: 23,
      aisId: null,// 要修改的预警信息的id
      aisPictrueOrVideoList: null, // 要修改的预警已选择的图片或则视频
      selectType: null, // 打开飞行成果选择时选择的是图片还是视频
      waringColumns: [
        { prop: "warningTypeName", slot: true, label: "预警类别" },
        { prop: "warningPhotoUrl", slot: true, label: "图片" },
        { prop: "video", slot: true, label: "视频" },
        { prop: "remark", slot: true, label: "目标事物信息" },
        { prop: "action", slot: true, label: "操作" },
      ],
      alertTypeDropDown: null, //预警类型
      text: null, // 测试文本框
      RouteDrawingShow: false,//绘制航线显示隐藏
      itemValue: null,//点击派单每一行的数据
      itemValuer: null,//点击派单每一行的数据
      reportStaus: false,//任务报告页面显示状态
      tabledata: null,//表格内点击存储的数据
      singular: 2,//已派单 2单任务/1 多任务状态切换
      tableStaus: 1,// 1待派单/2已派单表格状态切换
      leaflets: false, //派单弹框的状态
      type: 1,  //为1时主页面展示
      organId: null, // 机构赛选的id
      selectList: null, // 赛选的机构
      initiatingDemandPopupIsShow: true, // 控制需求单子任务弹框是否展示
      areaPointList: null,
      ReportDetail: null,// 任务报告详情
      drd: {
        cates: [
          { id: 2, title: "常态任务" },
          { id: 3, title: "临时紧急任务" },
        ],
        scenes: null,
        uav_type: [],
        mount_type: [],
        task_type: [],
        task_cate: [],
        flightLine: [],
        proStatus: [
          { id: 1, label: "待处理", value: 0 },
          { id: 2, label: "处理完成", value: 1 },
          { id: 3, label: "无需处理", value: 2 },
        ],
        cityType: [
          {
            id: 13,
            title: "济源市",
            children: [
              { id: 1, title: "沁园街道" },
              { id: 2, title: "济水街道" },
              { id: 3, title: "北海街道" },
              { id: 4, title: "天坛街道" },
              { id: 5, title: "玉泉街道" },
              { id: 6, title: "克井镇" },
              { id: 7, title: "五龙口镇" },
              { id: 8, title: "轵城镇" },
              { id: 9, title: "承留镇" },
              { id: 10, title: "邵原镇" },
              { id: 11, title: "坡头镇" },
              { id: 12, title: "梨林镇" },
              { id: 17, title: "大峪镇" },
              { id: 14, title: "思礼镇" },
              { id: 15, title: "王屋镇" },
              { id: 16, title: "下冶镇" },


            ],
          },
        ],
      },
      columns: [
        { prop: "index", label: "序号", width: "120px" },
        { prop: "taskTitle", label: "任务单名称", width: "270px" },
        { prop: "taskCateName", slot: true, label: "任务类型", width: "150px" },
        { prop: "taskTypeName", label: "任务场景", width: "150px" },
        { prop: "papplyStatus", slot: true, label: "审核状态", width: "150px" },
        { prop: "taskStatus", slot: true, label: "执行状态", width: "170px" },
        { prop: "dutyOrganName", label: "单位名称", width: "170px" },
        { prop: "addTime", label: "开始时间", width: "170px" },
        { prop: "endAt", label: "结束时间", width: "170px" },
        { prop: "action", slot: true, label: "操作", width: "200px" },
      ],
      dataSource: [],
      map: {
        status: {
          "-2": "已拒绝",
          "-1": "已取消",
          0: "待执行",
          1: "已完成",
          3: "执行中",
        },
        apply: {
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      dialogIsShow: false, //审批框状态
      taskData: null, //任务信息
      Approved: false, //审批通过弹框状态
      ApprovalFailed: false, //审批不通过弹框状态
      ApprovalFailedTextarea: null, // 审批不通过理由
      radio: '1', // 审批通过之后默认使用单任务
    };
  },
  computed: {
    ...mapGetters(["dbox_data"]),
    ...mapGetters(["user_info_hn"])
  },
  beforeDestroy() {
    this.type = 1

  },
  methods: {
    cellStyleFilter({column,columnIndex}){
      if (columnIndex==0) {
        return 'textAlign:center;'
      } else {
        return 'textAlign:center;'
      }
    },
    async showDetailsInfo(id) { //打开详情弹框
      const res = await API.TASK.approveMsgInfo(id)
      if(res.status === 1){
        this.Details_List = res.data
      } else {
        this.$message.error(res.message)
      }
    },
    handleClose(done) {
      done();
      },
    showDialogVisibleVideo(url){
      if(url){
        this.videoUrl = url
        this.dialogVisibleVideo = true
      }
    },
    getSrc(Srclsit) {
      let src = Srclsit.split(',')
      // return 'http://' + src[0]
      return src[0]
    },
    getSrcList(Srclsit) {
      let src = Srclsit.split(',')
      let newSrc = []
      for (let index = 0; index < src.length; index++) {
        newSrc.push(src[index])
      }
      return newSrc
    },
    async showFn(item) {
      // 任务报告详情接口
      let { data: res } = await API.TASK.getReportDetail(item.id);

      this.ReportDetail = res
      this.type = 2
    },
    // tableRowClassName({ row, rowIndex }) {  // 控制任务状态
    //   if (row.isChild == 1) {
    //     return 'dan-row-child';
    //   } else if (rowIndex % 2 == 0) {
    //     return 'dan-row';
    //   } else if (rowIndex % 2 == 1) {
    //     return 'shuang-row';
    //   }
    //   return '';
    // },
    getApplyColor(status) { //审批状态颜色
      switch (status) {
        case 0:
          return "color: #FFBD36";
          break;
        case 1:
          return "color: #4799F3";
          break;
        case 3:
          return "color: #4799F3";
          break;
        case 2:
          return "color: #FF3636";
          break;
        default:
          break;
      }
    },
    clearAll() { //子弹框点击航线绘制隐藏所有弹框并打开航线绘制弹框
      this.leaflets = false
      this.RouteDrawingShow = true
    },
    // updataTableStaus(state) { // 待派单  已派单 请求列表
    //   this.selectInfo.disposeStatus = state
    //   this.async_get_list()
    // },
    upSingular(state) { // 选择单多任务
      this.selectInfo.isSubtasks != state ? this.selectInfo.isSubtasks = state : this.selectInfo.isSubtasks = null
      this.singular = state
      this.pagination.page=1
      this.async_get_list()
    },
    pageChange(page) { // 翻页操作
      console.log(page);
      this.pagination.page = page;
      this.async_get_list();
    },
    async async_get_list(state) { // 获取列表数据
    if (state == 'search') {
      this.pagination.page = 1
    }
      const { map } = this;
      const { page, pageSize } = this.pagination;
      let obj = {
        ...this.selectInfo,
        pageNum: page,
        taskStatus: 1,
        pageSize
      }
      let res = await API.TASK.intuitive(obj);
      res = res.data;
      this.distributionQuantity.one = res.data.one
      this.distributionQuantity.many = res.data.many
      this.dataSource = res.data.task || [];
      this.dataSource = this.dataSource.map((item, index) => {
        item.child.forEach(element => {
          element.isChild = 1
        })
        return {
          ...item,
          index: (page - 1) * pageSize + index + 1,
          taskStatusName: map.status[item.taskStatus],
          applyStatusName: map.apply[item.papplyStatus],
          processingStatusName: map.processing[item.processingStatus],
        }
      });

      this.pagination.page = res.pageNo;
      this.pagination.total = res.totalCount;
    },
    closeIDsPopup() { // 关闭不通过原因弹框
      this.$emit("closeDemand");
    },
    getAllOrganization() {
      let data = this.dbox_data
      this.selectList = this.getTreeData(data);
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    // 获取场景类型
    async getScenes() {
      let res = await API.TASK.GetDrdCate();
      res = res.data;
      this.drd.scenes = res
    },

    openList() { //关闭航线绘制，打开派单弹框
      this.RouteDrawingShow = false
      this.leaflets = true
      this.$refs.Details.approvalDistribution()
    },
    clearList() { //关闭派单弹框，打开列表弹框
      this.leaflets = false
      this.initiatingDemandPopupIsShow = true
      this.$refs.Details.clearvalue()
    },
    coloseNeedPopup() {
      this.dialogIsShow = false
    },
  },
  mounted() {
    this.getScenes()
    this.getAllOrganization()
    this.async_get_list()
    const { VUE_APP_IMGIP } = process.env;
    this.VUE_APP_IMGIP = VUE_APP_IMGIP
  },
};
</script>

<style lang="scss" scoped>
  .listInfo{
    div{
      width:250px
    }
  }
.content {

  /* 右侧 */
  .list {
    width: 100%;
    height: 830px;

    .state {
      display: flex;

      .jc {
        margin-right: 24px;
        cursor: pointer;
      }
    }

    .approval-info {
      width: 100%;
      height: 109px;

      .dsp {
        background-image: url(./img/1.png);
      }

      .sptg {
        background-image: url(./img/2.png);
        &.selected{
          background-image: url(~@/assets/img/click-1.png);
        }
      }

      .mrtg {
        background-image: url(./img/3.png);
      }

      .spbtg {
        background-image: url(./img/4.png);
        &.selected{
          background-image: url(~@/assets/img/click-2.png);
        }
      }

      .jc {
        width: 204px;
        height: 109px;

        .jc-tp {
          width: 46px;
          height: 46px;
          font-size: 46px;
          margin-left: 20px;

          &.iconfont.icon-leijifeihangjiaci {
            background: linear-gradient(180deg, #FFFFFF 0%, #D0FDFC 59%, #50F9F4 100%);
            //将字体图标改为只显示背景在字体上，就完成了字体的渐变
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-leijifeihangshichang {
            background: linear-gradient(180deg, #CBF3FF 0%, #9BE8FF 59%, #00C4FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-leijifeihangjuli {
            background: linear-gradient(180deg, #EFFFF5 0%, #82FFD0 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-leijirenwushuliang {
            background: linear-gradient(180deg, #FFF0C8 0%, #FFCE4F 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .jc-text {
          text-align: center;
          width: 120px;

          .num {
            font-size: 32px;
            font-family: DINAlternate-Bold, DINAlternate;
          }

          .text {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #DAE4FF;
          }
        }
      }
    }

    .select {
      margin: 20px 0 24px 0;

      ::v-deep {
        .el-input__inner {
          background-color: #000;
          border: 0;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          color: #fff;
        }
      }

      .button {
        margin-right: 10px;
        width: 210px;
      }

      .search-button {
        display: inline-block;
        width: 54px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-left: 10px;
        cursor: pointer;
        border-radius: 4px;
        background-color: #000;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
      }
    }

    .task-table {
      height: 600px;
    }

    .taskSwitch {
      margin-top: 20px;
      display: flex;

      .btn {
        width: 100px;
        height: 32px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 32px;
        text-align: center;
      }

      .one {
        background: #1F4DCB;
        cursor: pointer;
      }

      .two {
        background: rgba(0, 0, 0, 0.7);
        cursor: pointer;
      }
    }
  }
}

.dispatch {
  height: 556px;
  width: 100%;
  overflow: auto;

  .need-top-title {
    height: 34px;

    .left {
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      padding-left: 10px;
      position: relative;

      .dec {
        position: absolute;
        top: 2px;
        left: 0;
        width: 4px;
        height: 16px;
        background: #FFBD36;
        border-radius: 2px;
      }
    }

    .right {
      font-size: 14px;
      color: #AAB7C3;
    }
  }

  .need-bottom {
    margin-top: 28px;
    font-size: 14px;
    color: #AAB7C3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .info-add {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    .one-info {
      width: 33.3%;
      margin-bottom: 12px;
    }

    .one-info-long {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .detail {
    height: 107px;
    margin-top: 12px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    padding: 16px 24px;
    line-height: 24px;
  }

  .button {
    margin-top: 40px;
    padding: 0 140px;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-align: center;

    .no {
      width: 126px;
      height: 40px;
      background: rgba(219, 27, 27, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    }

    .yes {
      width: 126px;
      height: 40px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    }
  }
}

.Approved {
  .Approved_Body {
    width: 100%;

    &.FailedTextarea {
      margin-bottom: 20px;
    }

    ::v-deep {
      .el-textarea {
        .el-textarea__inner {
          background-color: rgba(0, 0, 0, 0.6);
          ;
          border: none;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }

    .Approved_Body_Item {
      width: 100%;
      text-align: center;
      margin-bottom: 21px;

      .iconApproved {
        color: #6AFF64;
      }

      .Item_Label {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 12px;
      }

      .Item_Label_Select {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  .Approved_Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-align: center;

    .no {
      cursor: pointer;
      width: 126px;
      height: 40px;
      // background: rgba(219,27,27,0.71);
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-right: 20px;
    }

    .yes {
      cursor: pointer;
      width: 126px;
      height: 40px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-left: 20px;
    }
  }
}

::v-deep.compLOG {
  .el-dialog .el-dialog__body {
    padding: 60px 130px 32px;
  }

  .complete {
    width: 100%;

    .text {
      height: 26px;
      font-size: 20px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      line-height: 26px;
      text-align: center;
      margin-top: 50px;
    }

    .btns {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;

      .btn {
        width: 126px;
        height: 40px;
        background: rgba(38, 71, 238, 0.71);
        box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
        border-radius: 6px;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .btntext {
          height: 24px;
          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: #FFFFFF;
        }
      }
    }
  }
}

.report {
  width: 100%;
  height: 100%;

  .reportBody {
    width: 100%;
    height: 895px;

    .name {
      display: flex;
      flex-wrap: wrap;

      .text {
        width: 25%;
        height: 20px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #AAB7C3;
        line-height: 20px;
        margin-bottom: 24px;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;


        .color {
          color: #FFFFFF;
        }
      }
    }

    .flight-record {
      margin-top: 8px;

      .title {
        height: 24px;
        align-items: center;
        display: flex;

        .icon {
          width: 4px;
          height: 14px;
          background: #FFC300;
          border-radius: 2px;
        }

        .text {
          margin-left: 5px;
          height: 24px;
          font-size: 16px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 24px;
        }
      }

      .body {
        margin-top: 14px;
        display: flex;
        flex-wrap: wrap;

        .text {
          width: 25%;
          height: 20px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #AAB7C3;
          line-height: 20px;
          margin-bottom: 24px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          .color {
            color: #FFFFFF;
          }
        }
      }
    }

    .waring {
      margin-top: 11px;

      .box {
        display: flex;
        justify-content: space-between;
        height: 30px;

        .title {
          height: 24px;
          align-items: center;
          display: flex;

          .icon {
            width: 4px;
            height: 14px;
            background: #FFC300;
            border-radius: 2px;
          }

          .text {
            margin-left: 5px;
            height: 24px;
            font-size: 16px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 30px;
          }
        }

        .add {
          width: 86px;
          height: 30px;
          background: rgba(38, 71, 238, 0.71);
          box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
          border-radius: 2px;
          border: 1px solid;
          border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;

          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 30px;
          text-align: center;
        }
      }

      .body {
        margin-top: 17px;
        width: 100%;
        height: 308px;

      }
    }

    .summary {
      margin-top: 32px;

      .title {
        height: 24px;
        align-items: center;
        display: flex;

        .icon {
          width: 4px;
          height: 14px;
          background: #FFC300;
          border-radius: 2px;
        }

        .text {
          margin-left: 5px;
          height: 24px;
          font-size: 16px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 24px;
        }
      }

      .input {
        margin-top: 14px;

        ::v-deep.el-textarea {
          .el-textarea__inner {
            background: rgba(0, 0, 0, 0.6);
            border: none;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            max-height: 107px;
          }
        }
      }
    }

    .footer {
      margin-top: 40px;

      &-btns {
        display: flex;
        justify-content: space-between;
        padding: 0 389px;

        .btn {
          width: 126px;
          height: 40px;
          background: rgba(38, 71, 238, 0.71);
          box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
          border-radius: 6px;
          border: 1px solid;
          border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;

          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: #FFFFFF;
          line-height: 40px;
          text-align: center;
        }
      }
    }
  }
}

.add-picture {
  width: 32px;
  display: inline-block;
  height: 32px;
  background: url(./img/add.png) no-repeat;
  cursor: pointer;
}

.Details_Item_Left {
  display: flex;
  align-items: center;

  .Left_Item_Icon {
    display: inline-block;
    width: 4px;
    height: 16px;
    background: #FFBD36;
    border-radius: 2px;
  }

  .Left_Item_Title {
    padding-left: 5px;
    width: 90px;
    height: 24px;
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 24px;
  }
}

.earlyWarningBox {
  .earlyWarning_hd {
    display: flex;
    align-items: center;
    background: rgba(138, 152, 177, 0.18);
    height: 48px;

    span {
      display: inline-block;
      text-align: center;
      // width: calc(100% / 4);
      width: calc(100% / 3);
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }
  }

  .earlyWarning_hb-box {
    height: 300px;
    overflow-y: auto;

    .earlyWarning_hb {
      display: flex;
      color: #fff;
      height: 116px;
      background: rgba(138, 152, 177, 0.18);
      border: 1px solid #73A6D1;
      align-items: center;

      div {
        text-align: center;
        // width: calc(100% / 4);
        width: calc(100% / 3);
      }

      .imgAndVideo {
        margin: 0 auto;
        display: inline-block;
        background: rgba(0, 0, 0, 0.24);
        border: 1px solid #B4BCBE;
      }
    }
  }

  ::v-deep .el-icon-close {
    color: #8aabd4;
    font-size: 16px;
    position: absolute;
    right: 13px;
    top: 19px;
    transform: translateY(-50%);
    cursor: pointer;
  }
 
}
::v-deep .el-table__row{
  color: #fff;
  background-color: transparent;
}
::v-deep .el-table__row--striped {
  background: rgba(138, 152, 177, 0.18) !important;
  }
</style>