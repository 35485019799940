<template>
  <div class="hwb-page-box">
    <el-pagination class="hwb-table-pagination" @current-change="onChangePage" :current-page.sync="pagination.page"
      layout="total , prev, pager, next, jumper" :total="pagination.total" :page-size="pagination.pageSize" />
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    onChangePage(page) {
      this.$emit("on-pageChange", page)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>