<template>
  <div class="cpt-draw" @mouseenter="tipShow" @mouseleave="tipClose">
    <div
      id="draw-container"
      @mouseenter="CesiumLayerMouseEnter"
      @mouseleave="CesiumLayerMouseLeave"
    ></div>
  </div>
</template>

<script>
let handler, viewer, entity;
let noFlyEntities = [], modelList = [];
import {mapGetters} from "vuex"
import { initMap } from "@/utils/cesium.js";
import API from "@/api";
export default {
  computed: {
    ...mapGetters(["my_layers_data"]),
  },
  props: {
    leftOnclick: {
      type: Function,
      default: () => () => {},
    },
    rigthOnclick: {
      type: Function,
      default: () => () => {},
    },
    linelist: {
      type: Object,
      required: false,
      default: null,
    },
    editProps: {
      type: Object,
      required: false,
      default: null,
    },
    move: {
      type: Function,
      default: () => () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      default: null,
    },
    taskshow: {
      type: Object,
      default: null,
    },
    historicalTrack: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      viewList: null,
      tileset: null,
      VUE_APP_IP: null,
    };
  },
  methods: {
    mouseScoll(e) {
      //鼠标滚轮滚动效果
      /*这里获取元素节点*/
      let oPanel = document.getElementById("app");
      let Ripples = document.getElementsByClassName(
        "hwb-mouse-scrolling-effect"
      );
      if (Ripples.length > 0) {
        Ripples.remove();
      }
      let oSpan = document.createElement(`div`);
      /*浏览器兼容*/
      e = e || window.Event;
      /*获取相关参数*/
      oSpan.style.left = e.clientX + `px`;
      oSpan.style.top = e.clientY + `px`;
      /*给节点赋值参数数值*/
      oSpan.className = "hwb-mouse-scrolling-effect";
      /*在body中添加span标签*/
      oPanel.appendChild(oSpan);
      /*设置定时器 间隔时间为2000毫秒*/
      setTimeout(() => {
        /*清除ospan节点*/
        oSpan.remove();
      }, 600);
    },
    CesiumLayerMouseEnter() {
      // 鼠标移入地球添加鼠标滚动效果
      document.addEventListener(`mousewheel`, this.mouseScoll);
    },
    CesiumLayerMouseLeave() {
      // 鼠标移出地球添加鼠标滚动效果
      document.removeEventListener(`mousewheel`, this.mouseScoll);
    },
    tipShow() {
      // 鼠标移入地图鼠标提示文字显示
      const ces = document.getElementsByClassName("cesium-tooltip")[0];
      if (ces) {
        ces.style.display = "block";
      }
    },
    tipClose() {
      // 鼠标移出地图鼠标提示文字隐藏
      const ces = document.getElementsByClassName("cesium-tooltip")[0];
      if (ces) {
        ces.style.display = "none";
      }
    },
    init_viewer() {
      viewer = window.viewer = initMap(this.my_layers_data, "draw-container")
      // 判断是否为三维模型
      if (!this.model) {
        // 判断是否为编辑与预览
        if (this.editProps) {
          let { points } = JSON.parse(this.editProps.flightCourseJson);
          let poi = [];
          points.map((item) => {
            poi.push(Cesium.Cartesian3.fromDegrees(item.lon, item.lat, 0));
          });

          handler = new MMCM.DrawAirwayHandler({
            viewer,
            positions: poi,
            config: {
              isReadonly: this.readonly, // 是否可以操作
            },
          });
          // 禁止双击事件
          viewer.trackedEntity = undefined;
          viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
          );
          // 设置相机视角
          viewer.camera.setView({
            destination: Cesium.Cartesian3.fromDegrees(
              points[0].lon,
              points[0].lat,
              5000
            ),
          });
          this.async_get_list();
          // 判断是否为航空任务预览
        } else if (this.taskshow) {
          let { points } = this.taskshow;
          let poi = [];
          points.map((item) => {
            poi.push(Cesium.Cartesian3.fromDegrees(item.lon, item.lat, 0));
          });
          handler = new MMCM.DrawAirwayHandler({
            viewer,
            positions: poi,
            config: {
              isReadonly: this.readonly, // 是否可以操作
            },
          });
          viewer.camera.setView({
            destination: Cesium.Cartesian3.fromDegrees(
              points[0].lon,
              points[0].lat,
              5000
            ),
          });
          this.async_get_list();
        } else if (this.historicalTrack && this.historicalTrack.length > 0) {
          // 判断是不是绘制历史轨迹
          viewer.entities.add({
            polyline: {
              //航线设置
              positions: Cesium.Cartesian3.fromDegreesArrayHeights(
                this.historicalTrack
              ),
              width: 10, //给航线宽度
              material: new Cesium.PolylineArrowMaterialProperty(
                Cesium.Color.RED //给颜色
              ),
            },
          });
          viewer.flyTo(viewer.entities); //跳转到改位置
        }
        // 判断新增航线
        else {
          handler = new MMCM.DrawAirwayHandler({
            viewer,
          });
          viewer.camera.setView({
            // 设置相机位置
            destination: Cesium.Cartesian3.fromDegrees(
              120.68240528305054,
              30.458030121429956,
              5000
            ),
          });
          this.async_get_list();
        }
        if (this.historicalTrack.length <= 0) {
          // 如果不是渲染历史轨迹
          handler.setInputAction((data) => {
            this.leftOnclick(data);
          }, MMCM.DrawEventType.LEFT_CLICK);

          handler.setInputAction((data) => {},
          MMCM.DrawEventType.LEFT_DOUBLE_CLICK);

          handler.setInputAction((data) => {
            this.rigthOnclick(data);
          }, MMCM.DrawEventType.RIGHT_CLICK);

          handler.setInputAction((data) => {
            this.move(data);
          }, MMCM.DrawEventType.MOUSE_MOVE);
        }
      }
    },
    // 清除提示框
    showLine() {
      const ces = document.getElementsByClassName("cesium-tooltip")[0];
      const body = document.getElementsByTagName("body")[0];
      body.removeChild(ces);
    },
    // 三维模型跳转
    flyTo3D(data, isShow) {
      // 显示
      if (isShow) {
        // 根据id判断modelList中是否已经创建实例，是就改变属性显示并跳转，否就创建模型实例
        if (modelList[data.id]) {
          modelList[data.id].show = true;
          viewer.flyTo(modelList[data.id]);
        } else {
          let resource = new Cesium.Resource({
            url: data.url,
            retryCallback: (resource, error) => {
              if (!error.statusCode || error.statusCode === 404) {
                this.$message.error("服务数据加载失败");
              }
              return false;
            },
            retryAttempts: 1,
          });

          modelList[data.id] = viewer.scene.primitives.add(
            new Cesium.Cesium3DTileset({
              id: data.id,
              url: resource, //数据路径
              maximumScreenSpaceError: 2, //最大的屏幕空间误差
              maximumNumberOfLoadedTiles: 1000, //最大加载瓦片个数
              show: true,
            })
          );
          viewer.flyTo(modelList[data.id]);
        }
      } else {
        // 隐藏模型实例
        modelList[data.id].show = false;
      }
    },
    clear(index) {
      handler.removeEditPoint(index);
    },
    resetValue() {
      //当点击重置按钮将鼠标字体去除，后初始化方法
      let ces = document.getElementsByClassName("cesium-tooltip")[0];
      let body = document.getElementsByTagName("body")[0];
      if (ces) {
        body.removeChild(ces);
      }
      //去除刚刚编辑的内容（点）
      handler.destroy();
      //初始化方法
      handler = new MMCM.DrawAirwayHandler({
        viewer,
      });
      handler.setInputAction((data) => {
        this.leftOnclick(data);
      }, MMCM.DrawEventType.LEFT_CLICK);

      handler.setInputAction((data) => {},
      MMCM.DrawEventType.LEFT_DOUBLE_CLICK);

      handler.setInputAction((data) => {
        this.rigthOnclick(data);
      }, MMCM.DrawEventType.RIGHT_CLICK);

      handler.setInputAction((data) => {
        this.move(data);
      }, MMCM.DrawEventType.MOUSE_MOVE);
    },
    // 获取所有空域数据
    async async_get_list() {
      if (entity) {
        viewer.entities.removeAll();
      }
      let res = await API.AIRSPACE.selectLikeList({
        ...this.search,
        status: 1,
        pageNo: 1,
        pageSize: 10000,
      });
      if (res.status === 1) {
        this.viewList = res.data.data;
        this.add();
      } else {
        this.$message.error(res.message);
      }
    },
    // 地图回显
    add() {
      this.viewList.map((item, index) => {
        let positions = item.region.split(" ").join(",").split(",");
        if (positions.length > 3) {
          let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
          let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
          if (positions.length % 2 !== 0) return;
          entity = viewer.entities.add({
            name: "Red polygon on surface",
            position,
            label: {
              text: item.name,
              font: "20px monospace",
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(5, 5),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              fillColor: Cesium.Color.WHITE,
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                0,
                200000
              ),
            },
            polygon: {
              hierarchy,
              material:
                item.typeId === 1
                  ? Cesium.Color.fromCssColorString("#00FF11").withAlpha(0.2)
                  : item.typeId === 2
                  ? Cesium.Color.RED.withAlpha(0.2)
                  : Cesium.Color.YELLOW.withAlpha(0.2),
              outline: true,
              outlineColor: Cesium.Color.BLACK,
            },
          });
          noFlyEntities.push(entity);
        }
      });
    },
    sing(data, isShow) {
      this.flyTo3D(data, isShow);
      // this.init_viewer();
    },
  },
  mounted() {
    const { VUE_APP_IP } = process.env;
    this.VUE_APP_IP = VUE_APP_IP;
    this.init_viewer();
    let aa = document.getElementsByClassName("cesium-tooltip");
    aa[0].classList.add("hewanbai-linetip");
  },
  beforeDestroy() {
    this.CesiumLayerMouseLeave();
  },
  destroyed() {
    if (!this.readonly && this.model === null) {
      this.showLine();
    }
    if (document.getElementsByClassName("cesium-tooltip")[0]) {
      this.showLine();
    }
  },
  watch: {
    my_layers_data: function (newVal, oldVal) {
      if (newVal != oldVal) {
        viewer.imageryLayers.removeAll();
        if (newVal === "night") {
          viewer.imageryLayers.addImageryProvider(
            new Cesium.UrlTemplateImageryProvider({
              url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
            })
          );
        } else if (newVal === "tian") {
          console.log(newVal, "tiantiantian");
          // //高德影像地图数据图层，自带注记
          viewer.imageryLayers.addImageryProvider(
            new Cesium.WebMapTileServiceImageryProvider({
              url: "https://t0.tianditu.gov.cn/img_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
              layer: "img",
              style: "default",
              tileMatrixSetID: "w",
              format: "tiles",
              maximumLevel: 18,
            })
          );

          viewer.imageryLayers.addImageryProvider(
            new Cesium.WebMapTileServiceImageryProvider({
              url: "https://t0.tianditu.gov.cn/cia_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
              layer: "cia",
              style: "default",
              tileMatrixSetID: "w",
              format: "tiles",
              maximumLevel: 18,
            })
          );
        }
      }
    },
  },
};
</script>

<style>
.hewanbai-linetip {
  z-index: 10;
}
</style>

<style lang="scss" scoped>
.cpt-draw {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  #draw-container {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}
</style>
