<template>
    <div class="left-menu">
        <div class="body">
            <div v-for="item in iconList" :key="item.id" class="boxs" :class="`${item.id === type ? 'active' : ''}`"
                @click="select(item)" :title="item.name">
                <span class="text" :class="`${item.id === type ? 'select' : ''}`">{{ item.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        iconList: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            type: 1,
        }
    },
    methods: {
        select(value) {
            this.type = value.id
            this.$emit('on-Click', value)
        },
    }
}
</script>

<style lang="scss" scoped>
.left-menu {
    width: 100%;
    height: calc(100% - 24px);
    background: rgba(3, 8, 49, 0.4);
    box-shadow: 0px 0px 10px 0px rgba(49, 160, 209, 0.5);
    padding-top: 24px;

    .body {
        height: 100%;

        .boxs {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
                width: 20px;
                height: 19px;
                color: #7897DCFF;
            }
        }

        .active {
            background: linear-gradient(90deg, #2A67B0 0%, rgba(49, 149, 212, 0) 100%);
        }

        .text {
            font-size: 16px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #AAB7C3;
            cursor: pointer;

            &.select {
                color: #FFFFFF;
            }
        }
    }
}
</style>