<template>
        <!-- 需求单制定 -->
    <BigDialog v-if="ShowSelection" @on-close="cancelSaveArea" title="区域选择" class="area-select-popup" :top='100' :width='1600'>
    <div style="height:690px">
      <div class="content">
        <div class="button" v-if="!rowList">
          <el-button @click="cancelSaveArea" type="primary">取消</el-button>
          <el-button @click="saveArea" type="primary">保存  </el-button>
        </div>
        <Drawky
          :leftOnclick="(value) => leftOnclick(value)"
          :rigthOnclick="(value) => rigthOnclick(value)"
          :move="(value) => move(value)"
          :airspcelist="areaPointList"
          :drakyStyle="true"
          :addallairArray="(value) => addallairArray(value)"
          :rowList="rowList"
          :readonly="readonly"
        />
      </div>
    </div>
    </BigDialog>
</template>

<script>
import BigDialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import Drawky from "@/components/drawky"; // 绘制区域的
export default {
    components:{
        Drawky,
        BigDialog
    },
    props:{
        leftOnclick:{
            typeof: Function,
            default:()=>()=>{}
        },
        rigthOnclick:{
            typeof: Function,
            default:()=>()=>{}
        },
        move:{
            typeof: Function,
            default:()=>()=>{}
        },
        addallairArray:{
            typeof: Function,
            default:()=>()=>{}
        },
        cancelSaveArea:{
            typeof: Function,
            default:()=>()=>{}
        },
        saveArea:{
            typeof: Function,
            default:()=>()=>{}
        },
        ShowSelection:{
            typeof:Boolean,
            default:()=>false
        },
        areaPointList:{
            typeof:Array,
            default:()=>{}
        },
        rowList: {
            type: Object,
            default: () => {}
        },  
        readonly:{
            type: Boolean,
            default: () => false
        }
    }
}
</script>

<style scoped lang="scss">
.area-select-popup{
    // height: 800px;
}
</style>