<template>
  <div class="Safefly_Model" id="Model_Safefly">
            <!-- 表头 -->
            <div class="Safefly_Model_Header">
                <div class="Header_Label" v-for="item in cellName" :key="item.name" :style="item.width ? `width:${item.width}` :'flex:1'">{{item.name}}</div>
            </div>
            <!-- 表格内容 -->
            <div class="Safefly_Model_Body">
                <div class="Body_Cell" v-for="(item,index) in dataVal" :key="item.id">
                    <div class="Body_Row"  v-for="cell in cellName" :key="cell.name" :style="cell.width ? `width:${cell.width}` :'flex:1'">
                        <template v-if="cell.slot" class="content-inner" >
                            <div :style=" {maxWidth: cell.width? cell.width : maxw} ">
                              <slot :name="cell.value" :row="{item,index,width:cell.width}"></slot>
                            </div>
                        </template>
                        <div v-else class="tbody" :style=" {maxWidth: cell.width? cell.width : maxw} ">
                        <template v-if="item[cell.value] + ''">
                        <el-tooltip
                            effect="dark"
                            :open-delay="300"
                            :content="item[cell.value]+''"
                            placement="top"
                            popper-class="my-tooltip"
                        >
                        <div slot="content" style="max-width:280px">
                          {{ item[cell.value] }}
                        </div>
                            <div class="content-inner">
                            {{ item[cell.value] }}
                            </div>
                        </el-tooltip>
                        </template>
                        <template v-else>
                        <div class="content-inner">
                                暂无
                        </div>
                        </template>
                    </div>
                    </div>
                </div>
            </div>
            <div class="Safefly_Model_Footer" v-if="pagination">
                <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="pagination.pageNo || 1"
                 layout="total , prev, pager, next, jumper" :total="pagination.total || 0" :page-size="pagination.pageSize || 10" />
            </div>
        </div>
</template>

<script>
export default {
    props:{
         cellName:{
            typeof:Array,
            default:()=>[]
        },
         dataVal:{
            typeof:Array,
            default:()=>[]
        },
         pagination:{
            typeof:Object,
            default:()=>{}
        },

    },
    data() {
        return {
            maxw:null,//单元格内的最大宽
        };
    },
    methods:{
        onChangePage(val){
            console.log(val);
            this.$emit('onChangePage',val)
        },
    },
    mounted(){
      //将所有未设置的宽度然后取出，最后通过计算得出最后剩余的 (未设置宽度的总宽 / 未设置宽度的总表头的长度)
      let proDocu = document.getElementById('Model_Safefly').offsetWidth
      let cellLength = []
      // console.log(this.cellName);
             this.cellName.forEach(element => {
              // console.log(String(element.width).indexOf('p') > -1);
              if (String(element.width).indexOf('p') > -1) {
                // console.log(String(element.width).substr(0,String(element.width).indexOf('p')));
               proDocu   =  proDocu - String(element.width).substr(0,String(element.width).indexOf('p'))
               cellLength.push(element.width)
              }
            // console.log(proDocu);
            // console.log(cellLength.length);
            });
            //单个表格内的最大宽度要按照优先级为传入表格内的可移动的总宽除以传入的字段数量，其次在没有传入表格内可移动的总宽情况下，就按照父元素的总宽来计算出当前的每一个单元格内的数据
            console.log(this.maxw = (this.tableWidth || proDocu) / (this.cellName.length - cellLength.length) + 'px');
    }
}
</script>

<style scoped lang="scss">
    .Safefly_Model{
        display: flex;
        flex-direction: column;
        &>.Safefly_Model_Header{
            width: 100%;
            height: 50px;
            background: rgba(138,152,177,0.18);
            display: flex;
            align-items: center;
            font-weight: bold;
            &>.Header_Label{
                color: #fff;
                text-align: center;
            }
        }
        &>.Safefly_Model_Body{
          height: 460px;
          overflow: auto;
            &>.Body_Cell{
              display: flex;
              align-items: center;
                height: 48px;
                line-height: 48px;
                &:nth-child(odd){
            background: rgba(138,152,177,0);
            }
                &:nth-child(even){
            background: rgba(138,152,177,0.18);
            }
                &>.Body_Row{
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    color: #FFFFFF;
                   text-align: center;  
                   &>.iconfont {
                        color: #70f6f9;
                    }   
                   &>.tbody{
                    .content-inner{
                      white-space:nowrap;//禁止换行
                    overflow:hidden ;//超出隐藏
                    text-overflow: ellipsis;//显示省略号
                    }
                   }
                }
            }
        }
        &>.Safefly_Model_Footer{
  //列表底部
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  .cpt-table-pagination {
    //右下分页组件样式
    height: 30px;
    display: flex;
    align-items: center;

    ::v-deep {
      button {
        background: transparent;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        // border: 1px solid #488cff;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        border-radius: 4px;
        line-height: 40px;
        margin-right: 8px;
        box-sizing: border-box;
        color: #b6d4ff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:disabled {
          background: transparent;
          color: #b6d4ff;
          opacity: 0.3;
        }
      }

      .el-pagination__total {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        text-align: center;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin: 0px;
      }

      .el-pager {
      height:30px;

        .number {
          background: transparent;
          font-size: 12px;
          color: #b6d4ff;
          text-align: center;
          font-weight: lighter;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          border-radius: 4px;
          margin-right: 8px;
          box-sizing: border-box;

          &.active {
            opacity: 0.8;
            // background-image: linear-gradient(
            //   180deg,
            //   rgba(44, 123, 255, 0.5) 0%,
            //   rgba(72, 140, 255, 0) 100%
            // );
            background-color: #2647ee;
            color: #ffffff;
          }
        }

        .more {
          background: transparent;
          // line-height: 40px;
          width: 40px;
          // height: 40px;

          box-sizing: border-box;
          // border: 1px solid #629fff;
          border-radius: 4px;
          margin-right: 8px;
          font-size: 20px;
          color: #b6d4ff;
        }
      }

      .el-pagination__jump {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        width: 150px;
        height: 40px;
        margin-left: 6px;
        line-height: 40px;
        display: flex;
        align-items: center;

        .el-input {
          box-sizing: border-box;
          height: 100%;
          width: 84px;

          .el-input__inner {
            width: 60px;
            height: 30px;
            border-radius: 2px;
            border: 1px solid #b6d4ff;
            margin-top: 8px;
            // border: 1px solid #629fff;
            border-radius: 4px;
            background: transparent;
            // height: 100%;
            box-sizing: border-box;
            padding: 0;
            font-size: 18px;
            color: #90b2ec;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
          }
        }
      }
    }

    &.mini {
      height: 100%;
      display: flex;
      align-items: center;

      ::v-deep {
        button {
          height: 20px;
          width: 20px;
          line-height: 20px;
          background: transparent;
          border: none;
          color: #4d7592;
          min-width: auto;

          &.btn-next {
            margin-left: 8px;
          }
        }

        .el-pagination__total {
          font-size: 14px;
          color: #4d7592;
        }

        .el-pagination__sizes {
          display: none;
        }

        .el-pager {
          height:30px;


          .number {
            height: 100%;
            width: auto;
            line-height: 20px;
            background: transparent;
            border: none;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            min-width: 20px;
            margin-right: 0;

            &.active {
              opacity: 1;
              color: #0099ff;
            }
          }

          .more {
            height: 100%;
            width: 20px;
            line-height: 20px;
            font-size: 12px;
            border: none;
            min-width: auto;
            margin-right: 0px;

            &::before {
              line-height: 20px;
            }
          }
        }

        .el-pagination__jump {
          height: 100%;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          .el-input {
            .el-input__inner {
              border: 1px solid #4d7592;
              border-radius: 2px;
              width: auto;
              min-width: 20px;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
        }
    }
</style>