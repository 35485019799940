class getColor {
  static getTaskColor(status) { // 获取任务状态颜色
    switch (status) {  // 2常态任务 3临时紧急任务
      case 2:    
        return "color: #6AFF64";
        break;
      case 3:    
        return "color: #FF3636";
        break;
      default:
        break;
    }
  }
  static getTaskWord(status) { // 获取任务状态文字
    switch (status) {  // 2常态任务 3临时紧急任务
      case 2:  
        return "常态任务";
        break;
      case 3:  
        return "临时紧急任务";
        break;
      default:
        break;
    }
  }

  static getApplyColor(status) { //审批状态颜色
    switch (status) {  //  0待审批 1审批通过 2审批不通过 3默认通过
      case 0:
        return "color: #FFBD36";
        break;
      case 1:
        return "color: #4799F3";
        break;
      case 3:
        return "color: #4799F3";
        break;
      case 2:
        return "color: #FF3636";
        break;
      default:
        break;
    }
  }
  static getApplyWord(status) { //审批状态文字
    switch (status) {  //  0待审批 1审批通过 2审批不通过 3默认通过
      case 0:
        return "待审批";
        break;
      case 1:
        return "审批通过";
        break;
      case 3:
        return "默认通过";
        break;
      case 2:
        return "审批不通过";
        break;
      case null:
        return '/'
      default:
        break;
    }
  }

  static getDispatchColor(status) { // 派单状态颜色
    switch (status) {   // 1 待派单   2 已派单
      case 1:    
        return "color: #FFBD36";
        break;
      case 2:    
        return "color: #6AFF64";
        break;
      default:
        break;
    }
  }
  static getDispatchWord(status) { // 派单状态文字
    switch (status) {   // 1 待派单   2 已派单
      case 1:    
        return "待派单";
        break;
      case 2:    
        return "已派单";
        break;
      default:
        break;
    }
  }

  static getImplementColor(status) { //执行状态颜色
    switch (status) {  //  0待执行 1已完成  3执行中  -2已拒绝
      case 0:
        return "color: #FFBD36";
        break;
      case 1:
        return "color: #6AFF64";
        break;
      case 3:
        return "color: #00D2FF";
        break;
      case -2:
        return "color: #FF3636";
        break;
      default:
        break;
    }
  }
  static getImplementWord(status) { //执行状态文字
    switch (status) {  //  0待执行 1已完成  3执行中  -2已拒绝
      case 0:
        return "待执行";
        break;
      case 1:
        return "已完成";
        break;
      case 3:
        return "执行中";
        break;
      case -2:
        return "已拒绝";
        break;
      default:
        break;
    }
  }
}
export default getColor