<template>
  <div>
    <div>
      <Dialog v-if="RouteDrawingShow" @on-close="closeRouteDrawing" title="绘制航线" :width="1110" :top="104" :left="120">
        <div class="RouteDrawing_Top">
          <div class="Drawing_Button" @click="DrawingShow">绘制航线</div>
          <div>
            <div class="Drawing_Search">
              <el-input clearable v-model="search.flightName" size="mini" placeholder="请输入航线名称" class="input_Value">
              </el-input>
              <div @click="handle_search" class="Search_Btn iconfont icon-sousuo1"></div>
            </div>
          </div>
        </div>
        <div class="RouteDrawing_Body">
          <Table class="task-table" :columns="columns" :dataSource="dataSource">
            <template #id="{ row }">
              <div class="action">
                {{ row.index * currentPage }}
              </div>
            </template>
            <template #pointCount="{ row }">
              <div class="action">
                {{ row.pointCount + '' }}
              </div>
            </template>
            <template #distance="{ row }">
              <div class="action">
                {{ row.distance + '' }}
              </div>
            </template>
            <template #label="{ row }">
              <div :title="row.label" class="action" style="color:aqua" v-if="row.label">
                {{ row.label }}
              </div>
              <div v-else>
                /
              </div>
            </template>
            <template #status="{ row }">
              <div class="action">
                {{ row.status == 1 ? '正常' : '禁用' }}
              </div>
            </template>

            <template #issafe="{ row }">
              <div class="action">
                <span v-if="row.issafe == 1" style="color: #16D979">安全</span>
                <span v-else>/</span>
              </div>
            </template>
            <template #action="{ row }">
              <div class="action" style="height:100%;line-height: 45px;">
                <span style="font-size:22px" v-view="{ sign: 'hn:tasks:draw:viewFlight' }" v-if="routeButton1"
                  title="预览航线" class="iconfont icon-feihangguiji cp" @click="log(row)"></span>
                <span :title="row.issafe === 1 ? '取消安全' : '安全'" class="iconfont cp icon-anquan-xuanzhong f22"
                  @click="editIssafe(row)"></span>
                <span style="font-size:22px" v-view="{ sign: 'hn:tasks:draw:airlineEdit' }" v-if="routeButton2" title="更改"
                  class="iconfont icon-genggai cp" @click="edit(row)"></span>
                <span style="font-size:22px" title="删除" class="iconfont icon-shanchu cp" @click="del(row)"></span>
              </div>
            </template>
          </Table>
        </div>
        <div class="RouteDrawing_Footer">
          <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="currentPage || 1"
            layout="total , prev, pager, next, jumper" :total="total || 0" :page-size="size || 10" />
        </div>
      </Dialog>
    </div>
    <Drawing v-if="ShowDrawing" class="ml20" :editFlyLineData="editFlyLineData" :close="close" :readOnly="readOnly" />
  </div>
</template>

<script>
import Dialog from '@/components/common/new-dialog/index-tow.vue';
import Table from "@/components/common/table-new-hwb"; // 列表组件
import Drawing from './Drawing.vue'
import API from "@/api";
export default {
  components: {
    Dialog,
    Table,
    Drawing
  },
  data() {
    return {
      //是否为预览航线
      readOnly: false,
      //传入组件的数据
      editFlyLineData: null,
      //按钮权限控制
      routeButton1: false,
      routeButton2: false,
      //显示隐藏绘制航线列表
      RouteDrawingShow: true,
      //绘制航线弹框
      ShowDrawing: false,
      currentPage: 1,
      total: 0,
      size: 10,
      dataSource: [],
      columns: [
        { prop: "index", label: "序号", width: "120px" },
        { prop: "flightName", label: "航线名称", width: "300px" },
        { prop: "pointCount", label: "航点数量", width: "150px", slot: true },
        { prop: "distance", label: "航线长度", width: "150px", slot: true },
        { prop: "status", slot: true, label: "航线状态", width: "150px" },
        { prop: "issafe", slot: true, label: "安全状态", width: "150px" },
        { prop: "label", slot: true, label: "航线标签", width: "150px" },
        { prop: "action", slot: true, label: "操作", width: "200px" },
      ],
      search: {
        flightName: null
      }
    };
  },
  methods: {
    async editIssafe(data) {
      console.log(data.issafe, ' data.issafe');
      let res = await API.AIRWAY.editIssafe({
        id: data.id,
        issafe: data.issafe === 1 ? null : 1
      })
      if (res.code === 200) {
        this.$message.success('状态修改成功')
        this.currentPage = 1;
        await this.async_getList();
      } else {
        this.$message.success(res.message)
      }
    },
    cleartooltip() {
      let dom = document.querySelectorAll('.cesium-tooltip')
      dom = [].slice.apply(dom)
      for (let index = 0; index < dom.length; index++) {
        let element = dom[index];
        if (element !== null) {
          element.parentNode.removeChild(element)
        }
      }
    },
    handle_search() {
      console.log(this.search);
      this.currentPage = 1;
      this.async_getList();
    },
    close() {
      this.readOnly = false
      this.editFlyLineData = null
      this.ShowDrawing = false
      this.RouteDrawingShow = true
      this.async_getList()
    },
    log(row) {//预览航线
      console.log('预览航线', row);
      this.editFlyLineData = row
      this.readOnly = true
      this.RouteDrawingShow = false
      this.ShowDrawing = true
    },
    del(data) {
      console.log('删除航线', data);
      this.$confirm('删除航线后无法恢复，是否继续删除？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await API.AIRWAY.deleteFlightLine({ id: data.id })
        if (res.status === 1) {
          this.$message.success('删除成功')
          if (this.dataSource.length === 1) {
            if (this.pagination.page > 1) {
              this.pagination.page--
            }
          }
          this.async_getList();
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => {

      });
    },
    edit(row) {//编辑航线
      console.log('编辑航线', row);
      this.editFlyLineData = row
      this.RouteDrawingShow = false
      this.ShowDrawing = true
    },
    DrawingShow() {//绘制航线弹框
      this.RouteDrawingShow = false
      this.ShowDrawing = true
      this.cleartooltip()
    },
    closeRouteDrawing() { //关闭弹框
      this.$emit('openList')
    },
    async onChangePage(page) {//分页切换页码
      this.currentPage = page;
      await this.async_getList();
    },
    async async_getList() {
      let res = await API.AIRWAY.flightLineList({
        ...this.search,
        pageNo: this.currentPage,
        pageSize: this.size,
      })

      this.currentPage = res.data.pageNo
      this.size = res.data.pageSize
      this.total = res.data.totalCount
      this.dataSource = res.data.data.map((item, index) => ({
        ...item,
        index: res.data.pageNo == 1 ? index + 1 : index + (res.data.pageNo * this.size)
      }))
      console.log(this.dataSource, ' this.dataSource');
    }
  },
  async created() {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:tasks:draw:viewFlight") > -1) {
      this.routeButton1 = true
    }
    if (permissionList.indexOf("hn:tasks:draw:airlineEdit") > -1) {
      this.routeButton2 = true
    }
    this.async_getList()
  }
}
</script>

<style lang="scss" scoped>
.RouteDrawing_Top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .Drawing_Button {
    width: 126px;
    height: 40px;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 6px;
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }

  .Drawing_Search {
    position: relative;

    ::v-deep .input_Value {
      margin-left: 14px;
      position: relative;

      .el-input__inner {
        width: 210px;
        height: 30px;
        background: rgba(0, 0, 0, 0.5);
        border: none;
        color: #fff;
      }
    }

    .Search_Btn {
      position: absolute;
      right: 5px;
      top: 5px;
      color: #ffffff;
      cursor: pointer;
    }
  }

}

.RouteDrawing_Footer {
  //列表底部
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .cpt-table-pagination {
    //右下分页组件样式
    height: 50px;
    display: flex;
    align-items: center;

    ::v-deep {
      button {
        background: transparent;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        // border: 1px solid #488cff;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        border-radius: 4px;
        line-height: 40px;
        margin-right: 8px;
        box-sizing: border-box;
        color: #b6d4ff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:disabled {
          background: transparent;
          color: #b6d4ff;
          opacity: 0.3;
        }
      }

      .el-pagination__total {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        text-align: center;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin: 0px;
      }

      .el-pager {
        height: 30px;

        .number {
          background: transparent;
          font-size: 12px;
          color: #b6d4ff;
          text-align: center;
          font-weight: lighter;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          border-radius: 4px;
          line-height: 25px;
          margin-right: 8px;
          box-sizing: border-box;

          &.active {
            opacity: 0.8;
            // background-image: linear-gradient(
            //   180deg,
            //   rgba(44, 123, 255, 0.5) 0%,
            //   rgba(72, 140, 255, 0) 100%
            // );
            background-color: #2647ee;
            color: #ffffff;
          }
        }

        .more {
          background: transparent;
          line-height: 25px;
          width: 25px;
          height: 25px;

          box-sizing: border-box;
          // border: 1px solid #629fff;
          border-radius: 4px;
          margin-right: 8px;
          font-size: 20px;
          color: #b6d4ff;
        }
      }

      .el-pagination__jump {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        width: 150px;
        height: 40px;
        margin-left: 6px;
        line-height: 40px;
        display: flex;
        align-items: center;

        .el-input {
          box-sizing: border-box;
          height: 100%;
          width: 84px;

          .el-input__inner {
            width: 60px;
            height: 25px;
            border-radius: 2px;
            border: 1px solid #b6d4ff;
            margin-top: 8px;
            // border: 1px solid #629fff;
            border-radius: 4px;
            background: transparent;
            // height: 100%;
            box-sizing: border-box;
            padding: 0;
            font-size: 18px;
            color: #90b2ec;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
          }
        }
      }
    }

    &.mini {
      height: 100%;
      display: flex;
      align-items: center;

      ::v-deep {
        button {
          height: 20px;
          width: 20px;
          line-height: 20px;
          background: transparent;
          border: none;
          color: #4d7592;
          min-width: auto;

          &.btn-next {
            margin-left: 8px;
          }
        }

        .el-pagination__total {
          font-size: 14px;
          color: #4d7592;
        }

        .el-pagination__sizes {
          display: none;
        }

        .el-pager {
          height: 30px;

          .number {
            height: 100%;
            width: auto;
            line-height: 20px;
            background: transparent;
            border: none;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            min-width: 20px;
            margin-right: 0;

            &.active {
              opacity: 1;
              color: #0099ff;
            }
          }

          .more {
            height: 100%;
            width: 20px;
            line-height: 20px;
            font-size: 12px;
            border: none;
            min-width: auto;
            margin-right: 0px;

            &::before {
              line-height: 20px;
            }
          }
        }

        .el-pagination__jump {
          height: 100%;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          .el-input {
            .el-input__inner {
              border: 1px solid #4d7592;
              border-radius: 2px;
              width: auto;
              min-width: 20px;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

::v-deep {
  .el-input__inner {
    width: 300px;
  }

  .el-input__suffix {
    position: absolute;
    right: 30px;
  }
}
</style>