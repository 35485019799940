<template>
  <div class="demandapproval">
    <!-- 需求单制定弹框 -->
    <Dialog v-if="initiatingDemandPopupIsShow" v-show="type === 1" @on-close="closeIDsPopup" title="任务单调度" :width="1497"
      :top="120" :left="120">
      <div class="content pr">
        <!-- 右侧列表 -->
        <div class="list">
          <!-- 顶部两个个东西 -->
          <div class="approval-info state">
            <!-- 待派单 -->
            <div class="jc hwb-flex  dsp" style="color:#50F9F4;" :class="selectInfo.disposeStatus==1?'selected':''"
              @click="updataTableStaus(1)">
              <div class="jc-tp iconfont icon-daipaidan"></div>
              <div class="jc-text">
                <div class="num">{{distributionQuantity.wait}}</div>
                <div class="text">待派单</div>
              </div>
            </div>
            <!-- 已派单 -->
            <div class="jc hwb-flex  sptg" style="color:#50F9F4;" :class="selectInfo.disposeStatus==2?'selected':''"
              @click="updataTableStaus(2)">
              <div class="jc-tp iconfont icon-daipaidan"></div>
              <div class="jc-text">
                <div class="num">{{distributionQuantity.Finish}}</div>
                <div class="text">已派单</div>
              </div>
            </div>
          </div>
          <div class="taskSwitch">
            <div class="btn" :class="`${singular === 2 ? 'one' : 'two'}`" @click="upSingular(2)">需求单单任务</div>
            <div class="btn" :class="`${singular === 1 ? 'one' : 'two'}`" @click="upSingular(1)">需求单多任务</div>
          </div>
          <!-- 赛选栏 -->
          <div class="select">
            <el-cascader popper-class="input-orgname" placeholder="请选择所属机构" v-model="selectInfo.organizationId"
              clearable :options="selectList" :show-all-levels="false" :props="{
                children: 'child',
                label: 'name',
                value: 'id',
                checkStrictly: true,
                emitPath: false,
                empty: false
              }" class="button">
            </el-cascader>
            <el-select v-model="selectInfo.taskCateId" size="mini" clearable placeholder="请选择任务类型" class="button">
              <el-option v-for="item in drd.cates" :key="item.id" :label="item.title" :value="item.id" />
            </el-select>
            <el-select v-model="selectInfo.taskTypeId" size="mini" clearable placeholder="请选择场景类型" class="button">
              <el-option v-for="item in drd.scenes" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
            <el-input
            v-model="selectInfo.name"
            class="w170 ml10 dib "
            size="mini"
            placeholder="请输入任务单名称"
            clearable
          ></el-input>
            <div class="search-button" @click="async_get_list('search')">搜索</div>
          </div>
          <!-- 表格 -->
          <div class="tablesnot">
            <el-table :data="dataSource" class="hwb-table-new"
              :header-row-style="{'background-color': 'rgba(138,152,177,0.28)',color:'#fff','font-size':'14px'}"
              :header-row-class-name="'header-row-class-name'" :max-height="550" :row-class-name="tableRowClassName"
             :tree-props="{children: 'child', hasChildren: 'hasChildren'}" :cell-style="tableCellStyle"
              :highlight-current-row="false" :header-cell-style="{textAlign:'center'}"
              row-key='id'
              >
              <el-table-column prop="index" label="序号" header-align="right" :width="60"></el-table-column>
              <el-table-column prop="taskTitle" :show-overflow-tooltip="true" label="需求单名称"></el-table-column>
              <el-table-column prop="taskCateName" label="任务类型">
                <template slot-scope="scope">
                  <div class="action" :style="`color:${scope.row.taskCateName == '常态任务'? '#6AFF64':'#FF3636'}`">
                    {{ scope.row.taskCateName}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="taskTypeName" label="任务场景"></el-table-column>
              <el-table-column prop="applyStatusName" label="审核状态">
                <template slot-scope="scope">
                  <div class="action"
                    :style="scope.row.isChild?'':getStatusColor.getApplyColor(scope.row.papplyStatus)">
                    {{ scope.row.isChild?'/':getStatusColor.getApplyWord(scope.row.papplyStatus)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="disposeStatus" label="调度状态">
                <template slot-scope="scope">
                  <div class="action" :style="`color:${scope.row.disposeStatus == 1? '#FFBD36':'#00D2FF'}`">
                    <!-- {{ scope.row.disposeStatus == 1? '待派单':'已派单'}} -->
                    {{ disposeStatusFilter(scope.row.disposeStatus) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="taskStatus" label="执行状态">
                <template slot-scope="scope">
                  <div class="action" :style="getStatusColor.getImplementColor(scope.row.taskStatus)">
                    {{ getStatusColor.getImplementWord(scope.row.taskStatus) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="organizationName" :show-overflow-tooltip="true" label="单位名称"></el-table-column>
              <el-table-column prop="startedAt" :show-overflow-tooltip="true" label="开始时间"></el-table-column>
              <el-table-column prop="endAt" :show-overflow-tooltip="true" label="结束时间"></el-table-column>
              <el-table-column prop="action" label="操作" width="180">
                <template slot-scope="scope">
                  <div class="action">
                    <span title="派单" @click="distribute(scope.row)" v-if="
                      scope.row.disposeStatus == 1 && (scope.row.isSubtasks == 2 || scope.row.isSubtasks == null)
                    " class="iconfont icon-daipaidan" style="color:#35D9E7;padding-right:6px"></span>
                    <span title="新建子任务" v-if="
                      ((scope.row.taskStatus !== 1 && scope.row.taskStatus !== -1 && scope.row.taskStatus !== -2 && scope.row.isSubtasks !== 2) || (scope.row.taskCateName == '临时紧急任务' && scope.row.disposeStatus == 1)) && scope.row.isChild !== 1
                    " @click="subtask(scope.row)" class="iconfont icon-tianjiazirenwu"
                      style="color:#35D9E7;padding-right:6px"></span>
                    <span title="修改"
                      v-if="scope.row.isSubtasks == 2 && scope.row.disposeStatus === 2 && scope.row.taskStatus == 0"
                      @click="distribute(scope.row)" class="iconfont icon-bianji1"
                      style="color:#35D9E7;padding-right:6px"></span>
                    <span title="详情" v-if="scope.row.isSubtasks !== 1&&scope.row.disposeStatus !==1" @click="distributionDetails(scope.row)"
                      class="iconfont icon-chakanxiangqing" style="color:#35D9E7;padding-right:6px"></span>
                    <!-- 已派单的多任务才出现完成需求 -->
                    <span title="完成需求" v-if="
                      scope.row.isSubtasks == 1
                      && scope.row.disposeStatus == 2
                    " @click="sumTaskFinish(scope.row.id)" class="iconfont icon-wanchengxuqiu"
                      style="color:#35D9E7;padding-right:6px"></span>
                    <span title="审批报告" v-if="
                      scope.row.taskStatus == 1 && scope.row.reportStatus == 0 && scope.row.isSubtasks !== 1
                    " @click="approvalReport(scope.row.id,true)" class="iconfont icon-baogaoshenhe"
                      style="color:#35D9E7;padding-right:6px"></span>
                    <span title="查看报告" v-if="
                      scope.row.taskStatus == 1 && scope.row.reportStatus !== 0 && scope.row.isChild == 1
                    " @click="approvalReport(scope.row.id,false)" class="iconfont icon-renwubaogao2"
                      style="color:#35D9E7;padding-right:6px"></span>
                      <img v-if="scope.row.reportStatus == 2 && scope.row.isSubtasks == 2"  @click="editDetails(scope.row)" src="./img/5.png" title="重新下单" class="dib w15 h15 cp" alt="">
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pageCom class="pa bottom0" :pagination="pagination" @on-pageChange="pageChange" />
          </div>
        </div>
      </div>
    </Dialog>

    <!-- 新建子任务  -->
    <DemandSheetPreparation v-if="type === 2" @subtaskDetails="subtaskDetails" @subtaskDispatch="subtaskDispatch"
      :taskData="taskData" @closeDemand="closeDemand" />
    <!-- 派单 -->
    <div>
      <Details ref="Details" v-show="leaflets" :itemValue="itemValue" @close-toSubPage="closeToSubPage" @clear="clearList"
        @clearAll="clearAll" />
    </div>
    <!-- 详情 -->
    <div>
      <Details v-if="detailsShow" :Approved="true" :itemValue="itemValuer" @close-toSubPage="closeToSubPage"
        @clear="() => {this.detailsShow = false;type=1}" />
    </div>
    <!-- 绘制航线 -->
    <div>
      <RouteDrawing v-if="RouteDrawingShow" @openList="openList" />
    </div>
    <!-- 报告审核 -->
    <jobReport v-if="reportApprovalInfo" :reportApprovalInfo="reportApprovalInfo" @close="closeReport" />

    <!-- 重新下单 -->
    <div v-if="mapSelectionArea" class="area-select-popup">
      <div class="content">
        <div class="button">
          <el-button @click="cancelSaveArea" type="primary">取消</el-button>
          <el-button @click="saveArea" type="primary">保存</el-button>
        </div>
        <Drawky :leftOnclick="(value) => leftOnclick(value)" :rigthOnclick="(value) => rigthOnclick(value)"
          :move="(value) => move(value)" :airspcelist="areaPointList" :drakyStyle="true"
          :addallairArray="(value) => addallairArray(value)" />
      </div>
    </div>

    <el-dialog title="需求单制定" :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
      <div class="content hwb-flex">
        <!-- 左侧发起需求 -->
        <div class="create-need">
          <!-- 中间的竖线装饰 -->
          <!-- 发起需求弹框 -->
          <!-- :hide-required-asterisk="true"  表单清除红色提示-->
          <el-form ref="edit_form" :model="edit_form2" :label-position="'left'" :rules="edit_form2rules"
            class="edit-form" label-width="110px">
            <el-form-item label="需求单名称" prop="taskTitle">
              <el-input v-model="edit_form2.taskTitle" placeholder="请输入需求单名称" maxlength="254" clearable />
            </el-form-item>
            <el-form-item label="任务类型" prop="taskCateId">
              <el-select v-model="edit_form2.taskCateId" placeholder="请选择任务类型" clearable :disabled="updateNeedData">
                <el-option v-for="item in drd.task_type" :key="item.id" :label="item.title" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="任务场景" prop="taskTypeId">
              <el-select v-model="edit_form2.taskTypeId" placeholder="请选择场景类型" clearable>
                <el-option v-for="item in drd.task_cate" :key="item.id" :label="item.title" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="时间要求" required>
              <div class="timecss">
                <el-form-item prop="startedAt" style="margin-bottom: 0px;">
                  <el-date-picker v-model="edit_form2.startedAt" :picker-options="pickerOptionsStart"
                    @focus="dateStartPickerFocus" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="开始日期"
                    clearable>
                  </el-date-picker>
                </el-form-item>
                <span style="width: 6px; color: #D8D8D8; text-align: center">-</span>
                <el-form-item prop="endAt" style="margin-bottom: 0px;">
                  <el-date-picker v-model="edit_form2.endAt" :picker-options="pickerOptionsEnd"
                    @focus="dateEndPickerFocus" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="结束日期"
                    clearable>
                  </el-date-picker>
                </el-form-item>
              </div>
            </el-form-item>

            <el-form-item label="任务目标位置" prop="destination">
              <el-cascader class="input-orgname" placeholder="请选择街道" v-model="edit_form2.destination"
                :options="drd.cityType" :props="{
                  children: 'children',
                  label: 'title',
                  value: 'id',
                  emitPath: false,
                  empty: false,
                }" clearable>
              </el-cascader>
            </el-form-item>
            <el-form-item label="详细位置说明" prop="origin">
              <el-input v-model="edit_form2.origin" placeholder="请输入详细位置" clearable maxlength="254" />
            </el-form-item>
            <el-form-item label="区域选择">
              <span @click="mapSelectionArea = true" :class="[areaPointList ? 'selected-area ' : 'noselected-area']">
                {{ areaPointList ? "(点击修改区域)" : "(重新选择区域)" }}
              </span>
            </el-form-item>
            <el-form-item label="需求描述" style="width: 100%" prop="remark">
              <el-input v-model="edit_form2.remark" placeholder="请输入需求描述" type="textarea" :rows="4" clearable
                maxlength="254"></el-input>
            </el-form-item>
          </el-form>
          <!-- 确定按钮 -->
          <div class="anniu">
            <div @click="cancelUpdate" v-if="updateNeedData" class="button button2">取消修改</div>
            <div @click="handle_save" class="button">{{ updateNeedData ? '确认修改' : '确认' }}</div>
          </div>
        </div>

      </div>
    </el-dialog>

  </div>
</template>

<script>
let locationEntity;
import Details from './DetailsVisible'
import RouteDrawing from './RouteDrawing'
import DemandSheetPreparation from "./demand-sheet-preparation"
import Dialog from '@/components/common/new-dialog/index-tow.vue'; // 弹框组件
import DialogOne from '@/components/common/new-dialog/index.vue'; // 弹框组件
import Table from "@/components/common/table-new-hwb"; // 列表组件l
import pageCom from "@/components/common/page-com-hwb"; // 分页组件
import jobReport from "./jobReport/index.vue"; // 报告审核
import API from "@/api";
import getStatusColor from "@/api/statusColorGet.js"; // 获取颜色函数
import { nanoid } from "nanoid";
import { mapGetters } from "vuex"
import Drawky from "@/components/drawky"; // 绘制区域的
import dayjs from "dayjs";
import { transformCartesian2WGS84 } from "@/utils/cesium";
import * as turf from '@turf/turf'

export default {
  components: {
    Dialog,
    DialogOne,
    Table,
    DemandSheetPreparation,
    Details,
    RouteDrawing,
    pageCom,
    jobReport,
    Drawky
  },
  data() {
    return {
            pickerOptionsStart: {}, //预计飞行开始时间限制
      pickerOptionsEnd: {}, //预计飞行结束时间限制
            edit_form2rules: {  // 验证规则
        taskTitle: [
          { required: true, message: "请输入需求单名称", trigger: "blur" },
        ],
        taskCateId: [
          { required: true, message: "请选择任务类型", trigger: "blur" },
        ],
        startedAt: [
          { required: true, message: "请选择飞行开始时间", trigger: "blur" },
          { required: true, message: "请选择飞行开始时间", trigger: "change" },
        ],
        endAt: [
          { required: true, message: "请选择飞行结束时间", trigger: "blur" },
          { required: true, message: "请选择飞行开始时间", trigger: "change" },
        ],
        origin: [
          { required: true, message: "请输入详细位置", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入需求描述", trigger: "blur" },
        ],
        taskTypeId: [
          { required: true, message: "请选择场景类型", trigger: "blur" },
        ],
        destination: [
          { required: true, message: "请选择街道", trigger: "blur" },
        ],
      },
      mapSelectionArea:false,
      updateNeedData:null,
      dialogVisible:false,
      edit_form2: {  // 发起需求表单字段
        id: null,
        taskTitle: null,
        taskCateId: 2,
        taskTypeId: null,
        startedAt: null,
        endAt: null,
        origin: null,
        destination: null,
        remark: null,
        locationArea: null, // 存储区域字符串
        organizationId:null,//发起机构id
      },
      getStatusColor,
      reportApprovalInfo: null, // 要审批报告的ID  弹框通过它展示 关闭弹框赋值给null
      distributionQuantity: { // 待派单 已派单 数量
        wait: 0,
        Finish: 0,
      },
      selectInfo: { // 赛选的信息
        disposeStatus: 1, //派单状态：1待派单 2已派单
        isSubtasks: 2, // 1多任务 2单任务
        organizationId: null, // 机构id
        taskCateId: null, // 任务类别
        taskTypeId: null, // 任务场景
        applyStatus:null, //上级审批状态： 0待审批 1审批通过 2审批未通过 3默认通过
        pApplyStatus:'1,3', //1审批通过 3默认通过 列表默认数据 ,0待审批和2审批未通过的不出现
      },
      generalTaskReportId: 23,
      aisId: null,// 要修改的预警信息的id
      aisPictrueOrVideoList: null, // 要修改的预警已选择的图片或则视频
      selectType: null, // 打开飞行成果选择时选择的是图片还是视频
      waringColumns: [
        { prop: "warningTypeName", slot: true, label: "预警类别" },
        { prop: "warningPhotoUrl", slot: true, label: "图片" },
        { prop: "video", slot: true, label: "视频" },
        { prop: "remark", slot: true, label: "目标事物信息" },
        { prop: "action", slot: true, label: "操作" },
      ],
      alertTypeDropDown: null, //预警类型
      text: null, // 测试文本框
      RouteDrawingShow: false,//绘制航线显示隐藏
      itemValue: null,//点击派单每一行的数据
      itemValuer: null,//点击派单每一行的数据
      reportStaus: false,//任务报告页面显示状态
      tabledata: null,//表格内点击存储的数据
      singular: 2,//已派单 2单任务/1 多任务状态切换
      tableStaus: 1,// 1待派单/2已派单表格状态切换
      leaflets: false, //派单弹框的状态
      detailsShow: false, //派单详情弹框的状态
      type: 1,  //为1时主页面展示
      organId: null, // 机构赛选的id
      selectList: null, // 赛选的机构
      initiatingDemandPopupIsShow: true, // 控制需求单子任务弹框是否展示
      areaPointList: null,
      edit_form: {  // 发起需求表单字段

      },

      drd: {
        cates: [
          { id: 2, title: "常态任务" },
          { id: 3, title: "临时紧急任务" },
        ],
        scenes: null,
        uav_type: [],
        mount_type: [],
        task_type: [],
        task_cate: [],
        flightLine: [],
        proStatus: [
          { id: 1, label: "待处理", value: 0 },
          { id: 2, label: "处理完成", value: 1 },
          { id: 3, label: "无需处理", value: 2 },
        ],
        cityType: [
          {
            id: 13,
            title: "济源市",
            children: [
              { id: 1, title: "沁园街道" },
              { id: 2, title: "济水街道" },
              { id: 3, title: "北海街道" },
              { id: 4, title: "天坛街道" },
              { id: 5, title: "玉泉街道" },
              { id: 6, title: "克井镇" },
              { id: 7, title: "五龙口镇" },
              { id: 8, title: "轵城镇" },
              { id: 9, title: "承留镇" },
              { id: 10, title: "邵原镇" },
              { id: 11, title: "坡头镇" },
              { id: 12, title: "梨林镇" },
              { id: 17, title: "大峪镇" },
              { id: 14, title: "思礼镇" },
              { id: 15, title: "王屋镇" },
              { id: 16, title: "下冶镇" },


            ],
          },
        ],
      },
      dataSource: [],
      map: {
        status: {
          "-2": "已拒绝",
          "-1": "已取消",
          0: "待执行",
          1: "已完成",
          3: "执行中",
        },
        apply: {
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      dialogIsShow: false, //审批框状态
      taskData: null, //任务信息
      Approved: false, //审批通过弹框状态
      ApprovalFailed: false, //审批不通过弹框状态
      ApprovalFailedTextarea: null, // 审批不通过理由
      radio: '1', // 审批通过之后默认使用单任务
    };
  },
  computed: {
    ...mapGetters(["dbox_data"]),
    ...mapGetters(["user_info_hn"])
  },
  methods: {
        cityTypeIdFiler(val) {
      //筛选出选择的街道
      if (val.destination == "济源市") {
        return 13;
      } else {
        //筛选出选择的街道
        let data = this.drd.cityType[0].children.filter(
          (v) =>  val.destination.includes(v.title)
        )[0];
        if (data) {
          return data.id;
        } else {
          return null;
        }
      }
    },
        cityTypeIdFile(val) {
      if (val == 13) {
        return "济源市";
      } else {
        //筛选出选择的街道
        return this.drd.cityType[0].children.filter(
          (v) => this.edit_form2.destination == v.id
        )[0].title;
      }
    },
        dataFilter(){
      if (this.edit_form2.startedAt || this.edit_form2.endAt) {
        if (this.edit_form2.startedAt == this.edit_form2.endAt) {
        console.log(20221113);
        return true
      }
      }
      
    },
    oneArrToTwoArr(data) {
      let newData = [];
      for (let i = 0; i < Math.ceil(data.length / 2); i++) {
        newData[i] = [];
        newData[i].push(data[i * 2]);
        newData[i].push(data[i * 2 + 1]);
      }
      return newData;
    },
        async handle_save() {  

          // 发起需求确定按钮
      this.$refs["edit_form"].validate(async (valid) => {
        if (valid) {
          let dataBoolean =  this.dataFilter()
          if(dataBoolean) return this.$message.warning('时间不能相同')
          if (this.areaPointList && this.areaPointList.regionList.length > 2) {
            // 画了区域的话转一下格式
            let regionListCopy = this.areaPointList.regionList.concat();
            let first = regionListCopy[0],
              last = regionListCopy[regionListCopy.length - 1];
            regionListCopy.shift();
            regionListCopy.pop();
            let fv = [];
            let asx = this.oneArrToTwoArr(regionListCopy);
            asx.map((item) => fv.push([item[0], item[1]].join(" ")));
            let gg = fv.toString();
            let yg = first + "," + gg + "," + last;
            this.edit_form2.locationArea = yg;
          }

          let { destination, startedAt, endAt, ...rest } = this.edit_form2;

          const params = {
            ...rest,
            startedAt: dayjs(startedAt).format("YYYY-MM-DD HH:mm:ss"),
            endAt: dayjs(endAt).format("YYYY-MM-DD HH:mm:ss"),
            type: this.Inspection ? 1 : null,
            userId: this.user_info_hn.uid
          };
          //下拉框id转换街道
          destination = this.cityTypeIdFile(destination);
          params.destination = destination;
          let res = await API.TASK.reCreate(params)
          if (res.status === 1) {
            this.$message.success('创建需求成功')
            this.edit_form2 = {
              taskTitle: null,
              taskCateId: 2,
              taskTypeId: null,
              startedAt: null,
              endAt: null,
              origin: null,
              destination: null,
              remark: null,
              locationArea: null, // 存储区域字符串
              userId: null,
              organizationId:null
            }
            this.dialogVisible = false
            this.$refs.edit_form.resetFields()
            this.areaPointList = null
            this.updateNeedData = null
            this.async_get_list()
          } else {
            this.$message.error(res.message)
          }
        }
      });
    },
        dateStartPickerFocus() {
      // 预计飞行开始时间获取焦点做选择限制
      this.pickerOptionsStart = {
        disabledDate: (time) => {
          // 日期选择限制
          return time.getTime() < Date.now() - 8.64e7;
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    dateEndPickerFocus() {
      // 预计飞行结束时间获取焦点做选择限制
      this.pickerOptionsEnd = {
        disabledDate: (time) => {
          // 日期选择限制
          if (
            this.edit_form2.startedAt &&
            new Date(this.edit_form2.startedAt).getTime() > Date.now()
          ) {
            return (
              time.getTime() < new Date(this.edit_form2.startedAt).getTime() - 8.64e6
            );
          } else {
            return time.getTime() < Date.now() - 8.64e6;
          }
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
        addallairArray(value) { // 将子组件获取的禁飞区传递出来
      this.allairArray = value
    },
        move(data) {
      this.regionList = data.positions;
    },
        rigthOnclick(data) {
      if (data.positions.type === 1) {
      } else {
        this.regionList = data.positions;
      }
    },
        leftOnclick(data) {
          console.log(data,'data');
      // if (data.index === -1) {
      this.regionList = data.positions;
      // }
    },
       saveArea() {
      // 绘制区域弹框点击保存
      if (this.regionList && this.regionList.length > 2) {
        this.areaPointList = { regionList: [] };
        let wsg84 = [];
        this.regionList.map((item) =>
          wsg84.push(transformCartesian2WGS84(item))
        );
        wsg84.map((item) =>
          this.areaPointList.regionList.push(item.lng, item.lat)
        );

        // 将绘制区域经纬度转成符合比较的多维数组
        let newData = [];
        for (let i = 0; i < Math.ceil(this.areaPointList.regionList.length / 2); i++) {
          newData[i] = [];
          newData[i].push(this.areaPointList.regionList[i * 2]);
          newData[i].push(this.areaPointList.regionList[i * 2 + 1]);
        }
        newData.push(newData[0])

        // 将所有禁飞区转成符合比较的多维数组
        let allDate = [];
        this.allairArray.map(item => {
          let mapList = [];
          for (let i = 0; i < Math.ceil(item.length / 2); i++) {
            mapList[i] = [];
            mapList[i].push(item[i * 2]);
            mapList[i].push(item[i * 2 + 1]);
            mapList.push(mapList[0])
          }
          allDate.push(mapList)
        })

        // // 比较绘制区域是否与所有禁飞区域重叠
        // let poly1 = turf.polygon([newData])
        // let poly2 = turf.polygon(allDate)

        let box = []
        allDate.forEach(v => { //验证绘画的点是否在多边形内
          newData.forEach(value => {
            let pt = turf.point(value);
            let poly = turf.polygon([v])
            box.push(turf.booleanPointInPolygon(pt, poly))
          })
        })


        if (box.some(v => v == true)) {
          this.areaPointList=null
          this.$message.warning("禁飞区内禁止绘制任务区域");
        } else {
          this.mapSelectionArea = false;
        }

      } else {
        this.$message.warning("请先绘制完区域");
      }
    },
        cancelSaveArea() { // 取消保存
      this.mapSelectionArea = false;
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.edit_form.resetFields()
    },
     async drd_task_type() {
      try {
        let res = await API.TASK.GetDrdType();
        res = res.data;
        this.drd.task_type = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
        async drd_task_cate() {
      try {
        let res = await API.TASK.GetDrdCate();
        res = res.data;
        this.drd.task_cate = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    editDetails(row){
      console.log(row);
      this.dialogVisible = true
      this.getOneSubtaskNewFn(row.id)
    },
    async getOneSubtaskNewFn(id) {
      const { data } = await API.TASK.getOneSubtaskNew(id)
      console.log(data, 'datadatadata');
      for (const key in this.edit_form2) {
        this.edit_form2[key] = data[key]
      }
      this.edit_form2.id = id
      this.edit_form2.destination = this.cityTypeIdFiler(data)
    },
    disposeStatusFilter(status) {//筛选调度状态返回数据到页面
      if (status == 1) {
        return '待派单'
      } else if (status == 2) {
        return '已派单'
      }
      return ''
    },
    async sumTaskFinish(id) {
      const res = await API.TASK.sumTaskFinishNew(id)
      if (res.status === 1) {
        this.$message.success('处理成功')
        this.async_get_list()
      } else {
        this.$message.warning(res.message)
      }
    },
    approvalReport(id, state) { // 审批报告  点击审批报告图标打开弹框
      this.reportApprovalInfo = {
        id,
        isApproval: state  // true 审批报告   false 查看报告
      }
      this.type = 3
    },
    closeReport() {
      this.reportApprovalInfo = null
      this.type = 1
      this.async_get_list()
    },
    closeDemand() {  // 关闭创建子任务弹框
      this.taskData = null
      this.type = 1
      this.async_get_list()
    },
    subtaskDispatch(data) { // 创建子任务页面点进派单
      this.type = 3
      this.itemValue = data
      this.leaflets = true
      this.$refs.Details.approvalDistribution(data)
    },
    distribute(data) { // 派单
      // this.taskData = data
      this.leaflets = true
      this.itemValue = data
      this.initiatingDemandPopupIsShow = false
      this.$refs.Details.approvalDistribution(data)
    },
    subtaskDetails(item) { // 创建子任务页面点进派单详情
      this.itemValuer = item
      this.type = 3
      this.detailsShow = true  //派单详情框展示
    },
    distributionDetails(item) { // 派单详情
      this.itemValuer = item
      this.type = 3
      this.detailsShow = true  //派单详情框展示
    },
    tableCellStyle({ column, columnIndex }) {
      if (columnIndex == 0) {
        return 'textAlign:right'
      } else {
        return 'textAlign:center'
      }
    },
    tableRowClassName({ row, rowIndex }) {  // 控制任务状态
      if (row.isChild == 1) {
        return 'dan-row-child';
      } else if (rowIndex % 2 == 0) {
        return 'dan-row';
      } else if (rowIndex % 2 == 1) {
        return 'shuang-row';
      }
      return '';
    },
    getApplyColor(status) { //审批状态颜色
      switch (status) {
        case 0:
          return "color: #FFBD36";
          break;
        case 1:
          return "color: #4799F3";
          break;
        case 3:
          return "color: #4799F3";
          break;
        case 2:
          return "color: #FF3636";
          break;
        default:
          break;
      }
    },
    clearAll() { //子弹框点击航线绘制隐藏所有弹框并打开航线绘制弹框
      this.leaflets = false
      this.RouteDrawingShow = true
    },
    updataTableStaus(state) { // 待派单  已派单 请求列表
      this.pagination.page = 1
      this.selectInfo.disposeStatus != state ? this.selectInfo.disposeStatus = state : this.selectInfo.disposeStatus = null
      this.async_get_list()
    },
    upSingular(state) { // 选择单多任务
      if(state==1&&this.selectInfo.disposeStatus==2 && this.singular==1){
       this.dataSource =[]
      }
      this.pagination.page = 1
      this.selectInfo.isSubtasks != state ? this.selectInfo.isSubtasks = state : this.selectInfo.isSubtasks = null
      this.singular != state ? this.singular = state : this.singular = null
      this.async_get_list()
    },
    pageChange(page) { // 翻页操作
      this.pagination.page = page;
      this.async_get_list();
    },
    async async_get_list(state) { // 获取列表数据
      if (state == 'search') {
        this.pagination.page = 1
      }
      const { map } = this;
      const { page, pageSize } = this.pagination;
      let obj = {
        ...this.selectInfo,
        pageNum: page,
        pageSize
      }
      let res = await API.TASK.intuitive(obj);
      res = res.data;
      this.distributionQuantity.wait = res.data.wait
      this.distributionQuantity.Finish = res.data.Finish
      this.dataSource = res.data.task || [];
      this.dataSource = this.dataSource.map((item, index) => {
        item.child.forEach(element => {
          element.isChild = 1
        })
        return {
          ...item,
          index: (page - 1) * pageSize + index + 1,
          taskStatusName: map.status[item.taskStatus],
          applyStatusName: map.apply[item.papplyStatus],
          processingStatusName: map.processing[item.processingStatus],
        }
      });

      this.pagination.page = res.pageNo;
      this.pagination.total = res.totalCount;
    },
    closeIDsPopup() { // 关闭不通过原因弹框
      this.$emit("closeDemand");
    },
    getAllOrganization() {
      let data = this.dbox_data
      this.selectList = this.getTreeData(data)
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    // 获取场景类型
    async getScenes() {
      let res = await API.TASK.GetDrdCate();
      res = res.data;
      this.drd.scenes = res
    },
    openList() { //关闭航线绘制，打开派单弹框
      this.$refs.Details.approvalDistribution()
      this.RouteDrawingShow = false
      this.leaflets = true
    },
    clearList() { //关闭派单弹框，打开列表弹框
      this.leaflets = false
      this.initiatingDemandPopupIsShow = true
      this.async_get_list()
      this.$refs.Details.clearvalue()
    },
    closeToSubPage() { // 关闭派单弹框 回到子任务创建页面
      this.leaflets = false
      this.detailsShow = false
      this.type = 2
      this.$refs.Details.clearvalue()
    },
    // 子任务
    subtask(data) {
      this.taskData = data
      this.type = 2
    },
    coloseNeedPopup() {
      this.dialogIsShow = false
    },
  },
  mounted() {
    this.getScenes()
    this.getAllOrganization()
    this.async_get_list()
    this.drd_task_type()
    this.drd_task_cate()
    // this.upSingular(2)
  },
  watch:{
        "edit_form2.startedAt"(val) {
      // 监视预计飞行开始时间做的某些事
      if (val) {
        const date = new Date(new Date(val).setHours(0, 0, 0, 0)).getTime();
        const today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        if (date > today) {
          // 如果选的日期不是和当天同一天
          this.pickerOptionsStart.selectableRange = "00:00:00 - 23:59:59";
        } else {
          // 如果是同一天
          this.pickerOptionsStart.selectableRange = `${this.getNowTime()} - 23:59:59`;
        }
        if (
          new Date(this.edit_form2.startedAt).getHours() != new Date().getHours() &&
          new Date(this.edit_form2.startedAt).getDay() == new Date().getDay() &&
          new Date(this.edit_form2.startedAt).getDate() == new Date().getDate() &&
          new Date(this.edit_form2.startedAt).getHours() == 0
        ) {
          this.edit_form2.startedAt = new Date();
        }
      }
      if (
        this.edit_form2.endAt &&
        val &&
        new Date(val).getTime() > new Date(this.edit_form2.endAt).getTime()
      ) {
        this.edit_form2.endAt = null; // 如果大于结束时间，清空结束时间
      }
    },
    "edit_form2.endAt"(val) {
      if(val && new Date(val).getTime() < new Date(this.edit_form2.startedAt).getTime()){
        this.edit_form2.endAt = this.edit_form2.startedAt
        return
      }
      if(val && new Date(val).getTime() < new Date().getTime()){
        this.edit_form2.endAt = new Date()
        return
      }
      if (val) {
        const date = new Date(new Date(val).setHours(0, 0, 0, 0)).getTime();
        let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        if (
          this.edit_form2.startedAt &&
          new Date(this.edit_form2.startedAt).getTime() > Date.now()
        ) {
          today = new Date(
            new Date(this.edit_form2.startedAt).setHours(0, 0, 0, 0)
          ).getTime();
        }
        if (date > today) {
          // 如果选的日期不是和开始时间或是当天同一天
          this.pickerOptionsEnd.selectableRange = "00:00:00 - 23:59:59";
        } else {
          // 如果是同一天
          this.pickerOptionsEnd.selectableRange = `${this.getNowTime(
            this.edit_form2.startedAt?this.edit_form2.startedAt: ''
          )} - 23:59:59`;
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.content {

  /* 右侧 */
  .list {
    width: 100%;
    height: 830px;

    .state {
      display: flex;

      .jc {
        margin-right: 24px;
        cursor: pointer;
      }
    }

    .approval-info {
      width: 100%;
      height: 109px;

      .dsp {
        background-image: url(./img/4.png);
        &.selected{
          background-image: url(~@/assets/img/click-2.png);
        }

        .jc-text {
          color: #FFD360;
        }

        .jc-tp {
          &.iconfont.icon-daipaidan {
            background: linear-gradient(180deg, #FFF0C8 0%, #FFCE4F 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
          }
        }
      }
      .sptg{
        background-image: url(./img/2.png);
        &.selected{
          background-image: url(~@/assets/img/click-1.png);
        }
        .jc-text{
          color: #00C4FF;
        }
        .jc-tp{
          &.iconfont.icon-daipaidan{
            background: linear-gradient(180deg, #CBF3FF 0%, #9BE8FF 59%, #00C4FF 100%);
              -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .sptg {
        background-image: url(./img/2.png);

        &.selected {
          background-image: url(~@/assets/img/click-1.png);
        }

        .jc-text {
          color: #00C4FF;
        }

        .jc-tp {
          &.iconfont.icon-daipaidan {
            background: linear-gradient(180deg, #CBF3FF 0%, #9BE8FF 59%, #00C4FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .mrtg {
        background-image: url(./img/3.png);
      }

      .spbtg {
        background-image: url(./img/4.png);
      }

      .jc {
        width: 204px;
        height: 109px;

        .jc-tp {
          width: 46px;
          height: 50px;
          font-size: 46px;
          margin-left: 20px;
        }

        .jc-text {
          text-align: center;
          width: 120px;

          .num {
            font-size: 32px;
            font-family: DINAlternate-Bold, DINAlternate;
          }

          .text {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #DAE4FF;
          }
        }
      }
    }

    .select {
      margin: 20px 0 24px 0;

      ::v-deep {
        .el-input__inner {
          background-color: #000;
          border: 0;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          color: #fff;
        }
      }

      .button {
        margin-right: 10px;
        width: 210px;
      }

      .search-button {
        display: inline-block;
        width: 54px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-left: 10px;
        cursor: pointer;
        border-radius: 4px;
        background-color: #000;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
      }
    }

    .task-table {
      height: 600px;
    }

    .taskSwitch {
      margin-top: 20px;
      display: flex;

      .btn {
        width: 100px;
        height: 32px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 32px;
        text-align: center;
      }

      .one {
        background: #1F4DCB;
        cursor: pointer;
      }

      .two {
        background: rgba(0, 0, 0, 0.7);
        cursor: pointer;
      }
    }
  }
}

.dispatch {
  height: 556px;
  width: 100%;
  overflow: auto;

  .need-top-title {
    height: 34px;

    .left {
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      padding-left: 10px;
      position: relative;

      .dec {
        position: absolute;
        top: 2px;
        left: 0;
        width: 4px;
        height: 16px;
        background: #FFBD36;
        border-radius: 2px;
      }
    }

    .right {
      font-size: 14px;
      color: #AAB7C3;
    }
  }

  .need-bottom {
    margin-top: 28px;
    font-size: 14px;
    color: #AAB7C3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .info-add {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    .one-info {
      width: 33.3%;
      margin-bottom: 12px;
    }

    .one-info-long {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .detail {
    height: 107px;
    margin-top: 12px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    padding: 16px 24px;
    line-height: 24px;
  }

  .button {
    margin-top: 40px;
    padding: 0 140px;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-align: center;

    .no {
      width: 126px;
      height: 40px;
      background: rgba(219, 27, 27, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    }

    .yes {
      width: 126px;
      height: 40px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    }
  }
}

.Approved {
  .Approved_Body {
    width: 100%;

    &.FailedTextarea {
      margin-bottom: 20px;
    }

    ::v-deep {
      .el-textarea {
        .el-textarea__inner {
          background-color: rgba(0, 0, 0, 0.6);
          ;
          border: none;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }

    .Approved_Body_Item {
      width: 100%;
      text-align: center;
      margin-bottom: 21px;

      .iconApproved {
        color: #6AFF64;
      }

      .Item_Label {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 12px;
      }

      .Item_Label_Select {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  .Approved_Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-align: center;

    .no {
      cursor: pointer;
      width: 126px;
      height: 40px;
      // background: rgba(219,27,27,0.71);
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-right: 20px;
    }

    .yes {
      cursor: pointer;
      width: 126px;
      height: 40px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-left: 20px;
    }
  }
}

::v-deep.compLOG {
  .el-dialog .el-dialog__body {
    padding: 60px 130px 32px;
  }

  .complete {
    width: 100%;

    .text {
      height: 26px;
      font-size: 20px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      line-height: 26px;
      text-align: center;
      margin-top: 50px;
    }

    .btns {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;

      .btn {
        width: 126px;
        height: 40px;
        background: rgba(38, 71, 238, 0.71);
        box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
        border-radius: 6px;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .btntext {
          height: 24px;
          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: #FFFFFF;
        }
      }
    }
  }
}

.report {
  width: 100%;
  height: 100%;

  .reportBody {
    width: 100%;
    height: 895px;

    .name {
      display: flex;
      flex-wrap: wrap;

      .text {
        width: 25%;
        height: 20px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #AAB7C3;
        line-height: 20px;
        margin-bottom: 24px;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;


        .color {
          color: #FFFFFF;
        }
      }
    }

    .flight-record {
      margin-top: 8px;

      .title {
        height: 24px;
        align-items: center;
        display: flex;

        .icon {
          width: 4px;
          height: 14px;
          background: #FFC300;
          border-radius: 2px;
        }

        .text {
          margin-left: 5px;
          height: 24px;
          font-size: 16px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 24px;
        }
      }

      .body {
        margin-top: 14px;
        display: flex;
        flex-wrap: wrap;

        .text {
          width: 25%;
          height: 20px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #AAB7C3;
          line-height: 20px;
          margin-bottom: 24px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          .color {
            color: #FFFFFF;
          }
        }
      }
    }

    .waring {
      margin-top: 11px;

      .box {
        display: flex;
        justify-content: space-between;
        height: 30px;

        .title {
          height: 24px;
          align-items: center;
          display: flex;

          .icon {
            width: 4px;
            height: 14px;
            background: #FFC300;
            border-radius: 2px;
          }

          .text {
            margin-left: 5px;
            height: 24px;
            font-size: 16px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 30px;
          }
        }

        .add {
          width: 86px;
          height: 30px;
          background: rgba(38, 71, 238, 0.71);
          box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
          border-radius: 2px;
          border: 1px solid;
          border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;

          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 30px;
          text-align: center;
        }
      }

      .body {
        margin-top: 17px;
        width: 100%;
        height: 308px;

      }
    }

    .summary {
      margin-top: 32px;

      .title {
        height: 24px;
        align-items: center;
        display: flex;

        .icon {
          width: 4px;
          height: 14px;
          background: #FFC300;
          border-radius: 2px;
        }

        .text {
          margin-left: 5px;
          height: 24px;
          font-size: 16px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 24px;
        }
      }

      .input {
        margin-top: 14px;

        ::v-deep.el-textarea {
          .el-textarea__inner {
            background: rgba(0, 0, 0, 0.6);
            border: none;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            max-height: 107px;
          }
        }
      }
    }

    .footer {
      margin-top: 40px;

      &-btns {
        display: flex;
        justify-content: space-between;
        padding: 0 389px;

        .btn {
          width: 126px;
          height: 40px;
          background: rgba(38, 71, 238, 0.71);
          box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
          border-radius: 6px;
          border: 1px solid;
          border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;

          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: #FFFFFF;
          line-height: 40px;
          text-align: center;
        }
      }
    }
  }
}

.add-picture {
  width: 32px;
  display: inline-block;
  height: 32px;
  background: url(./img/add.png) no-repeat;
  cursor: pointer;
}
.create-need {
  width: 548px;
  height: 790px;
  position: relative;

  .anniu {
    margin-top: 40px;
    text-align: center;
    justify-content: center;
    display: flex;

    .button {
      width: 126px;
      height: 40px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 40px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      cursor: pointer;
    }

    .button2 {
      margin-right: 30px;
    }
  }

  .dec {
    position: absolute;
    top: -28px;
    height: 848px;
    left: 560px;
    width: 0px;
    border-right: 1px solid #73A6D1;
    box-shadow: 0px 0px 5px #73A6D1;
  }

  .timecss {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ::v-deep {
    .edit-form {
      padding: 22px 20px 0 6px;

      .el-form-item__label {
        height: 36px;
        color: #AAB7C3;
        font-size: 14px;
        text-align: left;
      }

      .el-date-editor--datetime {
        width: 204px;
      }

      .el-input__suffix {
        height: 34px;

        .el-input__suffix-inner {

          .el-input__icon,
          .el-input__clear {
            line-height: 36px;
          }
        }
      }

      .el-cascader {
        width: 100%;
      }

      .el-input {
        box-sizing: border-box;

        .el-input__inner {
          height: 36px;
          box-sizing: border-box;
          background: rgba(40, 53, 122, 0.1);
          border: 1px solid #73A6D1;
          box-shadow: 0px 0px 2px #73A6D1;
          border-radius: 0;
          font-size: 16px;
          color: #9fc4ff;
          letter-spacing: 0;
          font-weight: 400;
          line-height: 36px;
        }

        .el-input__suffix {
          .el-input__suffix-inner {

            .el-input__icon,
            .el-input__clear {
              line-height: 36px;
            }
          }
        }
      }

      .el-textarea {
        textarea {
          box-sizing: border-box;
          background: rgba(40, 53, 122, 0.1);
          background: transparent;
          border: 1px solid #73A6D1;
          box-shadow: 0px 0px 2px #73A6D1;
          border-radius: 0;
          font-size: 16px;
          color: #9fc4ff;
          letter-spacing: 0;
          font-weight: 400;
        }
      }

      .el-form-item__content {


        .el-select {
          width: 100%;
        }
      }

      .linear-fields {
        border: none;
      }

      .overflow-auto {
        overflow: auto;
        max-height: 130px;
        border: 1px solid #27526e;
      }

      .noselected-area {
        color: #999;
        cursor: pointer;
        line-height: 34px;
        font-size: 16px;
      }

      .selected-area {
        color: rgb(25, 194, 107);
        cursor: pointer;
        line-height: 34px;
        font-size: 16px;
      }

      .Inspection {
        height: 37px;
      }
    }
  }
}
/* 绘制区域 */
.area-select-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(12, 34, 67, 0.4);
  z-index: 7000;

  .content {
    position: absolute;
    width: 1600px;
    height: 800px;
    left: 50%;
    top: 100px;
    margin-left: -800px;
    border: 1px solid #009aff;

    .button {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 160px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1000;

      .el-button--primary {
        background-color: #69b3e5;
      }
    }
  }
}
</style>