<template>
  <Dialog :title="Approved ? '任务详情单' : '任务调度单'" :width="1110" :left="120" :top="104" @on-close="clear" v-if="ValueList">
    <div class="Details_Box" ref="DetailsX">
      <div class="Details_Item">
        <div class="Details_Item_Heard">
          <div class="Details_Item_Left">
            <span class="Left_Item_Icon"></span>
            <span class="Left_Item_Title">{{ ValueList.isChild ? '子任务详情单' : '需求详情单' }}</span>
          </div>
          <div class="Details_Item_Right">
            <span class="Right_Item_Time">{{ ValueList.addTime }}</span>
          </div>
        </div>
        <div class="Details_Item_Body">
          <div class="Details_Item_Body_List">
            <div class="Body_Item_Box">
              <span class="Task_Name">{{ ValueList.isChild ? '子任务名称' : '需求单名称' }}：</span>
              <span class="Task_Value">{{ ValueList.taskTitle }}</span>
            </div>
            <div class="Body_Item_Box">
              <span class="Task_Name">任务类别：</span>
              <span class="Task_Value">{{ ValueList.taskCateName }}</span>
            </div>
            <div class="Body_Item_Box">
              <span class="Task_Name">任务场景：</span>
              <span class="Task_Value">{{ ValueList.taskTypeName }}</span>
            </div>
            <div class="Body_Item_Box">
              <span class="Task_Name">审核状态：</span>
              <span class="Task_Value">{{ mapList.apply[ValueList.applyStatus] }}</span>
            </div>
            <div class="Body_Item_Box">
              <span class="Task_Name">调度状态：</span>
              <span class="Task_Value">{{ disposeStatusFilter(ValueList.disposeStatus) }}</span>
            </div>
            <!-- <div class="Body_Item_Box">
              <span class="Task_Name">直属上级审批状态：</span
              ><span class="Task_Value">{{ValueList.papplyStatusByUser===null?'/':mapList.status[ValueList.papplyStatusByUser]}}</span>
            </div> -->
            <div class="Body_Item_Box">
              <span class="Task_Name">任务目标位置：</span>
              <span class="Task_Value">{{ ValueList.destination }}</span>
            </div>
            <div class="Body_Item_Box">
              <span class="Task_Name">详细位置描述：</span>
              <span class="Task_Value">{{ ValueList.origin }}</span>
            </div>
            <div class="Body_Item_Box">
              <span class="Task_Name">发起人姓名：</span>
              <span class="Task_Value">{{ ValueList.userName }}</span>
            </div>
            <div class="Body_Item_Box">
              <span class="Task_Name">联系方式：</span>
              <span class="Task_Value">{{ ValueList.phone }}</span>
            </div>
          </div>
          <div class="Details_Item_Body_List_Second">
            <div class="Body_Item_Box">
              <span class="Task_Name">开始时间：</span>
              <span class="Task_Value">{{ ValueList.startedAt }}</span>
            </div>
            <div class="Body_Item_Box">
              <span class="Task_Name">结束时间：</span>
              <span class="Task_Value">{{ ValueList.endAt }}</span>
            </div>
            <div class="Body_Item_Box">
              <span class="Task_Name">任务区域：</span>
              <span class="Task_Value" :style="ValueList.locationArea ? 'cursor: pointer;color: #265AD5;' : ''"
                @click="Selection(ValueList)">{{ ValueList.locationArea ? '点击查看' : '暂无' }}</span>
            </div>
          </div>
          <div class="Body_Item_Bottom_Box">
            <div class="Bottom_Task_Name Task_Name">需求单任务内容描述:</div>
            <div class="Task_Details">{{ ValueList.remark }}</div>
          </div>
        </div>
      </div>

      <div class="Details_Item">
        <div class="Details_Item_Heard">
          <div class="Details_Item_Left">
            <span class="Left_Item_Icon"></span>
            <span class="Left_Item_Title">所需资源</span>

          </div>
        </div>
        <div class="Resources_Item_Body">
          <!-- :rules="rules.edit_form"
        :model="edit_form" -->
          <el-form ref="formlist" class="linear-form vertical" label-width="80px" :rules="rules.form" :model="form">
            <el-form-item label="无人机" prop="deviceType" ref="Typedevice">
              <div v-for="(item, index) in form.deviceType" :key="item.key"
                style="display: flex; align-items: center; margin-bottom: 6px">
                <el-select v-model="item.deviceTypeId" placeholder="无人机类型" clearable ref="selectChange1"
                  :disabled="ValueList.disposeStatus == 2 || Approved">
                  <el-option v-for="item in drd.uav_type" :key="item.id" :label="item.title" :value="item.id"
                    :disabled="item.disabled" />
                </el-select>
                <span class="Symbol"></span>
                <el-input-number v-model="item.count" placeholder="无人机数量" clearable :min="1" :disabled="Approved" />
                <span v-if="!Approved">
                  <span v-if="index === 0" @click="
                    form.deviceType.length < drd.uav_type.length
                      ? form.deviceType.push({
                        deviceTypeId: null,
                        count: null,
                        key: nanoid(),
                      })
                      : $message({
                        message: `最多只能选择${drd.uav_type.length}种无人机类型`,
                        type: 'warning',
                      })
                    " class="fields-icon el-icon-plus"></span>
                  <span v-else @click="onetwo(index)" class="fields-icon icon-delete el-icon-minus"></span>
                </span>
              </div>
              <!-- <el-select></el-select> -->
            </el-form-item>
            <el-form-item label="挂载" prop="mountType">
              <div v-for="(item, index) in form.mountType" :key="item.key"
                style="display: flex; align-items: center; margin-bottom: 6px">
                <el-select v-model="item.mountTypeId" placeholder="挂载类型" clearable ref="selectChange2"
                  :disabled="Approved">
                  <el-option v-for="item in drd.mount_type" :key="item.id" :label="item.title" :value="item.id"
                    :disabled="item.disabled" />
                </el-select>
                <span class="Symbol"></span>
                <el-input-number v-model="item.count" placeholder="挂载数量" clearable :min="1" :disabled="Approved" />
                <span v-if="!Approved">
                  <span v-if="index === 0" @click="
                    form.mountType.length < drd.mount_type.length
                      ? form.mountType.push({
                        mountTypeId: null,
                        count: null,
                        key: nanoid(),
                      })
                      : $message({
                        message: `最多只能选择${drd.mount_type.length}种挂载类型`,
                        type: 'warning',
                      })
                    " class="fields-icon el-icon-plus"></span>
                  <span v-else @click="form.mountType.splice(index, 1)"
                    class="fields-icon icon-delete el-icon-minus"></span>
                </span>
              </div>
            </el-form-item>
            <el-form-item label="车辆" prop="name">
              <el-cascader size="mini" :disabled="Approved" ref="elCascader" style="width: 445px"
                class="input-orgname-new" @change="logArr" placeholder="请选择要分配的车辆" v-model="form.vehicleIdList"
                :options="vehicleArrayOptions" :show-all-levels="true" :props="{
                  children: 'vehicles',
                  label: 'carNumber',
                  value: 'id',
                  multiple: true,
                }" clearable>
              </el-cascader>
              <!-- <span class="Symbol"></span> -->
              <!-- <el-input-number
                v-model="form.provideCarCount"
                placeholder="请输入配备车辆数量"
                clearable
                :min="0"
              /> -->
            </el-form-item>
            <el-form-item label="电池" prop="batteryNumbers">
              <el-select size="mini" ref="selectChange3" :disabled="Approved" v-model="form.batteryNumbers"
                placeholder="请选择电池编号" multiple clearable class="Battery" :multiple-limit="2">
                <el-option v-for="item in drd.BatteryList" :key="item.id" :label="item.title" :value="item.title">
                </el-option>
              </el-select>

              <!-- <span class="Symbol"></span> -->
            </el-form-item>
            <el-form-item label="选配AI" prop="ai">
              <el-select @change="aichange" size="mini" :disabled="Approved" multiple v-model="form.ai"
                placeholder="请选配AI" clearable>
                <el-option v-for="item in aiList" :key="item.value" :label="item.value" :value="item.key"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="Details_Item">
        <div class="Details_Item_Heard">
          <div class="Details_Item_Left">
            <span class="Left_Item_Icon"></span>
            <span class="Left_Item_Title">飞行航线</span>
          </div>
        </div>
        <div class="Route_Item_Body">
          <el-form class="linear-form vertical" label-width="80px">
            <el-form-item label="航线选择" prop="name">
              <div class="Route">
                <el-select ref="selectChange4" v-model="form.flightLineId" placeholder="请选择航线" clearable
                  :disabled="Approved">
                  <el-option v-for="item in drd.flightLine" :key="item.id" :label="item.title" :value="item.id">
                  </el-option>
                </el-select>
                <div class="RouteDrawing" v-if="!Approved" @click="RouteDrawing">航线绘制</div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="Details_Item">
        <div class="Details_Item_Heard">
          <div class="Details_Item_Left">
            <span class="Left_Item_Icon"></span>
            <span class="Left_Item_Title">服务人员</span>
          </div>
        </div>

        <div class="Service_Item_Body">
          <Table :columns="columns" :dataSource="dataSource" style="max-height: 400px" v-if="!Approved">
            <template #free="{ row }">
              <div class="action">
                <span title="是否正在作业" class="iconfont">{{
                  row.free === 1 ? "空闲" : "非空闲"
                }}</span>
              </div>
            </template>
            <template #status="{ row }">
              <div class="action">
                <span title="分配情况" class="iconfont">{{
                  statusList[row.status]
                }}</span>
              </div>
            </template>
            <template #action="{ row }">
              <el-checkbox v-model="dataSource[row.index].checked" @change="rows(row.id)"></el-checkbox>
            </template>
          </Table>
          <!-- 已选择运维人员展示 -->
          <div class="select-man" v-if="Approved && flightUser && flightUser.length > 0">
            <el-collapse v-model="activeName">
              <el-collapse-item title="已分配运维人员：" name="1">
                <div class="select-man-item" v-for="(item, index) in flightUser" :key="index">
                  <div class="item">姓名：{{ item.name }}</div>
                  <div class="item">联系方式：{{ item.phone }}</div>
                  <div class="item">所属单位：{{ item.ORG_NAME }}</div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
      <div class="Details_Item" v-if="Approved">
        <div class="Details_Item_Heard">
          <div class="Details_Item_Left">
            <span class="Left_Item_Icon"></span>
            <span class="Left_Item_Title">审核记录</span>
          </div>
        </div>

        <div class="Service_Item_Body jcsb" v-if="reportList">
          <div class="Body_Item_Box">
            <span class="Task_Name facb mr10">审核人员:</span>
            <span class="Task_Value cf ">{{ reportList.reportApplyUser }}</span>
          </div>
          <div class="Body_Item_Box">
            <span class="Task_Name facb mr10">审核结果:</span>
            <span class="Task_Value cf">{{ mapList.report[reportList.status] }}</span>
          </div>
          <div class="Body_Item_Box" v-if="reportList.status == 2">
            <span class="Task_Name facb mr10">审核不通过原因:</span>
            <span class="Task_Value cf">{{ ValueList.approvalRemark }}</span>
          </div>
          <div class="Body_Item_Box">
            <span class="Task_Name facb mr10">审核时间:</span>
            <span class="Task_Value cf">{{ reportList.applyTime }}</span>
          </div>
        </div>
      </div>
      <div class="Details-footer">
        <div class="Button Left_Button" v-if="!Approved" @click="SubmitDetails">派 单</div>
      </div>
      <RegionalSelection :ShowSelection="ShowSelection" :cancelSaveArea="cancelSaveArea" :addallairArray="addallairArray"
        :leftOnclick="leftOnclick" :rigthOnclick="rigthOnclick" :move="move" :rowList="rowList"></RegionalSelection>
    </div>
  </Dialog>
</template>

<script>
import RegionalSelection from '@/components/common/regionalSelection/index.vue'
import Table from "@/components/common/table";
import Dialog from "@/components/common/new-dialog/index-tow.vue"; // 弹框组件
import { nanoid } from "nanoid";
import API from "@/api";
export default {
  props: {
    Approved: {
      type: Boolean,
      default: () => false
    },
    itemValue: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    Table,
    Dialog,
    RegionalSelection
  },
  data() {
    let editDevtype = (rule, value, callback) => {
      value.forEach((v) => {
        if (v.deviceTypeId == null || v.deviceTypeId == "") {
          callback(new Error("请选择无人机类型"));
        }
        if (!v.count) {
          callback(new Error("无人机数量不能为0"));
        }
      });
      callback();
    };
    let editMountType = (rule, value, callback) => {
      value.forEach((v) => {
        if (v.mountTypeId == null || v.mountTypeId == "") {
          callback(new Error("请选择挂载类型"));
        }
        if (!v.count) {
          callback(new Error("无人机数量不能为0"));
        }
      });
      callback();
    };
    let batteryNumbersValidator = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请选择电池"));
      } else if (value.length == 1) {
        callback(new Error("请选择两块电池"));
      }
      callback();
    };
    return {
      ValueList: [],
      aiList: null,
      reportList: null,
      activeName: "1",
      DetailsVisible: true,
      flightUser: [],
      dataSource: [],
      selsectId: [],
      statusList: {
        0: "未分配",
        1: "已分配",
        2: "其他任务分配",
      },
      mapList: {
        //列表数据过滤
        status: {
          [-1]: "已取消",
          0: "待执行",
          1: "已完成",
          3: "执行中",
        },
        apply: {
          [-1]: "暂无",
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
        report: {
          0: '待审批',
          1: '审批通过',
          2: '审批不通过'
        }
      },
      columns: [
        { prop: "name", label: "运维人员" },
        { prop: "phone", label: "联系手机号" },
        { prop: "free", slot: true, label: "是否正在作业" },
        { prop: "status", slot: true, label: "分配情况" },
        { prop: "organizationName", label: "所属单位" },
        {
          prop: "action",
          slot: true,
          label: "操作",
          width: "100px",
        },
      ],
      columnsPeople: [
        { prop: "name", label: "服务人员姓名" },
        { prop: "organizationName", label: "所属机构" },
        { prop: "phone", label: "联系电话" }
      ],
      drd: {
        flightLine: [],
        uav_type: [],
        mount_type: [],
        BatteryList: [],
      },
      form: {
        ai: [],
        //航线
        flightLineId: null,
        //无人机
        deviceType: [{ deviceTypeId: null, count: null, key: nanoid() }],
        //挂载
        mountType: [{ mountTypeId: null, count: null, key: nanoid() }],
        //车辆
        vehicleIdList: [],
        //电池
        batteryNumbers: [],
      },
      vehicleArrayOptions: [], // 车辆选择下拉列表
      Details_List: null, //任务数据
      rules: {
        //验证规则
        form: {
          batteryNumbers: [
            {
              required: true,
              validator: batteryNumbersValidator,
              trigger: "change",
            },
          ],
          deviceType: [
            { required: true, validator: editDevtype, trigger: "change" },
          ],
          mountType: [
            { required: true, validator: editMountType, trigger: "change" },
          ],
        },
      },
      //区域选择
      ShowSelection: false,
      areaPointList: null, // 发起需求区域绘制点对象
      rowList: null,//点击区域任务
    };
  },
  methods: {
    aichange() {
      this.$forceUpdate()
    },
    async drd_task_ais() { // AI下拉
      try {
        let res = await API.AI.ailist();
        this.aiList = res.data
      } catch (e) {
        console.error(e);
      }
    },
    //区域选择
    leftOnclick(data) {
      console.log(data);
    },
    rigthOnclick(data) {
      console.log(data);
    },
    move(data) {
      console.log(data);
    },
    cancelSaveArea() { //关闭区域选择
      this.rowList = null
      this.ShowSelection = false
    },
    // 将子组件获取的禁飞区传递出来
    addallairArray(value) {
      this.allairArray = value
    },
    Selection(row) {
      console.log(row.locationArea);
      if (row.locationArea) {
        this.rowList = row
        this.ShowSelection = true
      }
    },

    disposeStatusFilter(status) {//筛选调度状态返回数据到页面
      if (status == 1) {
        return '待派单'
      } else if (status == 2) {
        return '已派单'
      }
      return ''
    },
    rows(id) {
      console.log(id);
      // 存储选择的运维
      if (this.selsectId.indexOf(id) > -1) {
        this.selsectId = [];
      } else {
        this.selsectId = [id];
      }
    },
    nanoid() {
      return nanoid();
    },
    clear() {//关闭弹框清空数据
      this.form = {
        //航线
        flightLineId: null,
        //无人机
        deviceType: [{ deviceTypeId: null, count: null, key: nanoid() }],
        //挂载
        mountType: [{ mountTypeId: null, count: null, key: nanoid() }],
        //车辆
        vehicleIdList: [],
        //电池
        batteryNumbers: [],
        ai: [],

      },
        this.$refs.formlist.resetFields()
      if (this.itemValue.isSubPage) {
        this.$emit('close-toSubPage')
      } else {
        this.$emit('clear')
      }
      this.$refs.DetailsX.scrollTop = 0
    },
    clearvalue() {
      this.form = {
        //航线
        flightLineId: null,
        //无人机
        deviceType: [{ deviceTypeId: null, count: null, key: nanoid() }],
        //挂载
        mountType: [{ mountTypeId: null, count: null, key: nanoid() }],
        //车辆
        vehicleIdList: [],
        //电池
        batteryNumbers: [],
      }
    },
    RouteDrawing() { //航线绘制按钮
      this.$emit('clearAll')
    },
    async FlightLine() {
      // 获取航线下拉数据
      const { data } = await API.TASK.Drd({
        issafe: 1
      });
      this.drd.flightLine = data;
    },
    async VehicleList() {
      // 车辆列表获取
      try {
        let res = await API.TASK.VehicleList();
        this.vehicleArrayOptions = res.data;
        this.vehicleArrayOptions.map((item) => {
          item.carNumber = item.carTypeName;
          if (!item.vehicles) {
            item.disabled = true;
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    onetwo(index) {
      this.form.deviceType.splice(index, 1);
      this.form.deviceType.forEach((v) => {
        if (v.deviceTypeId == null || v.deviceTypeId == "" || !v.count) {
        } else {
          this.$refs.Typedevice.clearValidate();
        }
      });
    },
    async operatorList() {
      // 获取飞手排班列表
      const { data } = await API.TASK.flyingUser({});
      data.map((item, index) => {
        item.checked = false;
        item.index = index;
        if (this.selsectId.length > 0 && this.selsectId[0] == item.id) {
          item.checked = true;
        }
      });
      this.dataSource = data;
    },
    async drd_mount_type() {
      //获取挂载类型列表
      try {
        let res = await API.MOUNT.GetDrdType();
        res = res.data;

        this.drd.mount_type = res.map((item) => ({
          ...item,
          title: item.name,
          disabled: false,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    logArr() {
      console.log(this.form.vehicleArray);
    },
    CloseDetails() {
      //关闭调度详情
      this.DetailsVisible = false;
    },
    async drd_uav_type() {
      try {
        let res = await API.UAV.GetDrdType();
        res = res.data;
        this.drd.uav_type = res.map((item) => ({
          ...item,
          title: item.name,
          disabled: false,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async getBatteryList() {
      //电池列表
      const { data } = await API.TASK.BatteryList();
      this.drd.BatteryList = data;
    },
    async approvalDistribution(data) {
      if (data) this.getSubTaskData(data.id)
      this.operatorList();
      this.drd_uav_type();
      this.drd_mount_type();
      this.VehicleList();
      this.getBatteryList();
      this.FlightLine()
      this.DetailsVisible = true;
    },
    async getSubTaskData(id) { // 如果是修改子任务把子任务的数据请求回来并回显表单
      const { data } = await API.TASK.getOneSubtaskNew(id)
      this.itemValue.isSubPage ? data.isSubPage = true : data.isSubPage = false
      this.ValueList = data
      if (this.ValueList.reportStatus) {
        let { data: res } = await API.TASK.getReportDetail(id);
        this.reportList = res
        this.$forceUpdate()
      }
      this.form.batteryNumbers = data.batteryNumbers ? data.batteryNumbers.map(item => {
        return item
      }) : []
      this.form.flightLineId = data.flightLineId
      this.form.deviceType = data.deviceType.length ? data.deviceType : [{ deviceTypeId: null, count: null, key: nanoid() }]
      this.form.mountType = data.mountType.length ? data.mountType : [{ mountTypeId: null, count: null, key: nanoid() }]
      this.form.ai = data.ai || []
      this.form.vehicleIdList = data.vehicleList && data.vehicleList.length > 0 ? data.vehicleList.map(item => {
        return [item.carTypeId, item.carId]
      }) : []
      this.flightUser = data.flightUser
      this.selsectId = data.flightUser && data.flightUser.length ? [data.flightUser[0].id] : []
    },
    SubmitDetails() {   // 派单按钮
      //调度详情保存
      // 保存处理需求
      if (this.selsectId.length <= 0) {
        this.$message.warning("未分配运维人员");
        return;
      } else {
        this.$refs["formlist"].validate(async (valid, object) => {
          if (valid) {
            // const id = this.$route.params.id;
            const id = this.itemValue.id;
            const { vehicleIdList, ...rest } = this.form;
            let carList = vehicleIdList.map(item => {
              return item[1]
            })

            const params = {
              ...rest,
              vehicleIdList: carList,
              deviceType:
                rest.deviceType.length >= 1 && rest.deviceType[0].deviceTypeId
                  ? rest.deviceType
                  : [],
              mountType:
                rest.mountType.length >= 1 && rest.mountType[0].mountTypeId
                  ? rest.mountType
                  : [],
              id,
              operatorIds: this.selsectId, // 运维人员
              processingStatus: 1,
            };
            const res = await API.TASK.newhandle(params);
            if (res.status == 1) {
              this.$el_message("处理成功", () => {
                this.DetailsVisible = false;
                this.form = {
                  flightLineId: null,
                  deviceType: [
                    { deviceTypeId: null, count: null, key: nanoid() },
                  ],
                  mountType: [
                    { mountTypeId: null, count: null, key: nanoid() },
                  ],
                  vehicleIdList: [],
                  batteryNumbers: [],
                };
                this.$refs.formlist.resetFields()
                this.clear()
              });
            } else {
              this.$el_message(res.message);
            }
          } else {
            setTimeout(() => {
              // 如果不通过验证，跳到没有通过验证的输入框并获取焦点 
              let isError = document.getElementsByClassName("is-error");
              if (isError.length > 0) {
                isError[0].querySelector("input").focus();
              }
            }, 100);
          }
        });
      }
    },
  },
  async mounted() {
    console.log(this.itemValue);
    this.drd_task_ais()
    if (this.itemValue && this.itemValue.id) {
      await this.approvalDistribution(this.itemValue)
    }
  },
  watch: {
    $route() {
      this.operatorList();
    },
    // "fromRef.flightUser"(val) {
    //   if (val && val.length > 0) {
    //     this.selsectId = val.map((item) => {
    //       return item.id;
    //     });
    //   }
    // },
    selsectId(val) {
      if (val.length > 0) {
        this.dataSource.map((item) => {
          if (item.id == val[0]) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
      }
    },
    "form.deviceType": {
      // 配备无人机下拉限制
      handler: function (val) {
        let indexarr = [];
        val.forEach((item) => {
          if (item.deviceTypeId) {
            indexarr.push(item.deviceTypeId);
          }
        });
        this.drd.uav_type.forEach((itemIn) => {
          itemIn.disabled = false;
        });

        indexarr.forEach((item) => {
          this.drd.uav_type.forEach((itemIn) => {
            if (itemIn.id == item) {
              itemIn.disabled = true;
            }
          });
        });
      },
      deep: true,
      immediate: true,
    },
    "form.mountType": {
      // 配备挂载下拉选择限制
      handler: function (val) {
        let indexarr = [];
        val.forEach((item) => {
          if (item.mountTypeId) {
            indexarr.push(item.mountTypeId);
          }
        });

        this.drd.mount_type.forEach((itemIn) => {
          itemIn.disabled = false;
        });

        indexarr.forEach((item) => {
          this.drd.mount_type.forEach((itemIn) => {
            if (itemIn.id == item) {
              itemIn.disabled = true;
            }
          });
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.facb {
  color: #93a0af;
}

/* 运维人员样式 */
::v-deep {
  .el-collapse-item__header {
    font-size: 16px;
    font-weight: 600;
    color: #9fc4ff;
  }

  .el-collapse-item__content {
    color: #9fc4ff;
  }

  .el-collapse-item__content {
    padding-bottom: 16px;
  }

  .el-collapse-item__header {
    background: rgba(82, 39, 39, 0);
  }

  .el-collapse-item__wrap {
    background: rgba(82, 39, 39, 0);
    border: 0px;
  }

  .el-collapse-item__header {
    border: 0px;
  }

  .el-collapse {
    border: 0px;
  }
}

//调度详情单
::v-deep {
  .select-man {
    margin-bottom: 16px;
    color: #9fc4ff;
    font-size: 16px;

    .select-man-item {
      margin-top: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;

      .item {
        width: 33%;
      }
    }
  }

  .Resources_Item_Body,
  .Route_Item_Body {
    .linear-form.vertical .el-form-item {
      .Route {
        display: flex;
        align-items: center;

        .RouteDrawing {
          width: 86px;
          height: 31px;
          background: rgba(38, 71, 238, 0.71);
          box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
          border-radius: 2px;
          border: 1px solid;
          border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 30px;
          text-align: center;
          margin-left: 12px;
          cursor: pointer;
        }
      }

      .fields-icon {
        width: 34px;
        height: 28px;
        background: rgba(0, 0, 0, 0.5);
        border: 1px solid #768094;
        text-align: center;
        line-height: 30px;
        margin-left: 10px;
        color: #fff;
        cursor: pointer;
      }

      .Symbol {
        margin: 0 5px;
        display: inline-block;
        width: 10px;
        height: 1px;
        background: #d8d8d8;
        vertical-align: middle;
      }

      .el-form-item__label {
        line-height: 32px;
        float: left;
        text-align: right;
        color: #aab7c3;
      }

      .el-form-item__content {
        .el-select {
          width: 440px;
          // height: 30px;
          background: rgba(0, 0, 0, 0.5);
        }

        .el-select.Battery {
          width: 443px;
          // height: 40px;
          // margin-bottom: 20px;
          background: rgba(0, 0, 0, 0.5);
        }

        .el-input-number {
          //添加数据按钮右侧
          width: 212px;
          line-height: 38px;

          .el-input-number__increase {
            border-left: 1px solid #768094;
            height: 30px;
            line-height: 32px;
            margin-top: 2px;
          }

          //添加数据按钮左侧
          .el-input-number__decrease {
            border-right: 1px solid #768094;
            height: 30px;
            line-height: 32px;
            margin-top: 2px;
          }
        }
      }

      .el-input {
        .el-input__inner {
          border: 1px solid #768094;
          height: 30px;
          background: rgba(0, 0, 0, 0.5);
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #aab7c3;
          line-height: 20px;
        }

        .el-input__suffix .el-input__suffix-inner .el-input__icon {
          line-height: 30px;
        }
      }
    }
  }
}

.Details_Box {
  height: 863px;
  width: calc(100% - 6px);
  padding-right: 10px;
  overflow: auto;

  .Details_Item {
    margin-bottom: 32px;

    .Details_Item_Heard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 13px;
      border-bottom: 1px solid rgba(170, 183, 195, 0.15);
      margin-bottom: 15px;

      .Details_Item_Left {
        display: flex;
        align-items: center;

        .Left_Item_Icon {
          display: inline-block;
          width: 4px;
          height: 16px;
          background: #ffbd36;
          border-radius: 2px;
        }

        .Left_Item_Title {
          padding-left: 5px;
          width: 130px;
          height: 24px;
          font-size: 18px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
        }
      }

      .Details_Item_Right {
        .Right_Item_Time {
          width: 116px;
          height: 16px;
          font-size: 14px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #aab7c3;
          line-height: 16px;
        }
      }
    }

    .Details_Item_Body {
      width: 100%;

      .Details_Item_Body_List {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
      }

      .Details_Item_Body_List_Second {
        display: flex;
        align-items: center;

        &.Bottom-Margin {
          margin-bottom: 11px;
        }
      }

      .Body_Item_Box {
        width: 33%;
        margin-bottom: 11px;
      }

      .Body_Item_Bottom_Box {
        .Bottom_Task_Name {
          margin-bottom: 11px;
        }

        .Task_Details {
          padding: 16px 24px;
          height: 75px;
          background: rgba(0, 0, 0, 0.6);
          font-size: 14px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          overflow: auto;
        }
      }

      .Task_Name {
        // width: 70px;
        // height: 20px;
        font-size: 14px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #aab7c3;
        line-height: 20px;
      }

      .Task_Value {
        // width: 56px;
        // height: 20px;
        font-size: 14px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }

  .Details-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .Button {
      text-align: center;
      width: 126px;
      height: 40px;
      border-radius: 6px;
      // border: 1px solid;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 40px;
      cursor: pointer;
    }

    .Left_Button {
      // width: 126px;
      // height: 40px;
      // border-radius: 6px;
      // border: 1px solid;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #ffffff,
        inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-image: linear-gradient(180deg,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)) 1 1;
      margin-right: 20px;
    }

    .Right_Button {
      background: rgba(219, 27, 27, 0.71);
      box-shadow: inset 0px 1px 3px 0px #ffffff,
        inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
      border-image: linear-gradient(180deg,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)) 1 1;
      margin-left: 20px;
    }
  }
}

.number-of-tasks {
  width: 100%;

  .out {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .status-select {
      cursor: pointer;
      // width: 170px;
      height: 26px;
      margin-bottom: 16px;

      .button {
        background: rgba(0, 0, 0, 0.7);
        color: #94b3c2;
        font-size: 20px;
        text-align: center;
        width: 50%;
        height: 100%;
        line-height: 26px;
      }

      .button-active {
        background-color: #1f4dcb;
      }
    }

    .mc {
      width: 35%;
    }

    .lx {
      width: 27%;
    }

    .zt {
      width: 25%;
    }

    .cz {
      width: 13%;
    }

    .header {
      font-size: 16px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #aab7c3;
      text-align: center;
      background: rgba(3, 24, 66, 0.4);
      padding: 10px 0 10px 0;
    }

    .content {
      height: 124px;
      overflow: auto;

      .list {
        padding: 10px 0;
        background: linear-gradient(270deg,
            rgba(132, 179, 242, 0) 0%,
            rgba(132, 179, 242, 0.3) 52%,
            rgba(132, 179, 242, 0) 100%);
      }

      .list2 {
        background: rgba(3, 24, 66, 0.4);
      }
    }
  }
}
</style>