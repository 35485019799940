<template>
  <div class="demandapproval">
    <!-- 需求单制定弹框 -->
    <Dialog @on-close="closeIDsPopup" title="任务单审核" :width="1497" :top="120" :left="120">
      <div class="content pr">
        <!-- 右侧列表 -->
        <div class="list">
          <!-- 顶部四个东西 -->
          <div class="approval-info state">
            <!-- 待审批 -->
            <div @click="approvalStatusClick('0')" class="jc hwb-flex  dsp"
              :class="edit_form.approvalStatus == 0 ? 'selected' : null" style="color:#50F9F4;">
              <div class="jc-tp">
                <img src="./img/dsp.svg" alt="">
              </div>
              <div class="jc-text">
                <div class="num">{{ approvalStatusNum.wait }}</div>
                <div class="text">待审批</div>
              </div>
            </div>
            <!-- 审批通过 -->
            <div @click="approvalStatusClick(1)" class="jc hwb-flex  sptg"
              :class="edit_form.approvalStatus == 1 ? 'selected' : null" style="color:#00C4FF;">
              <div class="jc-tp">
                <img src="./img/sptg.svg" alt="">
              </div>
              <div class="jc-text">
                <div class="num">{{ approvalStatusNum.pass }}</div>
                <div class="text">审批通过</div>
              </div>
            </div>
            <div @click="approvalStatusClick(2)" class="jc hwb-flex  spbtg"
              :class="edit_form.approvalStatus == 2 ? 'selected' : null" style="color:#FFD360;">
              <div class="jc-tp">
                <img src="./img/spbtg.svg" alt="">
              </div>
              <div class="jc-text">
                <div class="num">{{ approvalStatusNum.notPass }}</div>
                <div class="text">审批不通过</div>
              </div>
            </div>
          </div>
          <!-- 赛选栏 -->
          <div class="select">
            <el-cascader popper-class="input-orgname" placeholder="请选择所属机构" v-model="edit_form.organId" clearable
              :options="selectList" :show-all-levels="false" :props="{
                children: 'child',
                label: 'anotherName',
                value: 'id',
                checkStrictly: true,
                emitPath: false,
                empty: false
              }" class="button">
            </el-cascader>
            <el-select v-model="edit_form.taskCate" size="mini" clearable placeholder="请选择任务类型" class="button">
              <el-option v-for="item in drd.cates" :key="item.id" :label="item.title" :value="item.id" />
            </el-select>
            <el-select v-model="edit_form.taskType" size="mini" clearable placeholder="请选择场景类型" class="button">
              <el-option v-for="item in drd.scenes" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
            <el-input v-model="edit_form.name" class="w170 ml10 dib " size="mini" placeholder="请输入任务单名称" clearable>
            </el-input>
            <div class="search-button" @click="searchData">搜索</div>
          </div>
          <!-- 表格 -->
          <Table class="task-table" :columns="columns" :dataSource="dataSource" :pagination="pagination" @size-change="(size) => {
            pagination.pageSize = size;
            async_get_list();
          }" @page-change="(page) => {
  async_get_list(page);
}
  ">
            <template #taskCateName="{ row }">
              <div class="action" :style="getStatusColor.getTaskColor(row.taskCateName === '常态任务' ? 2 : 3)">
                {{ row.taskCateName }}
              </div>
            </template>
            <template #papplyStatus="{ row }">
              <div class="action" :style="getApplyColor(row.papplyStatus)">
                {{ map.apply[row.papplyStatus] }}
              </div>
            </template>
            <template #action="{ row }">
              <div class="action">
                <span title="审核" @click="examine(row)" v-if="row.papplyStatus == 0
                  " class="iconfont icon-shenhe" style="color:#35D9E7;padding-right:6px"></span>
                <span title="详情" v-if="row.papplyStatus !== 0
                  " @click="showDetails(row)" class="iconfont icon-chakanxiangqing"
                  style="color:#35D9E7;padding-right:6px"></span>
                <!-- <span title="重新下单" v-if="
                  row.papplyStatus !== 0
                "  class="iconfont "
                  style="color:#35D9E7;padding-right:6px"></span> -->
                <!-- <img  v-if="
                  row.papplyStatus==2
                " @click="editDetails(row)" src="./img/5.png" title="重新下单" class="dib w15 h15 cp" alt=""> -->
              </div>
            </template>
          </Table>
        </div>
      </div>
    </Dialog>
    <!-- 审批弹框 -->
    <div id="dialogIsShow">
      <!-- 执行审批 -->
      <BigDialog v-if="dialogIsShow" @on-close="coloseNeedPopup" title="任务详情页" :width="938" :left="491" :top="188">
        <div class="dispatch">
          <div class="need-top-title hwb-flex">
            <div class="left">
              <div class="dec"></div>任务详情页
            </div>
            <div class="right">{{ Details_List.addTime }}</div>
          </div>
          <div class="need-bottom">
            <div class="one-info"><span>需求单名称：</span><span style="color:#fff">{{ Details_List.taskTitle }}</span></div>
            <div class="one-info"><span>任务类别：</span><span style="color:#6AFF64">{{ Details_List.taskCateName }}</span>
            </div>
            <div class="one-info"><span>任务场景：</span><span style="color:#fff">{{ Details_List.taskTypeName }}</span>
            </div>

            <div class="one-info"><span>审核状态：</span><span
                :style="getStatusColor.getApplyColor(Details_List.papplyStatus)">{{
                  getStatusColor.getApplyWord(Details_List.papplyStatus)
                }}</span>
            </div>
            <div class="one-info"><span>调度状态：</span><span
                :style="getStatusColor.getDispatchColor(Details_List.disposeStatus)">{{
                  getStatusColor.getDispatchWord(Details_List.disposeStatus)
                }}</span>
            </div>
            <div class="one-info"><span>直属上级审批状态：</span><span
                :style="getStatusColor.getApplyColor(Details_List.papplyStatusByUser)">{{
                  getStatusColor.getApplyWord(Details_List.papplyStatusByUser)
                }}</span>
            </div>

            <div class="one-info"><span>发起单位：</span><span style="color:#fff">{{ Details_List.organizationName }}</span>
            </div>
            <div class="one-info"><span>发起人姓名：</span><span style="color:#fff">{{ Details_List.userName }}</span></div>
            <div class="one-info"><span>联系方式：</span><span style="color:#fff">{{ Details_List.phone }}</span></div>

            <div class="info-add">
              <div class="one-info"><span>开始时间：</span><span style="color:#fff">{{ Details_List.startedAt }}</span></div>
              <div class="one-info"><span>结束时间：</span><span style="color:#fff">{{ Details_List.endAt }}</span></div>
              <div class="one-info"><span class="Task_Name">任务区域：</span><span class="Task_Value"
                  :style="Details_List.locationArea ? 'cursor: pointer;color: #265AD5;' : ''"
                  @click="Selection(Details_List)">{{ Details_List.locationArea ? '点击查看' : '暂无' }}</span></div>
            </div>

            <div class="one-info-long"><span>任务目标位置：</span><span style="color:#fff">{{ Details_List.destination
            }} </span>
            </div>
            <div class="one-info-long"><span>详细位置说明：</span><span style="color:#fff">{{ Details_List.origin }}</span>
            </div>
            <div class="one-info-long"><span>任务航线：</span>
              <span @click="showLline(Details_List)" style="color:#265ad5" class="cp"
                v-if="Details_List.flightLineId">航线预览</span>
              <span class="cf" v-else> 暂无航线 {{ Details_List.flightLineId }}</span>
            </div>
          </div>
          <div style="color:#AAB7C3">需求单任务内容描述：</div>
          <div class="detail">
            {{ Details_List.remark }}
          </div>
          <div style="color:#AAB7C3" v-if="applyStatusId === 2">审批不通过原因:</div>
          <div class="detail" v-if="applyStatusId === 2">
            {{ Details_List.approvalRemark }}
          </div>
          <div v-if="applyStatusId === 0 && user_info_hn.departmentId === 60" class="need-task-type hwb-flex">
            <div>需求单是否需要创建子任务：</div>
            <div>
              <el-radio v-model="radio" label="1">是</el-radio>
              <el-radio v-model="radio" label="2">否</el-radio>
            </div>
          </div>
          <!-- 审批按钮 -->
          <div class="button hwb-flex" v-if="applyStatusId === 0">
            <div class="no" @click="showApprovalFailed">审核不通过</div>
            <div class="yes" @click="submitApproval(1)">审核通过</div>
          </div>
        </div>
      </BigDialog>
    </div>
    <!-- 审批不通过弹框 -->
    <div id="ApprovalFailed">
      <BigDialog v-if="ApprovalFailed" class="Approved" @on-close="coloseApprovalFailed" title="审批不通过" :width="545"
        :left="687" :top="358">
        <div class="Approved_Body FailedTextarea">
          <el-form :model="ruleForm" :rules="rules">
            <el-form-item prop="ApprovalFailedTextarea">
              <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请输入审批不通过原因"
                v-model="ruleForm.ApprovalFailedTextarea">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 审批按钮 -->
        <div class="Approved_Footer">
          <div class="no" @click="coloseApprovalFailed">取消</div>
          <div class="yes" @click="submitApproval(2)">确定</div>
        </div>
      </BigDialog>
    </div>
    <!-- 需求单制定 -->
    <!-- <div v-if="mapSelectionArea" class="area-select-popup">
      <div class="content">
        <div class="button">
          <el-button @click="cancelSaveArea" type="primary">取消</el-button>
          <el-button @click="saveArea" type="primary">保存</el-button>
        </div>
        <Drawky :leftOnclick="(value) => leftOnclick(value)" :rigthOnclick="(value) => rigthOnclick(value)"
          :move="(value) => move(value)" :airspcelist="areaPointList" :drakyStyle="true"
          :addallairArray="(value) => addallairArray(value)" />
      </div>
    </div> -->
    <!-- <el-dialog title="需求单制定" :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
      <div class="content hwb-flex"> -->
    <!-- 左侧发起需求 -->
    <!-- <div class="create-need"> -->
    <!-- 中间的竖线装饰 -->
    <!-- 发起需求弹框 -->
    <!-- :hide-required-asterisk="true"  表单清除红色提示-->
    <!-- <el-form ref="edit_form" :model="edit_form2" :label-position="'left'" :rules="edit_form2rules"
            class="edit-form" label-width="110px">
            <el-form-item label="需求单名称" prop="taskTitle">
              <el-input v-model="edit_form2.taskTitle" placeholder="请输入需求单名称" maxlength="254" clearable />
            </el-form-item>
            <el-form-item label="任务类型" prop="taskCateId">
              <el-select v-model="edit_form2.taskCateId" placeholder="请选择任务类型" clearable :disabled="updateNeedData">
                <el-option v-for="item in drd.task_type" :key="item.id" :label="item.title" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="任务场景" prop="taskTypeId">
              <el-select v-model="edit_form2.taskTypeId" placeholder="请选择场景类型" clearable>
                <el-option v-for="item in drd.task_cate" :key="item.id" :label="item.title" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="时间要求" >
              <div class="timecss">
                <el-form-item prop="startedAt" style="margin-bottom: 0px;">
                  <el-date-picker v-model="edit_form2.startedAt" :picker-options="pickerOptionsStart"
                    @focus="dateStartPickerFocus" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="开始日期"
                    clearable>
                  </el-date-picker>
                </el-form-item>
                <span style="width: 6px; color: #D8D8D8; text-align: center">-</span>
                <el-form-item prop="endAt" style="margin-bottom: 0px;">
                  <el-date-picker v-model="edit_form2.endAt" :picker-options="pickerOptionsEnd"
                    @focus="dateEndPickerFocus" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="结束日期"
                    clearable>
                  </el-date-picker>
                </el-form-item>
              </div>
            </el-form-item>

            <el-form-item label="任务目标位置" prop="destination">
              <el-cascader class="input-orgname" placeholder="请选择街道" v-model="edit_form2.destination"
                :options="drd.cityType" :props="{
                  children: 'children',
                  label: 'title',
                  value: 'id',
                  emitPath: false,
                  empty: false,
                }" clearable>
              </el-cascader>
            </el-form-item>
            <el-form-item label="详细位置说明" prop="origin">
              <el-input v-model="edit_form2.origin" placeholder="请输入详细位置" clearable maxlength="254" />
            </el-form-item>
            <el-form-item label="区域选择">
              <span @click="mapSelectionArea = true" :class="[areaPointList ? 'selected-area ' : 'noselected-area']">
                {{ areaPointList ? "(点击修改区域)" : "(重新选择区域)" }}
              </span>
            </el-form-item>
            <el-form-item label="需求描述" style="width: 100%" prop="remark">
              <el-input v-model="edit_form2.remark" placeholder="请输入需求描述" type="textarea" :rows="4" clearable
                maxlength="254"></el-input>
            </el-form-item>
          </el-form> -->
    <!-- 确定按钮 -->
    <!-- <div class="anniu">
            <div @click="cancelUpdate" v-if="updateNeedData" class="button button2">取消修改</div>
            <div @click="handle_save" class="button">{{ updateNeedData ? '确认修改' : '确认' }}</div>
          </div>
        </div>

      </div>
    </el-dialog> -->
    <RegionalSelection :ShowSelection="ShowSelection" :cancelSaveArea="cancelSave" :addallairArray="addallairArray"
      :leftOnclick="leftOnclick" :rigthOnclick="rigthOnclick" :move="move" :rowList="rowList"></RegionalSelection>
    <div class="out-content h800">
      <el-dialog :title="taskshow.list ? '预览航线' : '历史轨迹预览'" :close-on-click-modal="false" :visible.sync="taskshow.show"
        v-if="taskshow.show" :before-close="clearTask" class="taskDialong ">
        <Draw v-if="taskshow" :taskshow="taskshow.list" :historicalTrack="historicalTrackList" :readonly="taskshow.show"
          class="taskDraw" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
let locationEntity;
import RegionalSelection from '@/components/common/regionalSelection/index.vue'
import Dialog from '@/components/common/new-dialog/index-tow.vue'; // 弹框组件
import Table from "@/components/common/table-new-hwb"; // 列表组件
import BigDialog from "@/components/common/new-dialog/index.vue"; // 弹框组件(有遮罩层)
import API from "@/api";
import Draw from "@/components/draw";

import Drawky from "@/components/drawky"; // 绘制区域的
import { transformCartesian2WGS84 } from "@/utils/cesium";
import * as turf from '@turf/turf'
import dayjs from "dayjs";
import getStatusColor from "@/api/statusColorGet.js"; // 获取颜色函数
import { mapGetters, mapActions } from "vuex"
export default {
  components: {
    Dialog,
    BigDialog,
    Table,
    Drawky,
    Draw,
    RegionalSelection
  },
  data() {
    return {
      // 重新下单start
      client: null,  // 装mqtt的东西  以便销毁
      Details_List: null, // 详情存的数据
      pickerOptionsStart: {}, //预计飞行开始时间限制
      pickerOptionsEnd: {}, //预计飞行结束时间限制
      areaPointList: null, // 发起需求区域绘制点对象
      regionList: null, // 存储选择区域点的原始x y z数据
      mapSelectionArea: false, // 地图选择区域控制弹框显示隐藏
      initiatingDemandPopupIsShow: true, // 控制需求单制定弹框是否展示
      updateNeedData: null, // 如果是修改需求单  吧该需求的数据放在这
      edit_form2: {  // 发起需求表单字段
        id: null,
        taskTitle: null,
        taskCateId: 2,
        taskTypeId: null,
        startedAt: null,
        endAt: null,
        origin: null,
        destination: null,
        remark: null,
        locationArea: null, // 存储区域字符串
      },
      taskshow: {
        show: false, // 预览航线是否展示
        list: null, // 预览航线存储数据
      },
      dialogVisible: false,
      edit_form2rules: {  // 验证规则
        taskTitle: [
          { required: true, message: "请输入需求单名称", trigger: "blur" },
        ],
        taskCateId: [
          { required: true, message: "请选择任务类型", trigger: "blur" },
        ],
        startedAt: [
          { required: true, message: "请选择飞行开始时间", trigger: "change" },
        ],
        endAt: [
          { required: true, message: "请选择飞行结束时间", trigger: "blur" },
        ],
        origin: [
          { required: true, message: "请输入详细位置", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入需求描述", trigger: "blur" },
        ],
        taskTypeId: [
          { required: true, message: "请选择场景类型", trigger: "blur" },
        ],
        destination: [
          { required: true, message: "请选择街道", trigger: "blur" },
        ],
      },
      // 重新下单end

      getStatusColor,
      selectList: null, // 赛选的机构
      dataval: [], //将后台返回的机构处理后只存入当前登录帐号机构以及子机构
      initiatingDemandPopupIsShow: true, // 控制需求单制定弹框是否展示
      approvalStatusNum: { // 审批状态各个状态数量
        notPass: 0,
        pass: 0,
        wait: 0
      },
      applyStatusId: 0,//初始化的数据以及更改审批状态更新列表
      edit_form: {  // 发起需求表单字段
        approvalStatus: null, // 审批状态赛选
        organId: null, // 机构赛选的id
        taskCate: null, // 任务类型
        taskType: null, // 场景类型
        name: null,
      },

      drd: {
        cates: [
          { id: 2, title: "常态任务" },
          { id: 3, title: "临时紧急任务" },
        ],
        scenes: null,
        uav_type: [],
        mount_type: [],
        task_type: [],
        task_cate: [],
        flightLine: [],
        proStatus: [
          { id: 1, label: "待处理", value: 0 },
          { id: 2, label: "处理完成", value: 1 },
          { id: 3, label: "无需处理", value: 2 },
        ],
        cityType: [
          {
            id: 13,
            title: "济源市",
            children: [
              { id: 1, title: "沁园街道" },
              { id: 2, title: "济水街道" },
              { id: 3, title: "北海街道" },
              { id: 4, title: "天坛街道" },
              { id: 5, title: "玉泉街道" },
              { id: 6, title: "克井镇" },
              { id: 7, title: "五龙口镇" },
              { id: 8, title: "轵城镇" },
              { id: 9, title: "承留镇" },
              { id: 10, title: "邵原镇" },
              { id: 11, title: "坡头镇" },
              { id: 12, title: "梨林镇" },
              { id: 17, title: "大峪镇" },
              { id: 14, title: "思礼镇" },
              { id: 15, title: "王屋镇" },
              { id: 16, title: "下冶镇" },


            ],
          },
        ],
      },


      columns: [
        { prop: "index", label: "序号", width: "100px" },
        { prop: "taskTitle", label: "任务单名称", width: "230px" },
        { prop: "taskCateName", slot: true, label: "任务类型", width: "170px" },
        { prop: "taskTypeName", label: "任务场景", width: "170px" },
        { prop: "papplyStatus", slot: true, label: "审核状态", width: "170px" },
        { prop: "organizationName", label: "单位名称", width: "170px" },
        { prop: "startedAt", label: "开始时间", width: "170px" },
        { prop: "endAt", label: "结束时间", width: "170px" },
        { prop: "action", slot: true, label: "操作", width: "170px" },
      ],
      dataSource: [],
      mapList: { //列表数据过滤
        status: {
          [-1]: "已取消",
          0: "待执行",
          1: "已完成",
          3: "执行中",
        },
        apply: {
          [-1]: "暂无",
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
      },
      map: {
        status: {
          "-2": "已拒绝",
          "-1": "已取消",
          0: "待执行",
          1: "已完成",
          // 2: "执行中",
          3: "执行中",
        },
        apply: {
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      dialogIsShow: false, //审批框状态
      Details_List: null, //任务信息
      Approved: false, //审批通过弹框状态
      ApprovalFailed: false, //审批不通过弹框状态
      radio: '1', // 审批通过之后默认使用单任务
      ruleForm: {
        ApprovalFailedTextarea: null, // 审批不通过理由
      },
      historicalTrackList: [],
      rules: {
        ApprovalFailedTextarea: [
          { required: true, message: '请输入审批不通过原因', trigger: 'blur' },
          { required: true, message: '请输入审批不通过原因', trigger: 'change' },
        ],
      },
      //区域选择
      ShowSelection: false,
      areaPointList: null, // 发起需求区域绘制点对象
      rowList: null,//点击区域任务
    };
  },
  computed: {
    ...mapGetters(["dbox_data"]),
    ...mapGetters(["user_info_hn"])
  },
  methods: {
    clearTask(done) {
      this.taskshow.list = null;
      this.taskshow.show = false;
      done && done();
    },
    async showLline(data) {
      if (data) {

        let res = await API.TASK.flightLineInfo(data.flightLineId);

        if (res.status === 1) {
          this.historicalTrackList = [];
          this.taskshow.list = JSON.parse(res.data.flightCourseJson);
          this.taskshow.show = true;
        } else {
          this.$message.error(res.message);
        }
      }
      
    },
    //区域选择
    leftOnclick(data) {
      console.log(data);
    },
    rigthOnclick(data) {
      console.log(data);
    },
    move(data) {
      console.log(data);
    },
    cancelSave() { //关闭区域选择
      this.rowList = null
      this.ShowSelection = false
    },
    // 将子组件获取的禁飞区传递出来
    addallairArray(value) {
      this.allairArray = value
    },
    Selection(row) {
      console.log(row.locationArea);
      if (row.locationArea) {
        this.rowList = row
        this.ShowSelection = true
      }
    },

    // 重新下单 start
    cancelUpdate() { // 取消修改按钮
      this.updateNeedData = null
      this.edit_form2 = {
        taskTitle: null,
        taskCateId: 2,
        taskTypeId: null,
        startedAt: null,
        endAt: null,
        origin: null,
        destination: null,
        remark: null,
        locationArea: null, // 存储区域字符串
        userId: null
      }
      this.areaPointList = null
    },
    leftOnclick(data) {
      // if (data.index === -1) {
      this.regionList = data.positions;
      // }
    },
    dateStartPickerFocus() {
      // 预计飞行开始时间获取焦点做选择限制
      this.pickerOptionsStart = {
        disabledDate: (time) => {
          // 日期选择限制
          return time.getTime() < Date.now() - 8.64e7;
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    dateEndPickerFocus() {
      // 预计飞行结束时间获取焦点做选择限制
      this.pickerOptionsEnd = {
        disabledDate: (time) => {
          // 日期选择限制
          if (
            this.edit_form.startedAt &&
            new Date(this.edit_form.startedAt).getTime() > Date.now()
          ) {
            return (
              time.getTime() < new Date(this.edit_form.startedAt).getTime() - 8.64e7
            );
          } else {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    rigthOnclick(data) {
      if (data.positions.type === 1) {
      } else {
        this.regionList = data.positions;
      }
    },
    move(data) {
      this.regionList = data.positions;
    },

    saveArea() {
      // 绘制区域弹框点击保存
      if (this.regionList && this.regionList.length > 2) {
        this.areaPointList = { regionList: [] };
        let wsg84 = [];
        this.regionList.map((item) =>
          wsg84.push(transformCartesian2WGS84(item))
        );
        wsg84.map((item) =>
          this.areaPointList.regionList.push(item.lng, item.lat)
        );

        // 将绘制区域经纬度转成符合比较的多维数组
        let newData = [];
        for (let i = 0; i < Math.ceil(this.areaPointList.regionList.length / 2); i++) {
          newData[i] = [];
          newData[i].push(this.areaPointList.regionList[i * 2]);
          newData[i].push(this.areaPointList.regionList[i * 2 + 1]);
        }
        newData.push(newData[0])

        // 将所有禁飞区转成符合比较的多维数组
        let allDate = [];
        this.allairArray.map(item => {
          let mapList = [];
          for (let i = 0; i < Math.ceil(item.length / 2); i++) {
            mapList[i] = [];
            mapList[i].push(item[i * 2]);
            mapList[i].push(item[i * 2 + 1]);
            mapList.push(mapList[0])
          }
          allDate.push(mapList)
        })

        // 比较绘制区域是否与所有禁飞区域重叠
        let poly1 = turf.polygon([newData])
        let poly2 = turf.polygon(allDate)
        if (turf.booleanOverlap(poly1, poly2)) {
          this.areaPointList = null

          this.$message.warning("禁飞区内禁止绘制任务区域");
        } else {
          this.mapSelectionArea = false;
        }

      } else {
        this.$message.warning("请先绘制完区域");
      }
    },
    oneArrToTwoArr(data) {
      let newData = [];
      for (let i = 0; i < Math.ceil(data.length / 2); i++) {
        newData[i] = [];
        newData[i].push(data[i * 2]);
        newData[i].push(data[i * 2 + 1]);
      }
      return newData;
    },
    cancelSaveArea() { // 取消保存
      this.mapSelectionArea = false;
    },
    addallairArray(value) { // 将子组件获取的禁飞区传递出来
      this.allairArray = value
    },
    async drd_task_cate() { // 任务场景下拉
      try {
        let res = await API.TASK.GetDrdCate();
        res = res.data;
        this.drd.task_cate = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async drd_task_type() { //获取任务类型下拉
      try {
        let res = await API.TASK.GetDrdType();
        res = res.data;
        this.drd.task_type = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async handle_save() {  // 发起需求确定按钮
      this.$refs["edit_form"].validate(async (valid) => {
        if (valid) {
          if (this.areaPointList && this.areaPointList.regionList.length > 2) {
            // 画了区域的话转一下格式
            let regionListCopy = this.areaPointList.regionList.concat();
            let first = regionListCopy[0],
              last = regionListCopy[regionListCopy.length - 1];
            regionListCopy.shift();
            regionListCopy.pop();
            let fv = [];
            let asx = this.oneArrToTwoArr(regionListCopy);
            asx.map((item) => fv.push([item[0], item[1]].join(" ")));
            let gg = fv.toString();
            let yg = first + "," + gg + "," + last;
            this.edit_form2.locationArea = yg;
          }

          let { destination, startedAt, endAt, ...rest } = this.edit_form2;

          const params = {
            ...rest,
            startedAt: dayjs(startedAt).format("YYYY-MM-DD HH:mm:ss"),
            endAt: dayjs(endAt).format("YYYY-MM-DD HH:mm:ss"),
            type: this.Inspection ? 1 : null,
            userId: this.user_info_hn.uid
          };
          //下拉框id转换街道
          destination = this.cityTypeIdFile(destination);
          params.destination = destination;
          let res = await API.TASK.reCreate(params)
          console.log(11231);
          if (res.status === 1) {
            this.$message.success('创建需求成功')
            this.edit_form2 = {
              taskTitle: null,
              taskCateId: 2,
              taskTypeId: null,
              startedAt: null,
              endAt: null,
              origin: null,
              destination: null,
              remark: null,
              locationArea: null, // 存储区域字符串
              userId: null
            }
            this.areaPointList = null
            this.updateNeedData = null
            this.async_get_list()
          } else {
            this.$message.error(res.message)
          }
        }
      });
    },
    handleClose() {
      this.dialogVisible = false
    },
    cityTypeIdFile(val) {
      if (val == 13) {
        return "济源市";
      } else {
        //筛选出选择的街道
        return this.drd.cityType[0].children.filter(
          (v) => this.edit_form2.destination == v.id
        )[0].title;
      }
    },
    // 重新下单 end
    approvalStatusClick(num) {
      console.log(this.edit_form);
      this.edit_form.approvalStatus != num ? this.edit_form.approvalStatus = num : this.edit_form.approvalStatus = null
    },
    searchData() { // 搜索按钮
      this.pagination.page = 1
      this.async_get_list()
    },
    closeIDsPopup() { // 关闭弹框
      this.$emit("closeDemand");
    },
    async async_get_list(page1) { // 获取列表数据
      if (page1) {
        this.pagination.page = page1
      }
      const { map } = this;
      const { page, pageSize } = this.pagination;
      let obj = Object.assign({}, this.edit_form)
      obj.pageNo = page
      obj.pageSize = pageSize
      let res = await API.TASK.approveMsgList(obj);
      console.log(res);
      res = res.data;
      this.approvalStatusNum = {
        notPass: res.data.notPass,
        pass: res.data.pass,
        wait: res.data.wait
      }
      this.dataSource = res.data.approveMsgList || [];
      this.dataSource = this.dataSource.map((item, index) => ({
        ...item,
        index: (page - 1) * pageSize + index + 1,
        taskStatusName: map.status[item.taskStatus],
        applyStatusName: map.apply[item.papplyStatus],
        processingStatusName: map.processing[item.processingStatus],
      }));

      this.pagination.total = res.totalCount;
    },
    getApplyColor(status) { //审批状态颜色
      switch (status) {
        case 0:
          return "color: #FFBD36";
          break;
        case 1:
          return "color: #4799F3";
          break;
        case 3:
          return "color: #4799F3";
          break;
        case 2:
          return "color: #FF3636";
          break;
        default:
          break;
      }
    },
    async showDetails(row) { //需求详情弹框
      const res = await API.TASK.approveMsgInfo(row.id)
      this.applyStatusId = res.data.papplyStatus
      this.Details_List = res.data
      this.dialogIsShow = true
    },
    showApprovalFailed() { //展示审核不通过弹框原因输入框
      this.ApprovalFailed = true
    },
    coloseApprovalFailed() { // 关闭审核不通过弹框
      this.ruleForm.ApprovalFailedTextarea = null
      this.ApprovalFailed = false
    },
    getAllOrganization() {
      let data = this.dbox_data
      let select = JSON.parse(JSON.stringify(this.getTreeData(data)))
      select.splice(1, 1)
      this.selectList = select
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length >= 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    getRandomColor() {
      return "rgb(" + Math.round(Math.random() * 255) + "," + Math.round(Math.random() * 255) + ',' + Math.round(Math.random() * 10) + ')';
    },

    // 获取场景类型
    async getScenes() {
      let res = await API.TASK.GetDrdCate();
      res = res.data;
      this.drd.scenes = res
    },
    // 审核
    async examine(data) {
      const res = await API.TASK.approveMsgInfo(data.id)
      this.applyStatusId = data.papplyStatus
      this.Details_List = res.data
      this.dialogIsShow = true
    },
    // 定位
    location(data) {
      // 点击跳转到地图位置
      // 将经纬度转为卡笛尔坐标
      let cartesian3 = Cesium.Cartesian3.fromDegrees(120.692707, 30.540383, 0)
      locationEntity = viewer.entities.getById('icon-location');
      if (!locationEntity) {
        locationEntity = viewer.entities.add({
          id: 'icon-location',
          position: cartesian3,
          billboard: {
            image: require("@/assets/icon-location.png"),
            width: 30,
            height: 30,
            show: true,
            pixelOffset: new Cesium.Cartesian2(0, -15),
            scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0),
          }
        })
      } else {
        locationEntity.position = cartesian3
      }

      // if (record.lng === '0' || record.lat === '0') {
      //     message.error('暂无经纬度信息')
      // } else {
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(120.692707, 30.540383, 3000)
      })
      // }

    },
    // 子任务
    subtask(data) {
      console.log('subtask', data);
    },
    coloseNeedPopup() {
      this.dialogIsShow = false
    },
    editDetails(row) {
      this.dialogVisible = true
      this.getOneSubtaskNewFn(row.id)
    },
    async getOneSubtaskNewFn(id) {
      const { data } = await API.TASK.getOneSubtaskNew(id)
      console.log(data, 'datadatadata');
      for (const key in this.edit_form2) {
        this.edit_form2[key] = data[key]
      }
      // this.areaPointList

      // edit_form2
    },
    // 审批通过
    submitApproved() {
      console.log('提交');
    },
    async submitApproval(num) {


      //审批通过与不通过 1通过 2不通过
      let obj = {
        id: this.Details_List.id,
        status: num,
      };
      if (num == 2) {  // 审批不通过
        if (!this.ruleForm.ApprovalFailedTextarea) {
          this.$message.warning("请输入审批不通过原因");
        } else {
          obj.approvalRemark = this.ruleForm.ApprovalFailedTextarea;
          const res = await API.TASK.approvalRequirements(obj);
          if (res.status == 1) {
            this.$message.success("处理成功，该需求审批不通过！");
            this.ruleForm.ApprovalFailedTextarea = null
            this.ApprovalFailed = false
            this.dialogIsShow = false

            //   this.$confirm('是否需要重新下单?', '提示', {
            //   confirmButtonText: '确定',
            //   cancelButtonText: '取消',
            //   type: 'warning'
            // }).then(() => {
            //   this.getOneSubtaskNewFn(this.Details_List.id)
            //   this.dialogVisible = true
            // }).catch(() => {
            //   this.$message({
            //     type: 'info',
            //     message: '已取消操作'
            //   });
            // });
            this.async_get_list()
          } else {
            this.ruleForm.ApprovalFailedTextarea = null
            this.ApprovalFailed = false
            this.dialogIsShow = false
            this.$message.error(this.res.message);
          }
        }
      } else { // 审批通过
        obj.isSubtasks = this.radio;
        const res = await API.TASK.approvalRequirements(obj);
        if (res.status == 1) {
          this.$message.success("处理成功，该需求审批通过！");
          this.async_get_list()
          this.dialogIsShow = false
        } else {
          this.$message.error(this.res.message);
        }
      }
    },
  },
  watch: {
    "edit_form.approvalStatus"(val) {
      this.pagination.page = 1
      this.async_get_list()
    }
  },
  mounted() {
    this.drd_task_cate()
    this.drd_task_type()
    this.getScenes()
    this.getAllOrganization()
    this.async_get_list()
  },
};
</script>

<style lang="scss" scoped>
.content {

  /* 右侧 */
  .list {
    width: 100%;
    height: 800px;

    .state {
      display: flex;

      .jc {
        margin-right: 24px;
        cursor: pointer;
      }
    }

    .approval-info {
      width: 100%;
      height: 109px;

      .dsp {
        background-image: url(./img/1.png);

        &.selected {
          background-image: url(~@/assets/img/click-3.png);
        }
      }

      .sptg {
        background-image: url(./img/2.png);

        &.selected {
          background-image: url(~@/assets/img/click-1.png);
        }
      }

      .mrtg {
        background-image: url(./img/3.png);

        &.selected {
          background-image: url(~@/assets/img/click-4.png);
        }
      }

      .spbtg {
        background-image: url(./img/4.png);

        &.selected {
          background-image: url(~@/assets/img/click-2.png);
        }
      }

      .jc {
        width: 204px;
        height: 109px;

        .jc-tp {
          width: 46px;
          height: 46px;
          font-size: 46px;
          margin-left: 20px;

          &.iconfont.icon-leijifeihangjiaci {
            background: linear-gradient(180deg, #FFFFFF 0%, #D0FDFC 59%, #50F9F4 100%);
            //将字体图标改为只显示背景在字体上，就完成了字体的渐变
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-leijifeihangshichang {
            background: linear-gradient(180deg, #CBF3FF 0%, #9BE8FF 59%, #00C4FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-leijifeihangjuli {
            background: linear-gradient(180deg, #EFFFF5 0%, #82FFD0 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-leijirenwushuliang {
            background: linear-gradient(180deg, #FFF0C8 0%, #FFCE4F 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .jc-text {
          text-align: center;
          width: 120px;

          .num {
            font-size: 32px;
            font-family: DINAlternate-Bold, DINAlternate;
          }

          .text {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #DAE4FF;
          }
        }
      }
    }

    .select {
      margin: 32px 0 24px 0;

      ::v-deep {
        .el-input__inner {
          background-color: #000;
          border: 0;
          height: 30px;
          line-height: 30px;
          color: #fff;
          font-size: 14px;
        }
      }

      .button {
        margin-right: 10px;
        width: 210px;
      }

      .search-button {
        display: inline-block;
        width: 54px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-left: 10px;
        cursor: pointer;
        border-radius: 4px;
        background-color: #000;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
      }
    }

    .task-table {
      height: 600px;
    }
  }
}

::v-deep {
  .out-content {
    .el-dialog {
      margin-top: 189px !important;
      height: 600px;
    }
  }
}

.taskDialong {
  width: 100%;

  .taskDraw {
    top: 32px !important;
    height: calc(100% - 32px) !important;
  }

  .el-dialog__body {
    height: 742px;
  }
}

/* 详情单样式 */
.dispatch {
  // height: 456px;
  width: 100%;

  // overflow: auto;
  .need-task-type {
    width: 300px;
    color: rgb(170, 183, 195);

    ::v-deep {
      .el-radio__label {
        color: #fff;
      }
    }
  }

  .need-top-title {
    height: 34px;

    .left {
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      padding-left: 10px;
      position: relative;

      .dec {
        position: absolute;
        top: 2px;
        left: 0;
        width: 4px;
        height: 16px;
        background: #FFBD36;
        border-radius: 2px;
      }
    }

    .right {
      font-size: 14px;
      color: #AAB7C3;
    }
  }

  .need-bottom {
    margin-top: 28px;
    font-size: 14px;
    color: #AAB7C3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .info-add {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    .one-info {
      width: 33.3%;
      margin-bottom: 12px;
    }

    .one-info-long {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .detail {
    height: 107px;
    margin-top: 12px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    padding: 16px 24px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .button {
    margin-top: 40px;
    padding: 0 140px;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;

    .no {
      cursor: pointer;
      width: 126px;
      height: 40px;
      // background: rgba(219,27,27,0.71);
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-right: 20px;
    }

    .yes {
      cursor: pointer;
      width: 126px;
      height: 40px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-left: 20px;
    }
  }
}

.Approved {
  .Approved_Body {
    width: 100%;

    &.FailedTextarea {
      margin-bottom: 20px;
    }

    ::v-deep {
      .el-textarea {
        .el-textarea__inner {
          background-color: rgba(0, 0, 0, 0.6);
          ;
          border: none;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }

    .Approved_Body_Item {
      width: 100%;
      text-align: center;
      margin-bottom: 21px;

      .iconApproved {
        color: #6AFF64;
      }

      .Item_Label {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 12px;
      }

      .Item_Label_Select {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  .Approved_Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-align: center;

    .no {
      cursor: pointer;
      width: 126px;
      height: 40px;
      // background: rgba(219,27,27,0.71);
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-right: 20px;
    }

    .yes {
      cursor: pointer;
      width: 126px;
      height: 40px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-left: 20px;
    }
  }

}

.create-need {
  width: 548px;
  height: 790px;
  position: relative;

  .anniu {
    margin-top: 40px;
    text-align: center;
    justify-content: center;
    display: flex;

    .button {
      width: 126px;
      height: 40px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 40px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      cursor: pointer;
    }

    .button2 {
      margin-right: 30px;
    }
  }

  .dec {
    position: absolute;
    top: -28px;
    height: 848px;
    left: 560px;
    width: 0px;
    border-right: 1px solid #73A6D1;
    box-shadow: 0px 0px 5px #73A6D1;
  }

  .timecss {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ::v-deep {
    .edit-form {
      padding: 22px 20px 0 6px;

      .el-form-item__label {
        height: 36px;
        color: #AAB7C3;
        font-size: 14px;
        text-align: left;
      }

      .el-date-editor--datetime {
        width: 204px;
      }

      .el-input__suffix {
        height: 34px;

        .el-input__suffix-inner {

          .el-input__icon,
          .el-input__clear {
            line-height: 36px;
          }
        }
      }

      .el-cascader {
        width: 100%;
      }

      .el-input {
        box-sizing: border-box;

        .el-input__inner {
          height: 36px;
          box-sizing: border-box;
          background: rgba(40, 53, 122, 0.1);
          border: 1px solid #73A6D1;
          box-shadow: 0px 0px 2px #73A6D1;
          border-radius: 0;
          font-size: 16px;
          color: #9fc4ff;
          letter-spacing: 0;
          font-weight: 400;
          line-height: 36px;
        }

        .el-input__suffix {
          .el-input__suffix-inner {

            .el-input__icon,
            .el-input__clear {
              line-height: 36px;
            }
          }
        }
      }

      .el-textarea {
        textarea {
          box-sizing: border-box;
          background: rgba(40, 53, 122, 0.1);
          background: transparent;
          border: 1px solid #73A6D1;
          box-shadow: 0px 0px 2px #73A6D1;
          border-radius: 0;
          font-size: 16px;
          color: #9fc4ff;
          letter-spacing: 0;
          font-weight: 400;
        }
      }

      .el-form-item__content {


        .el-select {
          width: 100%;
        }
      }

      .linear-fields {
        border: none;
      }

      .overflow-auto {
        overflow: auto;
        max-height: 130px;
        border: 1px solid #27526e;
      }

      .noselected-area {
        color: #999;
        cursor: pointer;
        line-height: 34px;
        font-size: 16px;
      }

      .selected-area {
        color: rgb(25, 194, 107);
        cursor: pointer;
        line-height: 34px;
        font-size: 16px;
      }

      .Inspection {
        height: 37px;
      }
    }
  }
}

/* 绘制区域 */
.area-select-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(12, 34, 67, 0.4);
  z-index: 7000;

  .content {
    position: absolute;
    width: 1600px;
    height: 800px;
    left: 50%;
    top: 100px;
    margin-left: -800px;
    border: 1px solid #009aff;

    .button {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 160px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1000;

      .el-button--primary {
        background-color: #69b3e5;
      }
    }
  }
}</style>