<template>
  <div class="task-dispatch">
    <div class="dispatch-menu">
      <Menu ref="menuRef" @on-Click="showList" :iconList="list"/>
    </div>
    <DemandSheetPreparation  v-if="showListType === 1" @closeDemand="closeDemand"/>
    <Waiting v-if="showListType === 2" @closeDemand="closeDemand"/>
    <Mission v-if="showListType === 3" @closeDemand="closeDemand"/>
    <Calendar v-if="showListType === 4" @closeDemand="closeDemand"/>
  </div>
</template>

<script>
import Menu from "@/components/newmenu/menu"
import DemandSheetPreparation from './demand-sheet-preparation/index.vue' // 需求单定制
import Waiting from "./waiting"
import Mission from "./MissionAccomplished"
import Calendar from "@/pages/platform/task/calendar"
export default {
  components: { Menu, DemandSheetPreparation, Waiting ,Mission,Calendar},
  data() {
    return {
      list: 
        [
          
        ],
        isShow: false,
        showListType:1, // 控制展示左侧哪个导航
    }
  },
  
  methods: {
    closeDemand(){
      console.log(12312423432);
      this.showListType=false
    },
    showList(item){
      this.showListType = item.id
      this.$forceUpdate()
    },
    init_jurisdiction(){
      const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:tasks:taskapply") > -1) {
      this.list.push({
            id: 1,
            name: '任务单审核',
            class: 'icon-dimanxiaodengjishu'
          },)
    }
    if (permissionList.indexOf("hn:tasks:taskcontrol") > -1) {
      this.list.push({
            id: 2,
            name: '任务单调度',
            class: 'icon-youxiao'
          },)
    }
    if (permissionList.indexOf("hn:tasks:taskcomplete") > -1) {
      this.list.push({
            id: 3,
            name: '任务完结库',
            class: 'icon-youxiao'
          },)
    }
    if (permissionList.indexOf("hn:scheduling") > -1) {
      this.list.push({
            id: 4,
            name: '人员排班',
            class: 'icon-youxiao'
          })
    }
    this.showListType = this.list[0]?.id
    
    }
  },
  created(){
    this.init_jurisdiction()
  },
  mounted(){
    if (this.list.length) this.$refs.menuRef.select(this.list[0])
  }
}
</script>

<style lang="scss" scoped>
    .task-dispatch {
        .dispatch-menu {
        position: absolute;
        left: 0;
        z-index: 1;
        width: 108px;
        height: 100%;
      }
    }
</style>