/*
 * @Author: kai 17660422924@163.com
 * @Date: 2024-01-12 15:49:13
 * @LastEditors: kai 17660422924@163.com
 * @LastEditTime: 2024-01-17 17:27:46
 * @FilePath: \mmc-JIYuan\src\utils\cesium.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export function transformCartesian2WGS84(cartesian) {
    let ellipsoid = Cesium.Ellipsoid.WGS84;
    let cartographic = ellipsoid.cartesianToCartographic(cartesian);
    return {
        lng: Cesium.Math.toDegrees(cartographic.longitude),
        lat: Cesium.Math.toDegrees(cartographic.latitude),
        alt: cartographic.height,
    };
}

export function setView(viewer) {
    viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(120.570164, 29.927768, 76900.3885525426),
        orientation: {
            "heading": 0.005918411232473275,
            "pitch": -0.9278204182519674,
            "roll": 6.283184339467954
        }
    })
}
export function initMap(my_layers_data, name) {
    // 设置相机初始位置为中国上空
    Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
        90,
        -20,
        110,
        90
    );

    let viewer = new Cesium.Viewer(name, {
        animation: false, // 动画控制不显示
        shouldAnimate: true,
        timeline: false, // 时间线不显示
        fullscreenButton: false, // 全屏按钮不显示
        creditContainer: document.createElement("div"),
        geocoder: false,
        homeButton: false,
        // maximumRenderTimeChange: Infinity, // 无操作时自动渲染帧率，设为数字会消耗性能，Infinity为无操作不渲染
        sceneModePicker: false, // 切换球形和平面型
        baseLayerPicker: false,
        navigationHelpButton: false,
        infoBox: false,
        selectionIndicator: false,
        requestRenderMode: true, //启用请求渲染模式
        scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
        sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
        imageryProvider: new Cesium.WebMapTileServiceImageryProvider({
            url: "http://t0.tianditu.gov.cn/img_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
            layer: "img",
            style: "default",
            tileMatrixSetID: "w",
            format: "tiles",
            maximumLevel: 18,
        }),
        orderIndependentTranslucency: false,
        contextOptions: {
            webgl: {
                alpha: true,
            }
        },
    });
    // 根据环境设置相对应地图
    if (my_layers_data === "night") {
        viewer.imageryLayers.addImageryProvider(
            new Cesium.UrlTemplateImageryProvider({
                url: "http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
            })
        );
    } else if (my_layers_data === "tian") {
        // //高德影像地图数据图层，自带注记
        viewer.imageryLayers.addImageryProvider(
            new Cesium.WebMapTileServiceImageryProvider({
                url: "https://t0.tianditu.gov.cn/img_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
                layer: "img",
                style: "default",
                tileMatrixSetID: "w",
                format: "tiles",
                maximumLevel: 18,
            })
        );

        viewer.imageryLayers.addImageryProvider(
            new Cesium.WebMapTileServiceImageryProvider({
                url: "https://t0.tianditu.gov.cn/cia_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
                layer: "cia",
                style: "default",
                tileMatrixSetID: "w",
                format: "tiles",
                maximumLevel: 18,
            })
        );
        viewer.imageryLayers.addImageryProvider(
            new Cesium.UrlTemplateImageryProvider({
                url: "https://hawk.hnjcloud.cn/3d/gdm-app/datamg/services/1603165708490304/tile?crs=EPSG:3857&x={x}&y={y}&l={z}",
            })
        );
    }
    viewer.scene.globe.depthTestAgainstTerrain = false
    return viewer

}